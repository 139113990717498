import { User } from '@firebase/auth';
import { Button, ListItemButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Icons } from 'config/icons';
import React, { Fragment } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export interface BottomDrawerOption {
	icon?: JSX.Element;
	title?: string;
	element?: JSX.Element;
	onClick?: (e: React.MouseEvent) => void;
	disabled?: boolean;
	needSignin?: boolean;
}

export interface BottomDrawerOptionProps {
	options: BottomDrawerOption[];
	element?: JSX.Element;
	actionIcon?: JSX.Element;
	actionTitle?: string;
	drawerTitle?: string;
	sx?: any;
	actionHandler?: (e: React.MouseEvent) => void;
	user?: User;
	navigate?: NavigateFunction;
}

export function BottomDrawerOptions(props: BottomDrawerOptionProps) {
	const optionsList = (
		<Fragment>
			{props.element ? (
				props.element
			) : (
				<List sx={props.sx}>
					{props.drawerTitle ? (
						<Fragment>
							<ListItem>
								<ListItemText primary={props.drawerTitle} />
							</ListItem>
							<Divider />
						</Fragment>
					) : null}
					{props.options.map((option, index) => {
						let disabled = option.disabled;
						let signin = false;
						if (option.needSignin === true && !props.user) {
							signin = true;
						}
						return (
							<ListItem
								key={option.title || (option.element?.key ?? index)}
								disablePadding
								secondaryAction={
									signin ? (
										<Button
											style={{ marginTop: 8 }}
											size="small"
											onClick={(e) => {
												if (props.actionHandler) {
													props.actionHandler(e);
												}
												if (props.navigate) {
													props.navigate('/account?screen=signin', { state: { isModal: true } });
												}
											}}
										>
											Sign In
										</Button>
									) : undefined
								}
							>
								<ListItemButton disabled={disabled || signin} onClick={option.onClick}>
									{option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
									{option.title ? <ListItemText primary={option.title} /> : null}
									{option.element}
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			)}
			<Divider />
			<List>
				<ListItem button onClick={props.actionHandler}>
					<ListItemIcon>{props.actionIcon || Icons.Close}</ListItemIcon>
					<ListItemText primary={props.actionTitle || 'Cancel'} />
				</ListItem>
			</List>
		</Fragment>
	);

	return optionsList;
}
