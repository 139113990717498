import { Avatar, Card, CardHeader, IconButton, Typography } from '@mui/material';
import { RecordsListControl } from 'components/RecordList';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { Icons } from 'config/icons';
import { getFormattedTimeFromSec } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import { useState } from 'react';
import { connect } from 'react-redux';
import { deleteTimerLog, updateTimerLog } from 'store/data/timer/actions';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, TimerLog } from 'types';
import { timerThemeColor } from './config';
import { TimerLogForm } from './TimerLogForm';

const classes = {
	card: {
		margin: 1,

		'& .MuiCardContent-root:last-child': {
			paddingBottom: 2.5,
		},
	},
	header: {
		'& div': {
			overflow: 'hidden',
		},
		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
	},
	title: {
		fontSize: 14,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	avatar: {
		color: '#fff',
		backgroundColor: timerThemeColor,
		width: 48,
		height: 48,
		transform: 'scale(0.8)',
	},
};

interface Props {
	record: { startTime: number; timerLog: TimerLog };
	index: number;
	updateTimerLog: (timerLog: TimerLog, startTime: number) => void;
	deleteTimerLog: (startTime: number) => void;
	toggleDrawer: (content?: any) => void;
}

const Component = (props: Props) => {
	let { record, toggleDrawer, updateTimerLog, deleteTimerLog, index } = props;
	let [editLog, setEditLog] = useState(false);

	const optionsObj = () => {
		return {
			options: [
				{
					title: 'Edit Log',
					icon: Icons.Edit,
					onClick: () => {
						setEditLog(true);
						toggleDrawer();
					},
				},
				{
					title: 'Delete Log',
					icon: Icons.DeleteForever,
					onClick: () => {
						deleteTimerLog(record.startTime);
						toggleDrawer();

						setTimeout(() => {
							RecordsListControl.scrollToItem(index - 1, 'start', 'auto');
						}, 10);
					},
				},
			],
			actionHandler: () => {
				toggleDrawer();
			},
		};
	};

	const onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		toggleDrawer(BottomDrawerOptions(optionsObj()));
	};

	return (
		<>
			{editLog && (
				<TimerLogForm
					actionText="Submit"
					startTime={record.startTime}
					duration={record.timerLog.duration}
					action={async (startTime: number, duration: number) => {
						let log = { ...record.timerLog, duration, isManual: true };

						if (record.startTime !== startTime) {
							deleteTimerLog(record.startTime);
						}

						updateTimerLog(log, startTime);
					}}
					onClose={() => {
						setEditLog(false);
					}}
				/>
			)}
			<Card sx={classes.card} onClick={() => {}}>
				<CardHeader
					sx={classes.header}
					avatar={
						<div style={{ position: 'relative' }}>
							<Avatar sx={classes.avatar} variant="circular">
								<Icons.TimerIcon />
							</Avatar>
						</div>
					}
					action={
						<IconButton
							onClick={(e) => {
								onMoreIconClick(e);
							}}
						>
							{Icons.MoreVert}
						</IconButton>
					}
					title={
						<Typography variant="subtitle1" noWrap>
							{new AnandDate().setEpoch(record.startTime).format("0DD MMM'YY, hh:mm:ss A")}
						</Typography>
					}
					subheader={
						<Typography variant="body2" color="textSecondary" noWrap>
							{getFormattedTimeFromSec(record.timerLog.duration, true)}
						</Typography>
					}
				/>
			</Card>
		</>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		updateTimerLog: (timerLog: TimerLog, startTime: number) => {
			dispatch(updateTimerLog(timerLog, startTime, true));
		},
		deleteTimerLog: (startTime: number) => {
			dispatch(deleteTimerLog(startTime));
		},
	};
}

export const TimerLogTile = connect(mapStateToProps, mapDispatchToProps)(Component);
