import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { BottomDrawerOption } from 'components/shell/BottomDrawerOptions';
import SimpleBottomNavigation from 'components/shell/BottomNavigation';
import { WebPage } from 'components/website/WebPage';
import { IsMinimalRhymeSite } from 'config';
import { isAdmin, isMobile } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { updateAppBarTitle } from 'store/temp/actions';
import { ApplicationState } from 'types';

const classes = {
	root: (props) => (theme) => {
		let offset = props.isWindowFullScreen ? -8 : props.includeBottomNavigation && isMobile() ? 112 : 57;
		let inset = props.isWindowFullScreen ? 0 : '- env(safe-area-inset-bottom)/2';
		let fallback = (css, property) => css.replace(` ${inset}`, '') + '; ' + property + ': ' + css;
		let top = props.topControlHeight ?? 0;
		let template = (props, top, off, property, condition) => (condition ? fallback(`calc(100% ${inset} - ${offset + off + top}px)`, property) : 'auto');

		return {
			paddingTop: '1px',
			backgroundColor: props.dark ? grey[200] : 'white',

			minHeight: template(props, 0, 0, 'min-height', !props.fullscreen),
			height: template(props, top, 0, 'height', props.fullscreen),

			[`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
				height: template(props, top, -8, 'height', props.fullscreen),
			},

			[theme.breakpoints.up('sm')]: {
				height: template(props, top, 8, 'height', props.fullscreen),
			},

			[`${theme.breakpoints.up('sm')} and (orientation: landscape)`]: {
				height: template(props, top, 8, 'height', props.fullscreen),
			},
		};
	},
};

interface PageProps {
	title: string;

	children: React.ReactNode;
	updateAppBarTitle: (title: string) => void;
	style?: any;
	fullscreen?: boolean;
	includeBottomNavigation?: boolean;
	topControlHeight?: number;
	onLoad?: () => void;
	isAdmin: boolean;
	dark?: boolean;
	miniMode: boolean;
	contextualMoreOptions?: BottomDrawerOption[];
	isWindowFullScreen: boolean;
}

class Page extends React.Component<PageProps> {
	constructor(props) {
		super(props);

		props.updateAppBarTitle(props.title);
	}

	componentDidMount() {
		if (this.props.onLoad) {
			this.props.onLoad();
		}
	}

	render() {
		if (isMobile() || this.props.isAdmin || IsMinimalRhymeSite) {
			return (
				<Box sx={classes.root(this.props)} style={this.props.style}>
					{this.props.children}
					{this.props.miniMode && !this.props.fullscreen ? <div style={{ height: 80 }} /> : null}
					{this.props.includeBottomNavigation && isMobile() && !this.props.isWindowFullScreen ? (
						<SimpleBottomNavigation contextualMoreOptions={this.props.contextualMoreOptions} />
					) : null}
				</Box>
			);
		} else {
			return <WebPage style={this.props.style}>{this.props.children}</WebPage>;
		}
	}
}

function mapStateToProps(state: ApplicationState, props) {
	return {
		includeBottomNavigation: IsMinimalRhymeSite ? false : props.includeBottomNavigation,
		isAdmin: isAdmin(state.userState.userStore.user, state.userState.userStore.userData),
		miniMode: state.tempState.audioPlaybackDetails && state.tempState.audioPlaybackState.isMiniPlayerMode,
		isWindowFullScreen: state.tempState.isFullScreen ?? false,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateAppBarTitle: (title: string) => {
			dispatch(updateAppBarTitle(title));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
