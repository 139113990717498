import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

const classes = {
	root: {
		background: '#038b0024',
		width: 75,
		height: 75,
		borderRadius: '50%',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
};

interface TimerPresetProps {
	duration: string;
	label?: string;
	size?: number;
	fontSize?: string;
	color?: string;
	background?: string;
	fontWeight?: number;
	pinned?: boolean;
}

const Component = (props: TimerPresetProps) => {
	return (
		<Box
			sx={{
				...classes.root,
				width: props.size ?? 75,
				height: props.size ?? 75,
				background: props.background ?? '#038b0024',
			}}
		>
			<Typography
				variant="h5"
				fontWeight={props.fontWeight ?? 400}
				fontSize={props.fontSize ?? '2.4rem'}
				color={props.color ?? '#fd9900'}
				lineHeight={props.label ? 1.1 : 1}
			>
				{props.duration}
			</Typography>
			{!!props.label && <Typography variant="subtitle2">{props.label}</Typography>}
		</Box>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export const TimerPresetControl = connect(mapStateToProps, mapDispatchToProps)(Component);
