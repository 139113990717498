import { combineReducers } from 'redux';
import { playedMediaReducer } from 'store/data/played/reducers';
import { timerLogsReducer } from 'store/data/timer/reducers';
import { DownloadStatus, DownloadStore, OfflineState } from 'types';
import { AddOfflineAudio, ADD_OFFLINE_AUDIO, RemoveOfflineAudio, REMOVE_OFFLINE_AUDIO, UpdateDownloadState, UPDATE_DOWNLOAD_STATE } from './actions';

export function downloadReducer(state: DownloadStore = {}, action: AddOfflineAudio | RemoveOfflineAudio | UpdateDownloadState) {
	switch (action.type) {
		case ADD_OFFLINE_AUDIO:
			if (!action.article) {
				return state;
			}

			return {
				...state,
				[action.article.id]: {
					...state[action.article.id],
					[action.forExport === true ? 'exportProgress' : 'progress']: {
						status: DownloadStatus.Queued,
						value: -1,
						bytes: 0,
						updatedAt: new Date(),
					},
					uri: action.article.mediaUri!,
					forExport: action.forExport,
				},
			};
		case REMOVE_OFFLINE_AUDIO:
			let newState = { ...state };
			delete newState[action.articleId];
			return newState;
		case UPDATE_DOWNLOAD_STATE:
			return {
				...state,
				[action.articleId]: {
					...state[action.articleId],
					[state[action.articleId]?.forExport === true ? 'exportProgress' : 'progress']: action.progress,
				},
			};
		default:
			return state;
	}
}

export const offlineReducer = combineReducers<OfflineState>({
	audio: downloadReducer,
	playedMedia: playedMediaReducer,
	timerLogs: timerLogsReducer,
});
