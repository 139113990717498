import { Box, Button, Card, CardHeader, IconButton, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import TextContent from 'components/TextContent';
import { Icons } from 'config/icons';
import { getWebAppAssetPath, isAdmin, isMobile, limitLines, limitWords } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React from 'react';
import { connect } from 'react-redux';
import { getHDByDate } from 'store/data/hd/selectors';
import { updateOperation } from 'store/data/ops/actions';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, HD, Operation } from 'types';

const classes = {
	root: (theme: Theme) => ({
		margin: theme.spacing(2, 1, 0),

		'&::before, &::after': {
			display: 'block',
			content: '""',
			backgroundImage: `url(${getWebAppAssetPath('bg/golden-border.jpg')})`,
			backgroundRepeat: 'repeat-x',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			height: '2rem',
		},
	}),
	header: {
		textTransform: 'uppercase' as 'uppercase',

		'& .MuiCardHeader-action': {
			position: 'fixed',
		},
	},
	hd: (theme: Theme) => ({
		padding: theme.spacing(1, 2),

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(1, 4),
		},
	}),
	date: (theme: Theme) => ({
		color: '#bd3b0b',
		fontWeight: 500,
		textAlign: 'center' as 'center',
		padding: theme.spacing(0, 0, 1),
		fontSize: '1.1em',
		borderBottom: '1px solid',
		display: 'flex',
		// flexDirection: 'row',

		[theme.breakpoints.up('md')]: {
			fontSize: '1.2em',
		},
	}),
};

interface Props {
	locale: string;
	toggleDrawer: (content?) => void;

	forDate?: string;
	hdByDate: { [date: string]: HD[] };
	hd?: HD;
	showAll: boolean;
	style?: any;
	hasAcked?: Operation;
	ack: () => void;
}

class Component extends React.Component<Props, any> {
	private textRef = React.createRef<HTMLDivElement>();
	private partialLines = 4;

	constructor(props) {
		super(props);

		this.state = {
			date: this.props.forDate,
			showFull: false,
			ack: false,
		};

		(this.textRef.current as any) = {};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.forDate !== this.props.forDate) {
			this.setState({
				date: this.props.forDate,
			});
		} else if (!this.state.date && this.props.showAll !== prevProps.showAll) {
			this.setState({
				date: this.props.forDate,
			});
		} else if (!this.props.hdByDate[this.state.date] && this.state.date !== this.props.forDate) {
			this.setState({
				date: this.props.forDate,
			});
		}

		this.optionsList = BottomDrawerOptions(this.optionsObj());

		if (this.textRef.current) {
			Object.values(this.textRef.current).forEach((arr) => limitLines(arr[0], arr[1], this.state.showFull ? -1 : this.partialLines));
		}
	}

	private optionsObj = () => ({
		options: [
			{
				element: (
					<Autocomplete
						style={{ width: '100%' }}
						options={Object.keys(this.props.hdByDate).sort()}
						getOptionLabel={(option) => new AnandDate('1900-' + option).format('MMM, Do')}
						value={this.state.date}
						disableClearable
						onChange={(event, newValue) => {
							this.setState({
								date: newValue,
							});
							this.props.toggleDrawer();
						}}
						renderInput={(params) => <TextField {...params} label="Historical Date" margin="normal" />}
					/>
				),
				icon: Icons.Calendar,
			},
		],
		actionHandler: () => {
			this.props.toggleDrawer();
		},
	});

	private optionsList;

	private onDateClicked = (e) => {
		e.stopPropagation();

		this.optionsList = this.optionsList ?? BottomDrawerOptions(this.optionsObj());
		this.props.toggleDrawer(this.optionsList);
	};

	componentDidMount() {
		if (this.textRef.current) {
			Object.values(this.textRef.current).forEach((arr) => limitLines(arr[0], arr[1], this.state.showFull ? -1 : this.partialLines));
		}
	}

	render() {
		let { hdByDate, locale, showAll, hasAcked, ack } = this.props;
		if (hasAcked?.value === true) {
			return <></>;
		}

		let hds = this.props.hd ? [this.props.hd] : hdByDate[this.state.date];

		if (!hds || hds.length === 0) {
			return null;
		}

		let allHDs = Object.keys(hdByDate).sort();
		let currentIndex = allHDs.indexOf(this.state.date);

		let hdContents = hds
			.sort((a, b) => new Date(a.forDate).getTime() - new Date(b.forDate).getTime())
			.map((hd: HD) => {
				let text = hd.text[locale] || hd.text.hi || hd.text.en;

				let hdContent = (
					<TextContent locale={locale} alignRhymes={true}>
						<div ref={(node) => ((this.textRef.current as any)[hd.id] = [node, text])} dangerouslySetInnerHTML={{ __html: text }}></div>
					</TextContent>
				);

				return (
					<Box sx={classes.hd} key={hd.id}>
						<Box sx={classes.date}>
							{showAll && (
								<Button
									size="small"
									onClick={() => {
										this.setState({ date: allHDs[currentIndex - 1] });
									}}
									disabled={currentIndex <= 0}
								>
									{Icons.KeyLeftArrow}
									Back
								</Button>
							)}
							<div
								style={{ cursor: showAll ? 'pointer' : 'auto', flexGrow: 1, alignSelf: 'center' }}
								onClick={showAll ? this.onDateClicked : undefined}
							>
								{new AnandDate(hd.forDate).format('ddd, Do MMM, YYYY')}
							</div>
							{showAll && (
								<Button
									size="small"
									onClick={() => {
										this.setState({ date: allHDs[currentIndex + 1] });
									}}
									disabled={currentIndex >= allHDs.length - 1}
								>
									Next
									{Icons.KeyRightArrow}
								</Button>
							)}
						</Box>
						{hdContent}
					</Box>
				);
			});

		return (
			<Card elevation={2} sx={classes.root} style={this.props.style}>
				{isMobile() ? (
					<CardHeader
						sx={classes.header}
						style={{ padding: 0 }}
						// action={
						// 	<IconButton sx={{ padding: 2 }} onClick={ack}>
						// 		{Icons.CloseIcon}
						// 	</IconButton>
						// }
						title={React.createElement('heading-one', {}, 'Historical Day')}
					/>
				) : null}
				{!isMobile() ? (
					<TextContent locale="locale-en" alignRhymes={true} useFontSize={false}>
						{React.createElement('heading-one', { class: classes.header }, 'Historical Day')}
					</TextContent>
				) : null}
				{hdContents}
				<Box sx={{ display: 'flex' }}>
					<Button sx={{ margin: '-24px 8px 8px 0px', marginRight: 'auto', display: 'block' }} size="small" onClick={ack}>
						Hide
					</Button>
					<Button
						sx={{ margin: '-24px 8px 8px 0', marginLeft: 'auto', display: 'block' }}
						size="small"
						onClick={() => {
							this.setState({ showFull: !this.state.showFull });
						}}
					>
						{!this.state.showFull ? 'Expand' : 'Collapse'}
					</Button>
				</Box>
			</Card>
		);
	}
}

function mapStateToProps(state: ApplicationState, props: { id?: string; style?: any }) {
	let hdByDate = getHDByDate(state);

	let today = state.tempState.today.format('MM-DD');
	let todaysHD = props.id ? state.dataState.hd.byId[props.id] : hdByDate[today];

	let forDate = today;
	let showAll =
		isAdmin(state.userState.userStore.user, state.userState.userStore.userData) &&
		state.userState.userStore.userData?.settings &&
		state.userState.userStore.userData?.settings['admin.records'];

	if ((!todaysHD || todaysHD.length === 0) && showAll) {
		let allDates = Object.keys(hdByDate).sort();
		let higherDates = allDates.filter((date) => date > today);
		forDate = higherDates.length ? higherDates[0] : allDates[0];
	}

	return {
		hdByDate,
		hd: props.id ? todaysHD : undefined,
		locale: state.uxState.locale,
		forDate,
		showAll,
		hasAcked: state.opsState.byId[new AnandDate().format('YYYY-MM-DD')],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		ack: () => {
			dispatch(updateOperation({ key: new AnandDate().format('YYYY-MM-DD'), value: true, createdAt: new Date(), updatedAt: new Date() }));
		},
	};
}

export const HDControl = connect(mapStateToProps, mapDispatchToProps)(Component);
