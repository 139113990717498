import { Action } from 'redux';
import { TimerLog, TimerLogs } from 'types';

export const UPDATE_TIMER_LOG = '@@timer/UPDATE_TIMER_LOG';
export type T_UPDATE_TIMER_LOG = typeof UPDATE_TIMER_LOG;

export interface UpdateTimerLog extends Action {
	type: T_UPDATE_TIMER_LOG;
	timerLog: TimerLog;
	startTime: number;
	force: boolean;
}

export const updateTimerLog = (timerLog: TimerLog, startTime: number, force: boolean): UpdateTimerLog => {
	return {
		type: UPDATE_TIMER_LOG,
		timerLog,
		startTime,
		force,
	};
};

export const DELETE_TIMER_LOG = '@@timer/DELETE_TIMER_LOG';
export type T_DELETE_TIMER_LOG = typeof DELETE_TIMER_LOG;

export interface DeleteTimerLog extends Action {
	type: T_DELETE_TIMER_LOG;
	startTime: number;
}

export const deleteTimerLog = (startTime: number): DeleteTimerLog => {
	return {
		type: DELETE_TIMER_LOG,
		startTime,
	};
};

export const UPDATE_ALL_TIMER_LOGS = '@@timer/UPDATE_ALL_TIMER_LOGS';
export type T_UPDATE_ALL_TIMER_LOGS = typeof UPDATE_ALL_TIMER_LOGS;

export interface UpdateAllTimerLogs extends Action {
	type: T_UPDATE_ALL_TIMER_LOGS;
	timerLogs: any;
}

export const updateAllTimerLogs = (timerLogs: any): UpdateAllTimerLogs => {
	return {
		type: UPDATE_ALL_TIMER_LOGS,
		timerLogs,
	};
};
