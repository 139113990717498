import { push } from '@lagunovsky/redux-react-router';
import App from 'AppInterface/App';
import { ChangePage, DidPlayToEnd, DidTransitionToMedia, DidUpdateDuration, DidUpdateElapsedTime, DidUpdateStatus } from 'AppInterface/AppInterface';
import { DBConfig, PlaybackSpeedPolicy } from 'config';
import { appUpdateRoute, phoneSigninRoute } from 'config/route';
import { getBuildVersion, isAndroid, isApple, isWebViewEnv, updatePushSetting } from 'helpers';
import { Context } from 'helpers/context';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { concatMap, debounceTime, delay, switchMap, throttleTime } from 'rxjs/operators';
import { UPDATE_CONFIG } from 'store/data/configs/actions';
import { multiAction, performOperationDaily, performOperationOnceInSession } from 'store/data/ops/actions';
import { updatePlayedMedia } from 'store/data/played/actions';
import { addOfflineAudio, removeOfflineAudio } from 'store/offline/actions';
import {
	EventType,
	onEvent,
	updateAudioPlaybackArticleIndex,
	updateAudioPlaybackDuration,
	updateAudioPlaybackState,
	updateAudioPlaybackTime,
	updateToday,
	WEB_APP_INIT,
} from 'store/temp/actions';
import { updateFilters, updateSortOrder } from 'store/ux/actions';
import { ApplicationState, AudioCurrentState, DownloadStatus } from 'types';
import { queueOps } from './OpsEpic';

const appBuildVersionEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_CONFIG),
		debounceTime(30000),
		concatMap(() => {
			let state = state$.value as ApplicationState;

			let updateAction = checkAppUpdate(state);
			if (updateAction) {
				return updateAction;
			}

			if (!isWebViewEnv() || window.location.port === '8080') {
				return EMPTY;
			}

			let configs = state.dataState.configs.byId;
			let latestVersionConfig = configs[DBConfig.LatestBuildVersion];
			let latestBuildVersion = parseInt(latestVersionConfig?.value ?? '0');
			let currentBuildVersion = getBuildVersion();

			if (latestBuildVersion >= currentBuildVersion + 10) {
				window.location.href = '/#nocache';
				return EMPTY;
			}

			if (latestBuildVersion > currentBuildVersion) {
				return of(
					onEvent(new Context(), EventType.Action, 'newBuild', {
						action: {
							label: 'Reload',
							callback: () => {
								let reloadedStr = localStorage.getItem('reloadCount');
								if (reloadedStr) {
									let reloadObj = JSON.parse(reloadedStr);
									if (reloadObj.latestVersion === latestBuildVersion && reloadObj.curVersion === currentBuildVersion) {
										if (reloadObj.count <= 2) {
											reloadObj.count++;
											localStorage.setItem('reloadCount', JSON.stringify(reloadObj));
										} else {
											App.showToast('Reloading App...');
											App.clearWebViewCache();
										}
									} else {
										localStorage.setItem(
											'reloadCount',
											JSON.stringify({
												latestVersion: latestBuildVersion,
												curVersion: currentBuildVersion,
												count: 1,
											})
										);
									}
								} else {
									localStorage.setItem(
										'reloadCount',
										JSON.stringify({
											latestVersion: latestBuildVersion,
											curVersion: currentBuildVersion,
											count: 1,
										})
									);
								}
								window.location.href = '/';
							},
						},
						message: `New updates are available`,
						persist: true, //parseInt(latestBuildVersion) > currentBuildVersion + 2
					})
				);
			}

			return EMPTY;
		})
	);

const changePageEpic = (action$, state$) =>
	action$.pipe(
		ofType(ChangePage),
		concatMap((action: { type: string; payload: string }) => {
			let [page, state] = action.payload.split('$');
			if (page.startsWith('http')) {
				if (page.startsWith(window.location.origin)) {
					page = page.replace(window.location.origin, '');
					page = page.replace('/?r=', '').replace(/&title.*/, '');
				} else {
					window.location.href = page;
					return EMPTY;
				}
			}
			if (state) {
				return of(push(page, JSON.parse(state)));
			} else {
				return of(push(page));
			}
		})
	);

let haveOverridePlaybackSpeed = false;

const appMediaCurrentTimeEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateElapsedTime),
		throttleTime(500),
		switchMap((action: { type: string; payload: number }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}
			// if (audioDetails.currentState === AudioCurrentState.Seeking) {
			// 	return concat(
			// 		of(updateAudioPlaybackTime(action.payload)),
			// 		of(updateAudioPlaybackState(AudioCurrentState.Playing))
			// 	);
			// }
			let playbackState = (state$.value as ApplicationState).tempState.audioPlaybackState;
			let currentTime = playbackState.currentTime;
			let articleId = audioDetails.articleList[audioDetails.currentIndex].id;

			// try {
			// 	let startTime: number = -1;
			// 	let endTime: number = -1;
			// 	if (articleId === 'JSAbPtmmAoMIZrMiEYKL' || articleId === 'v8X3COp68cPdPWQs0DjI') {
			// 		startTime = 730;
			// 		endTime = 752; //752
			// 	}

			// 	if (playbackState.playbackSpeed !== 1) {
			// 		if (currentTime >= startTime && currentTime <= endTime) {
			// 			App.setPlaybackSpeed(1.0);
			// 			haveOverridePlaybackSpeed = true;
			// 		} else if (haveOverridePlaybackSpeed) {
			// 			App.setPlaybackSpeed(playbackState.playbackSpeed);
			// 			haveOverridePlaybackSpeed = false;
			// 		}
			// 	}
			// } catch (e) {
			// 	console.log(e);
			// }

			if (Math.floor(currentTime) !== Math.floor(action.payload)) {
				let mediaLength = audioDetails.articleList[audioDetails.currentIndex].mediaLength;
				let playedMedia = (state$.value as ApplicationState).offlineState.playedMedia;
				let curPlayedTime = playedMedia[articleId]?.time ?? 0;
				let forceUpdate = mediaLength - action.payload < 3 || mediaLength - curPlayedTime < 10;

				return concat(
					of(updateAudioPlaybackTime(Math.floor(action.payload))),
					of(updatePlayedMedia(articleId, Math.floor(action.payload), forceUpdate))
				);
			} else {
				return EMPTY;
			}
		})
	);

const appMediaDurationEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateDuration),
		switchMap((action: { type: string; payload: number }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}
			return of(updateAudioPlaybackDuration(action.payload));
		})
	);

const appMediaEndEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidPlayToEnd),
		switchMap((action: { type: string; payload: string }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}
			return of(updateAudioPlaybackState(AudioCurrentState.Ended));
		})
	);

const appMediaTransitionEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidTransitionToMedia),
		switchMap((action: { type: string; payload: { audioId: string; reason: number } }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}
			let newIndex = audioDetails.articleList.findIndex((value) => value.id === action.payload.audioId);
			return of(updateAudioPlaybackArticleIndex(newIndex));
		})
	);

const appMediaStatusEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateStatus),
		concatMap((action: { type: string; payload: string }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}

			let curState;
			let status: string = action.payload;
			if (status.indexOf('paused') === 0) {
				status = 'paused';
			}
			if (status.indexOf('error') === 0) {
				status = 'error';
			}
			switch (status) {
				case 'playing':
					curState = AudioCurrentState.Playing;
					break;
				case 'paused':
					curState = AudioCurrentState.Paused;
					break;
				case 'idle':
					return EMPTY;
				case 'buffering':
					return EMPTY;
				case 'error':
					curState = AudioCurrentState.Error;
					break;
				default:
					return EMPTY;
			}
			return of(updateAudioPlaybackState(curState));
		})
	);

const appMediaEpic = combineEpics(appMediaStatusEpic, appMediaCurrentTimeEpic, appMediaDurationEpic, appMediaEndEpic, appMediaTransitionEpic);

const checkAppUpdate = (state: ApplicationState) => {
	if (isWebViewEnv() && ((isAndroid() && state.uxState.appParams.appBuildCode >= 61) || (isApple() && state.uxState.appParams.appBuildCode >= 58))) {
		let configs = state.dataState.configs.byId;
		let updatePolicyConfig = configs[DBConfig.AppUpdatePolicy];

		if (updatePolicyConfig && updatePolicyConfig.active && updatePolicyConfig.value) {
			let updatePolicy = updatePolicyConfig.value;

			let minBuildCode = isAndroid() ? updatePolicy.minAndroidBuild : updatePolicy.minIosBuild;
			let message = updatePolicy.message;
			let policy = updatePolicy.policy;

			let appBuildCode = state.uxState.appParams.appBuildCode;

			if (appBuildCode < minBuildCode) {
				let routeAction = push('/appupdate', { ...appUpdateRoute.state, message, policy });
				return of(routeAction);
			}
		}
	}
};

const webAppInitEpic = (action$, state$) =>
	action$.pipe(
		ofType(WEB_APP_INIT),
		// debounceTime(4000),
		switchMap(() => {
			let state = state$.value as ApplicationState;

			let updateAction = checkAppUpdate(state);
			if (updateAction) {
				return updateAction;
			}

			let user = state.userState.userStore.user;
			let userData = state.userState.userStore.userData;

			let offlineAudio = state.offlineState.audio;
			let articles = state.dataState.articles.byId;
			let settings = state.uxState.settings;

			updatePushSetting(state.uxState.settings.push);

			let result: any[] = [
				of(updateSortOrder('..clearall', '')),
				of(updateFilters('..clearall', '')),
				of(performOperationDaily('today', updateToday(), true, undefined, true)),
			];
			let signinSkipped = true || parseInt(localStorage.getItem('SkippedSignIn') ?? '0') >= new Date().getTime() - 24 * 60 * 60 * 1000;

			if (isWebViewEnv() && !signinSkipped) {
				if (!user) {
					let phoneRouteAction = multiAction([push('/'), push(phoneSigninRoute.to, phoneSigninRoute.state)]);
					result.push(
						of(
							performOperationOnceInSession('usersignin', phoneRouteAction, false, () => {
								return !(state$.value as ApplicationState).userState.userStore.user;
							})
						)
					);
				}

				// if (user && !user.phoneNumber) {
				// 	let phoneRouteAction = multiAction([push('/'), push(phoneSigninRoute.to, phoneSigninRoute.state)]);
				// 	result.push(of(performOperationOnceInSession('phonesignin', phoneRouteAction)));
				// }
			}

			// if (userData && (!userData.fullName || !userData.fullName.length)) {
			// 	let profileRouteAction = multiAction([push('/'), push('/account/profile', { isModal: true })]);
			// 	result.push(of(performOperationOnceInSession('profilefullname', profileRouteAction)));
			// }

			// if (settings?.download) {
			for (let id in offlineAudio) {
				if (!articles[id] || !articles[id].mediaUri) {
					result.push(of(removeOfflineAudio(id, true)));
					continue;
				}

				let progress = offlineAudio[id]?.progress;
				if (progress && progress?.status !== DownloadStatus.Complete) {
					result.push(of(addOfflineAudio(articles[id], false)));
				} else if (offlineAudio[id]?.exportProgress && offlineAudio[id]?.exportProgress?.status !== DownloadStatus.Complete) {
					result.push(of(addOfflineAudio(articles[id], true)));
				}
			}
			// }

			if (isWebViewEnv()) {
				App.stopAndRelease();
				App.setPlaybackSpeed(1.0);
				if ((isAndroid() && state.uxState.appParams.appBuildCode >= 63) || (isApple() && state.uxState.appParams.appBuildCode >= 60)) {
					App.setPlaybackSpeedPolicy(JSON.stringify(PlaybackSpeedPolicy));
				}
				console.log('App.stopAndRelease');
			}

			if (isWebViewEnv() && false) {
				// We should download PKP and other imp files/images etc..
				let configs = state.dataState.configs.byId;
				let autoDownloadIds: string[] = (configs[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];
				let autoDownloadActions: any[] = [];

				autoDownloadIds.forEach((id) => {
					if (articles[id] && (!offlineAudio[id] || offlineAudio[id].progress?.status !== DownloadStatus.Complete)) {
						if (autoDownloadActions.length === 0) {
							autoDownloadActions.push(
								of(
									onEvent(new Context(), EventType.Information, 'autoDownload', {
										success: true,
										message: "Downloading 'Pooja Ke Phool' for offline play...",
									})
								).pipe(delay(10000))
							);

							autoDownloadActions.push(of(addOfflineAudio(articles[id], false)));
						} else {
							autoDownloadActions.push(
								of(addOfflineAudio(articles[id], false))
								// .pipe(
								// 	delayWhen(() => action$.ofType(DidDownloadMedia))
								// )
							);
						}
					}
				});

				if (autoDownloadActions.length) {
					result.push(of(queueOps('autoDownload', concat(...[...autoDownloadActions]))));
				}
			}

			return concat(...[...result]);
		})
	);

export const appEpic = combineEpics(appMediaEpic, changePageEpic, webAppInitEpic, appBuildVersionEpic);
