import { Fab } from '@mui/material';
import RecordList from 'components/RecordList';
import { Icons } from 'config/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateTimerLog } from 'store/data/timer/actions';
import { getFilteredTimerLogs } from 'store/data/timer/selectors';
import { ApplicationState, TimerLog, TimerLogs, TimerType } from 'types';
import { TimerLogForm } from './TimerLogForm';
import { TimerLogTile } from './TimerLogTile';

const classes = {
	fab: {
		position: 'fixed' as 'fixed',
		bottom: '75px; bottom: calc(75px + env(safe-area-inset-bottom)/2)',
		right: 30,
		zIndex: 1,
	},
};

interface Props {
	timerLogs: TimerLogs;
	updateTimerLog: (timerLog: TimerLog, startTime: number) => void;
}

function getItemSize(index) {
	return 95;
}

const Component = (props: Props) => {
	const listRef = React.createRef<any>();

	let { timerLogs, updateTimerLog } = props;
	let [addLog, setAddLog] = useState(false);

	const scrollToItem = (index: number) => {
		listRef.current && (listRef.current as any).scrollToItem(index, 'auto');
	};

	return (
		<>
			<RecordList
				ref={listRef}
				sx={{ marginRight: 0 }}
				records={Object.keys(timerLogs)
					.sort((a, b) => parseInt(b) - parseInt(a))
					.map((startTime) => ({ startTime: parseFloat(startTime), timerLog: timerLogs[startTime] }))}
				fetching={false}
				RecordTile={TimerLogTile}
				getItemSize={getItemSize.bind(this)}
				// initialScrollOffset={currentIndex * this.getItemSize(0)}
			></RecordList>
			<Fab
				color="primary"
				sx={classes.fab}
				onClick={() => {
					setAddLog(true);
				}}
			>
				{Icons.Add}
			</Fab>
			{addLog && (
				<TimerLogForm
					actionText="Add"
					startTime={Math.floor(new Date().getTime())}
					duration={3600}
					action={async (startTime: number, duration: number) => {
						console.log(startTime, duration);

						let log: TimerLog = {
							type: TimerType.Dhyan,
							endTime: startTime + duration * 1000,
							duration,
							music: false,
							updatedAt: new Date().getTime(),
							isManual: true,
						};

						updateTimerLog(log, startTime);
					}}
					onClose={() => {
						setAddLog(false);
					}}
				/>
			)}
		</>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {
		timerLogs: getFilteredTimerLogs(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateTimerLog: (timerLog: TimerLog, startTime: number) => {
			dispatch(updateTimerLog(timerLog, startTime, true));
		},
	};
}

export const TimerLogsList = connect(mapStateToProps, mapDispatchToProps)(Component);
