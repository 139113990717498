import { TimerLogs } from 'types';
import { DeleteTimerLog, DELETE_TIMER_LOG, UpdateAllTimerLogs, UpdateTimerLog, UPDATE_ALL_TIMER_LOGS, UPDATE_TIMER_LOG } from './actions';

export function timerLogsReducer(curState: TimerLogs = {}, action: UpdateTimerLog | UpdateAllTimerLogs | DeleteTimerLog) {
	switch (action.type) {
		case UPDATE_TIMER_LOG:
			if (action.startTime === 0) {
				throw 'startTime is Zero';
			}

			if (Math.floor(action.startTime) !== action.startTime) {
				throw 'startTime is not integer: ' + action.startTime;
			}

			if (!action.force && action.timerLog.duration - (curState[action.startTime]?.duration ?? 0) < 10) {
				return curState;
			}

			let duration = action.force ? action.timerLog.duration : Math.max(curState[action.startTime]?.duration ?? 0, action.timerLog.duration);
			if (duration === curState[action.startTime]?.duration) {
				return curState;
			}

			var state = { ...curState };

			state[action.startTime] = {
				...state[action.startTime],
				...action.timerLog,
				duration: duration,
				updatedAt: new Date().getTime(),
			};

			return state;
		case UPDATE_ALL_TIMER_LOGS:
			return action.timerLogs;
		case DELETE_TIMER_LOG:
			var state = { ...curState };

			// for (let key in state) {
			// 	state[key].duration = 0;
			// }

			if (state[action.startTime]) {
				state[action.startTime] = {
					...state[action.startTime],
					duration: 0,
					updatedAt: new Date().getTime(),
				};

				// delete state[action.startTime];
			}

			return state;

		default:
			return curState;
	}
}
