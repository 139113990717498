import { Box, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { EndContent } from 'components/EndContent';
import ProgressBar from 'components/shell/ProgressBar';
import { isAdmin } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Article } from 'types';
import TextContent from '../TextContent';

interface ArticleProps {
	href: string;
	isEmbed?: boolean;
	includeTitle?: boolean;
	includeContext?: boolean;
	articleType: string;

	article?: Article;
	locale: string;
	isAdmin: boolean;
	showAll: boolean;
}

const classes = {
	notitle: {
		margin: '8px auto',
		height: 20,
	},
	content: (theme) => ({
		padding: theme.spacing(2),
	}),
};

class Component extends React.Component<ArticleProps, any> {
	render() {
		let { article, isEmbed, locale, includeTitle, showAll, includeContext } = this.props;

		if (!isEmbed && !showAll && article && (article.active === false || article.publishTime.seconds > new Date().getTime() / 1000)) {
			article = undefined;
		}

		let lang = article?.text && article?.text[locale] ? locale : article?.text?.hi ? 'hi' : 'en';

		let title =
			includeTitle ?? true ? (
				<div className={'locale-' + lang}>
					{React.createElement(
						'heading-one',
						{},
						article ? article.title[locale] || article.title.hi || article.title.en : <Skeleton sx={classes.notitle} animation="wave" width="70%" />
					)}
				</div>
			) : null;

		let contextText = article?.context ? article?.context[locale] || article?.context.hi || article.context?.en : undefined;
		let isHtml = contextText?.trim().startsWith('<');
		let context =
			contextText && includeContext !== false ? (
				<div className={'locale-' + lang}>
					<TextContent
						style={{
							fontSize: '1.4rem',
							fontWeight: isHtml ? 'normal' : 'bold',
							paddingTop: 16,
						}}
						locale={lang}
					>
						{isHtml ? (
							<div
								dangerouslySetInnerHTML={{
									__html: contextText,
								}}
							></div>
						) : (
							<div>({contextText})</div>
						)}
					</TextContent>
				</div>
			) : null;

		let loading = !article ? <ProgressBar /> : null;

		let artText = article?.text ? article?.text[locale] || article?.text.hi || article?.text.en : null;

		if (!showAll && article && article.expireTime.seconds <= new Date().getTime() / 1000) {
			artText = 'Content Expired!!';
		}

		let text = artText ? (
			<TextContent alignRhymes locale={lang}>
				<div dangerouslySetInnerHTML={{ __html: artText }}></div>
			</TextContent>
		) : (
			<Typography variant="body1">
				<Skeleton animation="wave" />
				<Skeleton animation="wave" />
				<Skeleton animation="wave" />
			</Typography>
		);
		return (
			<>
				{loading}
				<Box sx={isEmbed ? {} : classes.content}>
					{context}
					{title}
					{text}
				</Box>
				{isEmbed ? null : <EndContent locale={lang} isb={article?.articleType === 'shriamarvani'} />}
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState, props: { href: string; articleType: string; isEmbed?: boolean; includeTitle?: boolean }) {
	let articles = state.dataState.articles.byId;
	let article = articles[props.href];

	let admin = isAdmin(state.userState.userStore.user, state.userState.userStore.userData);

	return {
		href: props.href,
		article: article,
		locale: state.uxState.locale,
		isAdmin: admin,
		showAll: admin && state.userState.userStore.userData?.settings && state.userState.userStore.userData?.settings['admin.records'],
	};
}

export const ArticleTextContent = connect(mapStateToProps)(Component);
