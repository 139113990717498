import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { ArticleTypeMap } from 'config';
import { Icons } from 'config/icons';
import { canContinouslyPlay, getContentStorageUrl, isWebEnv, shuffleArray } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { updateAudioPlaybackRepeat } from 'store/temp/actions';
import { ApplicationState, Article, ContentType } from 'types';
import { AudioPlaybackInstance } from './AudioPlayback';
import AudioSlides from './AudioSlides';
import FullScreenPlayerControls from './FullScreenPlayerControls';
import FullScreenPlayerTitle from './FullScreenPlayerTitle';
import { MediaPlayer } from './MediaPlayer';
import PlayList from './PlayList';

const classes = {
	root: (props) => ({
		display: props.enabled ? 'flex' : 'none',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		height: '100%',
	}),
	title: {
		flex: '0 0 auto',
	},
	audioslides: {
		flex: '1 0 auto',

		backgroundColor: grey[50],
	},
	playlistContent: {
		padding: 0,
		flex: '1 1 auto',
		flexGrow: 1,
	},
	upnext: (theme: Theme) => ({
		flex: '0 0 auto',
		boxShadow: '0 -1px 5px lightgrey',
		backgroundColor: grey[50],

		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
		},

		'& .MuiCardHeader-avatar': {
			marginRight: theme.spacing(1),
		},

		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
	}),
	controls: {
		flex: '0 0 auto',
		boxShadow: '0 -1px 5px lightgrey',
		height: '227px',
		background: 'white',
		zIndex: 1,

		'&.absolute': {
			position: 'absolute',
			backgroundColor: 'white',
			transition: 'all ease 0.5s',

			bottom: 0,
			left: 0,
			right: 0,
		},
	},
	avatar: {
		// color: theme.typography.body2.color,
		backgroundColor: 'inherit',
		color: 'rgba(0, 0, 0, 0.54)',
		width: 'auto',
	},
};

interface PlayerProps {
	enabled: boolean;
	stopMusicPlayer: (e: React.MouseEvent) => void;
	playPauseAudio: (e: React.MouseEvent) => void;
	toggleFullScreen: (e: React.MouseEvent) => void;
	moveToIndex: (index: number) => void;
	articles?: Article[];
	currentIndex: number;
	repeat: number;
	updateAudioPlaybackRepeat: (repeat: number) => void;
}

interface PlayerState {
	fullspaceSlide: boolean;
	hiddenControls: boolean;
	hiddenPlaylist: boolean;
}

class FullscreenPlayer extends React.PureComponent<PlayerProps, PlayerState> {
	private timer = -1;
	private listRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			fullspaceSlide: false,
			hiddenControls: false,
			hiddenPlaylist: false,
		};
	}

	componentDidMount(): void {
		if (canContinouslyPlay()) {
			AudioPlaybackInstance().setRepeatMode(this.props.repeat);
		}
	}

	render() {
		let { stopMusicPlayer, playPauseAudio, toggleFullScreen, moveToIndex, articles, currentIndex, repeat, updateAudioPlaybackRepeat } = this.props;
		let { fullspaceSlide, hiddenControls, hiddenPlaylist } = this.state;

		let showPlaylist = articles && articles.length > 1 && !fullspaceSlide;
		let slidesHeight = fullspaceSlide ? 'calc(100% - 60px)' : showPlaylist ? 'calc(100% - 351px)' : 'calc(100% - 280px)';

		let nextTrack = articles && (currentIndex < articles.length - 1 ? articles[currentIndex + 1] : repeat ? articles[0] : null);
		let nextTrackTitle = nextTrack ? nextTrack.title.hi || nextTrack.title.en || '' : 'None';

		let auraList = [
			{ src: getContentStorageUrl(ContentType.PKP, 'webapp/aura.jpeg'), scale: '3.8', bgColor: '#e9eae3', style: { transformOrigin: '50.5% 49.5%' } },
		];

		let article = articles ? articles[currentIndex] : undefined;
		let aura;
		if (article) {
			let auraIndex = (article?.id.charCodeAt(0) ?? 0) % auraList.length;
			aura = auraList[auraIndex];
		}

		let isVideo = false;

		if (isWebEnv() && article?.mediaType === 'video') {
			slidesHeight = 'auto';
			isVideo = true;
		}

		return (
			<Box sx={classes.root(this.props)}>
				<Box sx={classes.title}>
					<FullScreenPlayerTitle
						title={article ? ArticleTypeMap[article.articleType] : undefined}
						onMinimize={toggleFullScreen}
						onClose={stopMusicPlayer}
					/>
				</Box>
				<Box
					sx={classes.audioslides}
					style={
						isVideo
							? {}
							: {
									maxHeight: slidesHeight,
									display: hiddenPlaylist || !showPlaylist ? 'block' : 'none',
									overflow: fullspaceSlide ? 'scroll' : 'hidden',
									backgroundColor: aura ? aura.bgColor : '#f2f4ef',
							  }
					}
					onClick={() => {
						if (fullspaceSlide) this.setState({ hiddenControls: !hiddenControls });
					}}
				>
					<AudioSlides
						slidesHeight={slidesHeight}
						aura={aura}
						needFullScreenSpace={(value) => {
							if (this.timer) {
								clearTimeout(this.timer);
							}
							if (value === true && fullspaceSlide === false) {
								this.timer = window.setTimeout(() => {
									this.setState({ fullspaceSlide: value, hiddenControls: true });
								}, 3000);
							}

							this.setState({ fullspaceSlide: value });
						}}
					/>
				</Box>
				{showPlaylist ? (
					<>
						<CardContent sx={classes.playlistContent} style={{ display: hiddenPlaylist ? 'none' : 'block', height: slidesHeight }}>
							<PlayList ref={this.listRef} />
						</CardContent>
						<CardHeader
							sx={classes.upnext}
							onClick={() => {
								this.listRef && this.listRef.current && (this.listRef.current as any).scrollToItem(currentIndex, 'auto');
								// moveToIndex(currentIndex);
								// this.setState({ hiddenPlaylist: !hiddenPlaylist });
							}}
							avatar={
								<Avatar sx={classes.avatar} variant="square">
									{Icons.DoubleArrow}
								</Avatar>
							}
							action={
								<>
									<IconButton
										onClick={(event) => {
											event.stopPropagation();
											let newList = shuffleArray(articles || []);
											MediaPlayer.instance().playRecords([...newList]);
										}}
									>
										{Icons.Shuffle}
									</IconButton>
									<IconButton
										color={repeat !== 0 ? 'primary' : 'default'}
										onClick={(event) => {
											let repeatMode = (repeat + 1) % 3;
											event.stopPropagation();

											if (canContinouslyPlay()) {
												updateAudioPlaybackRepeat(repeatMode);
												AudioPlaybackInstance().setRepeatMode(repeatMode);
											} else {
												updateAudioPlaybackRepeat(repeat === 0 ? 2 : 0);
											}
										}}
									>
										{repeat === 0 ? Icons.Repeat : repeat === 1 ? Icons.RepeatOneIcon : Icons.RepeatOnIcon}
									</IconButton>
									{/* <IconButton onClick={() => {}}>
							<QueueMusicSharpIcon fontSize='large' />
						</IconButton> */}
								</>
							}
							subheader={
								<Typography color="textSecondary" noWrap>
									{nextTrackTitle}
								</Typography>
							}
							title={'Next Track'}
							// subheader={'Up Next'}
						></CardHeader>
					</>
				) : null}

				{!isVideo && (
					<Box
						sx={classes.controls}
						className={clsx({ absolute: fullspaceSlide })}
						style={{
							bottom: hiddenControls ? '-220px' : 0,
							boxShadow: showPlaylist ? 'none' : '0 -1px 5px lightgrey',
						}}
					>
						<FullScreenPlayerControls playPauseAudio={playPauseAudio} moveToIndex={moveToIndex} />
					</Box>
				)}
			</Box>
		);
	}
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = tempState;

	return {
		articles: audioPlaybackDetails?.articleList,
		currentIndex: audioPlaybackDetails?.currentIndex || 0,
		repeat: audioPlaybackState.repeat,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateAudioPlaybackRepeat: (repeat: number) => {
			dispatch(updateAudioPlaybackRepeat(repeat));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenPlayer);
