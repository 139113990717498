import { ArticleTile } from 'components/articles/ArticleTile';
import ReorderRecords from 'components/ReorderRecords';
import React from 'react';
import { connect } from 'react-redux';
import { reorderPlaylist } from 'services/api/playlists';
import { getUserPlaylists } from 'store/data/user/selectors';
import { ApplicationState, Article, UserPlaylist, UserPlaylistTrack } from 'types';
import { arrayMoveImmutable } from 'array-move';

interface ReorderPlaylistProps {
	playlistId: string;
	playlist: UserPlaylist;
	articles: { [id: string]: Article };
	reorderPlaylist: (playlist: UserPlaylist, orderedTracks: UserPlaylistTrack[]) => void;
}

const ReorderPlaylist = (props: ReorderPlaylistProps) => {
	let { playlist, articles, reorderPlaylist } = props;

	let tracks: UserPlaylistTrack[] = [];
	for (let articleId in playlist!.tracks) {
		tracks.push({ articleId: articleId, order: playlist!.tracks[articleId].order });
	}
	return (
		<>
			<ReorderRecords
				records={tracks}
				getRecordId={(track) => track.articleId}
				onOrderChange={(records: any[], oldIndex: number, newIndex: number) => {
					let orderedTracks = arrayMoveImmutable(records, oldIndex, newIndex);
					reorderPlaylist(playlist, orderedTracks);
					return orderedTracks;
				}}
				renderTile={(track, index: number) => {
					let article = articles[track.articleId];

					return <ArticleTile style={{ flexGrow: 1, marginTop: 4, marginBottom: 4 }} onClick={() => {}} record={article} />;
				}}
			/>
		</>
	);
};

function mapStateToProps(state: ApplicationState, { playlistId }) {
	let playlists = getUserPlaylists(state);

	return {
		playlist: playlists[playlistId],
		articles: state.dataState.articles.byId,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		reorderPlaylist: (playlist: UserPlaylist, orderedTracks: UserPlaylistTrack[]) => {
			dispatch(reorderPlaylist({ playlist, orderedTracks }));
		},
	};
}

let connectedEle = connect(mapStateToProps, mapDispatchToProps)(ReorderPlaylist);
export default connectedEle;
