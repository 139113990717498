import { HeadObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { logEvent } from 'components/telemetry/index';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { getContentStorageUrl } from 'helpers';
import { triggerFunction } from 'services/api/functions';
import { Article, ContentType, Edition } from 'types';
import firebase, { firebaseConfig } from './initconfig';
import { setRecord } from './index';

let S3: S3Client;
const initR2 = async () => {
	if (!S3) {
		let R2Config: any = localStorage.getItem('R2Config');
		if (!R2Config) {
			R2Config = await triggerFunction('getR2Meta', {});
			localStorage.setItem('R2Config', JSON.stringify(R2Config));
		} else {
			R2Config = JSON.parse(R2Config);
		}

		S3 = new S3Client(R2Config);
	}
};

export const uploadMediaFileToR2 = async (article: Article) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (article.mediaUriR2) {
				console.log('Already present in R2', article);
				reject(false);
				return;
			}

			await initR2();

			let path = 'appstatic/media/' + article.mediaUri;
			let uri = getContentStorageUrl(ContentType.Article, article.articleType, article);

			uri = uri.replace('https://appmedia.atlotusfeet.com/', 'https://storage.googleapis.com/appmedia.atlotusfeet.com/');

			console.log('Original Uri: ', path, uri);
			let fileTitle = article.title?.en ?? article.title?.hi ?? article.title ?? '';
			fileTitle = fileTitle.replace('–', '-').replace('’', "'");
			console.log('fileTitle: ' + fileTitle);

			let result = await window.fetch(uri);
			let file = await result.blob();

			let putObj: any = {
				Bucket: 'appmedia',
				ACL: 'public-read',
				Body: file,
				Key: path,
				ContentType: file.type,
				CacheControl: 'public, max-age=31536000',
				Metadata: {
					filetitle: fileTitle,
				},
			};

			let r2Task = await S3.send(new PutObjectCommand(putObj));

			console.log('Media Uploaded to R2: ', putObj);
			await setRecord('articles', article.id, { mediaUriR2: article.mediaUri });
			console.log('Article record updated', article);
			resolve(true);
		} catch (error) {
			console.error(error);
			logEvent('storage_upload_error', error);
			reject(error);
		}
	});
};

export const uploadSnapshotFileToR2 = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			await initR2();

			let name = 'WebSnapshot - 1723561491620.json';
			let path = 'appstatic/data/' + name;
			let uri = getContentStorageUrl(ContentType.Data, name);

			console.log('Original Uri: ', path, uri);

			// try {
			// 	let r2Media = await S3.send(
			// 		new HeadObjectCommand({
			// 			Bucket: 'appmedia',
			// 			Key: path,
			// 		})
			// 	);

			// 	if (r2Media) {
			// 		console.log('uploadMediaFileToR2: Skipping, Media Found', path, r2Media, edition);
			// 		resolve(true);
			// 		return;
			// 	}
			// } catch (error) {
			// 	console.log('uploadMediaFileToR2: Continuing, Media Not Found', path);
			// }

			let result = await window.fetch(uri);
			let file = await result.blob();

			let putObj: any = {
				Bucket: 'appmedia',
				ACL: 'public-read',
				Body: file,
				Key: path,
				ContentType: file.type,
				CacheControl: 'public, max-age=31536000',
			};

			let r2Task = await S3.send(new PutObjectCommand(putObj));

			console.log('Media Uploaded to R2: ', putObj);
			resolve(true);
		} catch (error) {
			console.error(error);
			logEvent('storage_upload_error', error);
			reject(error);
		}
	});
};

export const uploadBlobToR2 = async (path: string, file: string, type: string) => {
	try {
		await initR2();

		await S3.send(
			new PutObjectCommand({
				Bucket: 'appmedia',
				ACL: 'public-read',
				Body: file,
				Key: path,
				ContentType: type,
				CacheControl: 'public, max-age=31536000',
			})
		);
	} catch (error) {
		logEvent('storage_upload_error', { path, ...error });
	}
};

export const uploadFile = async (
	path: string,
	file: File,
	progressCallback?: (progress: number, bytesTransferred?: number, totalBytes?: number) => void,
	uploadOnR2: boolean = true,
	fileTitle?: string
) => {
	return new Promise(async (resolve, reject) => {
		try {
			let r2Task;
			if (uploadOnR2) {
				await initR2();

				console.log('UploadFile: ' + path);
				r2Task = S3.send(
					new PutObjectCommand({
						Bucket: 'appmedia',
						ACL: 'public-read',
						Body: file,
						Key: path,
						ContentType: file.type,
						CacheControl: 'public, max-age=31536000',
						Metadata: {
							filetitle: fileTitle ?? '',
						},
					})
				);
			}

			var storage = getStorage(firebase);
			var fileRef = ref(storage, path);

			var metadata = {
				contentType: file.type,
				cacheControl: 'public, max-age=31536000',
			};

			let uploadTask = uploadBytesResumable(fileRef, file, metadata);
			uploadTask.on(
				'state_changed',
				async function (snapshot) {
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					progressCallback && progressCallback(progress, snapshot.bytesTransferred, snapshot.totalBytes);

					if (progress === 100) {
						logEvent('storage_upload', { path, totalBytes: snapshot.totalBytes });
						if (r2Task) {
							await r2Task;
						}
						resolve(100);
					}
				},
				function (error: any) {
					switch (error.code) {
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							break;

						case 'storage/canceled':
							// User canceled the upload
							break;

						case 'storage/unknown':
							// Unknown error occurred, inspect error.serverResponse
							break;
					}

					logEvent('storage_upload_error', { path, ...error });
					reject(error);
				},
				function () {
					// Upload completed successfully, now we can get the download URL
					// uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
					// 	console.log('File available at', downloadURL);
					// });
				}
			);
		} catch (error) {
			logEvent('storage_upload_error', { path, ...error });
			reject(error);
		}
	});
};

export const deleteFile = async (path: string) => {
	try {
		console.log('DeleteFile: ' + path);

		// let r2Task = await S3.send(
		// 	new DeleteObjectCommand({
		// 		Bucket: 'appmedia',
		// 		Key: path,
		// 	})
		// );

		var storage = getStorage(firebase);
		var pathReference = ref(storage, path);

		await deleteObject(pathReference);
		logEvent('storage_delete', { path });
	} catch (error) {
		logEvent('storage_delete_error', { path, ...error });
		throw error;
	}
};

export const getDownloadUrl = async (path: string) => {
	var storage = getStorage(firebase);
	var pathReference = ref(storage, path);

	try {
		let downloadUrl = await getDownloadURL(pathReference);
		return downloadUrl;
	} catch (error) {
		// A full list of error codes is available at
		// https://firebase.google.com/docs/storage/web/handle-errors
		switch (error.code) {
			case 'storage/object-not-found':
				// File doesn't exist
				break;

			case 'storage/unauthorized':
				// User doesn't have permission to access the object
				break;

			case 'storage/canceled':
				// User canceled the upload
				break;

			case 'storage/unknown':
				// Unknown error occurred, inspect the server response
				break;
		}
		logEvent('storage_download_error', { path, ...error });
	}
};

export const getStorageUrl = (path: string) => {
	path = path.replace(/\//g, '%2F');

	return 'https://firebasestorage.googleapis.com/v0/b/' + firebaseConfig.storageBucket + '/o/' + path + '?alt=media';
};
