import { Box, Card, CardHeader } from '@mui/material';
import ProgressBar from 'components/shell/ProgressBar';
import React, { forwardRef } from 'react';
import { ConnectedComponent } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export interface RecordListProps {
	ref?: any;
	autoSizerKey?: string;
	RecordTile: ((props: any) => JSX.Element | null) | ConnectedComponent<(props: any) => JSX.Element | null, any | null>;
	RecordTileProps?: any;
	recordTileSpecificProps?: (records: any[], index: number) => any;
	records: any[];
	fetching?: boolean;
	initialScrollOffset?: number;
	getItemSize: (index: number) => number;
	includePinchZoom?: boolean;
	onItemsRendered?: (overscanStartIndex: number, overscanStopIndex: number, visibleStartIndex: number, visibleStopIndex: number) => void;
	onScroll?: (scrollDirection: string, scrollOffset: number, scrollUpdateWasRequested: boolean) => void;
	sx?: any;
}

const classes = {
	root: {
		marginRight: '10px',
		width: '100%',
		height: '100%',
		// 'min-height': '60vh'
	},
	inline: {
		display: 'inline',
	},
	paper: {
		marginBottom: 4,
	},
	button: {
		flex: '1 1 auto',
		borderRadius: 0,
		borderRight: '1px solid darkgrey',

		'&:last-of-type': {
			borderRight: 'none',
		},
	},
	outerEl: {
		// overflowX: 'hidden !important' as 'hidden',
	},
};

export let RecordsListControl: RecordList;

class RecordList extends React.Component<RecordListProps, any> {
	private listRef = React.createRef();
	private listElRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			scale: 1.0,
		};
	}

	public resetAfterIndex(index: number) {
		if (this.listRef.current) {
			(this.listRef.current as VariableSizeList).resetAfterIndex(index);
		}
	}

	public scrollToItem = (index: number, align: string = 'auto', scrollBehavior: string = 'smooth') => {
		if (this.listElRef.current) {
			(this.listElRef.current as HTMLElement).style.scrollBehavior = scrollBehavior;
		}
		(this.listRef.current as VariableSizeList)?.scrollToItem(index, align);
	};

	private renderRow(props: ListChildComponentProps) {
		const { index, style } = props;
		let { records, RecordTile, RecordTileProps, recordTileSpecificProps } = this.props;

		let record = records[index];
		let specificProps = recordTileSpecificProps ? recordTileSpecificProps(records, index) : {};

		return (
			<div style={style} key={'fragment-' + index}>
				{record ? <RecordTile listCtrl={this} record={record} index={index} {...RecordTileProps} {...specificProps} /> : <RecordTile />}
			</div>
		);
	}

	componentDidMount() {
		RecordsListControl = this;
	}

	componentDidUpdate() {
		this.resetAfterIndex(0);
	}

	outerElementType = forwardRef((props, ref: any) =>
		this.props.includePinchZoom === true ? (
			<TransformWrapper wheel={{ disabled: true }} panning={{ lockAxisY: true, velocityDisabled: true }}>
				<TransformComponent wrapperStyle={{ height: '100%', overflow: 'auto' }} contentStyle={{ height: '100%' }}>
					<Box ref={ref} {...props} sx={classes.outerEl} />
				</TransformComponent>
			</TransformWrapper>
		) : (
			<div ref={ref} {...props} />
		)
	);

	render() {
		let { records, fetching, getItemSize, initialScrollOffset, autoSizerKey } = this.props;
		records = records || Array.from(new Array(4));

		if (records.length === 0) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader subheader="No records found" />
				</Card>
			);
		}

		let loadingTile = fetching ? <ProgressBar /> : null;
		let tiles = (
			<AutoSizer key={autoSizerKey ?? 'auto-' + records.length} style={{ position: 'inherit', height: '100%' }}>
				{({ height, width }) => (
					<VariableSizeList
						ref={this.listRef}
						outerRef={this.listElRef}
						outerElementType={this.outerElementType}
						itemSize={getItemSize}
						height={height}
						width={width}
						itemCount={records.length}
						overscanCount={4}
						initialScrollOffset={initialScrollOffset}
						onItemsRendered={({ overscanStartIndex, overscanStopIndex, visibleStartIndex, visibleStopIndex }) => {
							if (this.props.onItemsRendered) {
								this.props.onItemsRendered(overscanStartIndex, overscanStopIndex, visibleStartIndex, visibleStopIndex);
							}
						}}
						onScroll={({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
							if (this.props.onScroll) {
								this.props.onScroll(scrollDirection, scrollOffset, scrollUpdateWasRequested);
							}
						}}
					>
						{this.renderRow.bind(this)}
					</VariableSizeList>
				)}
			</AutoSizer>
		);

		return (
			<Box sx={{ ...classes.root, ...this.props.sx }}>
				{loadingTile}
				{tiles}
			</Box>
		);
	}
}

export default RecordList;
