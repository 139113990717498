import { Box, Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { grey } from '@mui/material/colors';
import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';

const classes = {
	root: (theme) => ({
		height: '100%',
		display: 'flex',
		flexDirection: 'row',

		'& .swiper': { width: '100%', height: '100%' },
		'& .swiper-slide': {
			textAlign: 'center',
			fontSize: '1.8rem',
			// background: '#fff',

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 40,
			// width: 40,
			color: grey[400],

			'&.swiper-slide-active': {
				fontSize: '2.4rem',
				color: theme.palette.text.secondary,
			},
		},

		'& .swiper-slide img': {
			display: 'block',
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
	}),
};

export const PickerItem = (props: { values: any[]; loop?: boolean; label: string; onChange?: (val) => void }) => {
	let [active, setActive] = useState(0);

	let commonSettings = (isl) => ({
		// style: swiperStyles(isl),
		// pagination: paginationSettings,
		// initialSlide: currentIndex,
		onActiveIndexChange: (swiper) => {
			setActive(swiper.activeIndex);
			if (props.onChange) {
				props.onChange(props.values[swiper.activeIndex]);
			}
		},
		direction: 'vertical',
		freeMode: {
			enabled: true,
			sticky: true,
		},
		grabCursor: true,
		spaceBetween: 5,
		modules: [FreeMode],
		slidesPerView: 3,
		centeredSlides: true,
		// centeredSlidesBounds: true,
	});

	let slides = props.values.map((val, i) => {
		return (
			<SwiperSlide key={i}>
				<Box sx={{ padding: 0.5, display: 'flex', flexDirection: 'row' }}>
					<Typography variant="h6" fontSize="inherit">
						{val}
					</Typography>
				</Box>
			</SwiperSlide>
		);
	});

	return (
		<Box sx={classes.root}>
			<Swiper
				{...commonSettings(false)}
				// onSwiper={setSwiperRef}
				className="mainSwiper"
				// effect={'material'}
				centeredSlides={true}
				slidesPerView={'auto'}
			>
				{slides}
			</Swiper>
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 0.5 }}>
				<Typography variant="h6" fontSize="2rem" color="textSecondary">
					{props.label}
				</Typography>
			</Box>
		</Box>
	);
};

interface Props {
	ampm?: boolean;
	onAccept?: (secs: number) => void;
	maxHours?: number;
}

const Component = (props: Props) => {
	let [hour, setHour] = useState(0);
	let [min, setMin] = useState(0);

	const minStep = 1;

	return (
		<Box sx={{ position: 'relative', height: 220, my: 1, overflow: 'hidden', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
			<PickerItem
				values={Array.from({ length: props.maxHours ?? 24 }, (v, k) => (k < 10 ? '0' : '') + k)}
				label="hours"
				loop={false}
				onChange={(hr) => setHour(parseInt(hr))}
			/>
			<Box sx={{ ml: 3 }}>
				<PickerItem
					values={Array.from({ length: 60 }, (v, k) => (k < 10 ? '0' : '') + k).filter((v) => parseInt(v) % minStep === 0)}
					label="mins"
					loop={false}
					onChange={(mn) => setMin(parseInt(mn))}
				/>
			</Box>
			<Button
				sx={{ position: 'absolute', bottom: 0, right: 8 }}
				color="primary"
				disabled={hour + min === 0}
				onClick={(e) => {
					e.stopPropagation();

					if (props.onAccept) {
						props.onAccept((hour * 60 + min) * 60);
					}
				}}
			>
				Select
			</Button>
		</Box>
	);
};

export const TimeSelectorControl = connect()(Component);
