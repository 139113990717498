import { logEvent } from 'components/telemetry';
import { Action } from 'redux';
import { AppParams, LocaleType, TimerPreset } from 'types';

export const CHANGE_LOCALE = '@@ux/CHANGE_LOCALE';
export type T_CHANGE_LOCALE = typeof CHANGE_LOCALE;

export interface ChangeLocale extends Action {
	type: T_CHANGE_LOCALE;
	locale: LocaleType;
}

export const changeLocale = (locale: LocaleType): ChangeLocale => {
	logEvent('locale', locale);

	return {
		type: CHANGE_LOCALE,
		locale: locale,
	};
};

export const UPDATE_BOOKLET_INDEX = '@@ux/UPDATE_BOOKLET_INDEX';
export type T_UPDATE_BOOKLET_INDEX = typeof UPDATE_BOOKLET_INDEX;

export interface UpdateBookletIndex extends Action {
	type: T_UPDATE_BOOKLET_INDEX;
	bookletId: string;
	index: number;
}

export const updateBookletIndex = (bookletId: string, index: number): UpdateBookletIndex => {
	return {
		type: UPDATE_BOOKLET_INDEX,
		bookletId,
		index,
	};
};

export const UPDATE_PAGE_MARKER = '@@ux/UPDATE_PAGE_MARKER';
export type T_UPDATE_PAGE_MARKER = typeof UPDATE_PAGE_MARKER;

export interface UpdatePageMarker extends Action {
	type: T_UPDATE_PAGE_MARKER;
	id: string;
	value: number;
}

export const updatePageMarker = (id: string, value: number): UpdatePageMarker => {
	return {
		type: UPDATE_PAGE_MARKER,
		id,
		value,
	};
};

export const UPDATE_ACTIVE_NOTIFICATIONS = '@@ux/UPDATE_ACTIVE_NOTIFICATIONS';
export type T_UPDATE_ACTIVE_NOTIFICATIONS = typeof UPDATE_ACTIVE_NOTIFICATIONS;

export interface UpdateActiveNotifications extends Action {
	type: T_UPDATE_ACTIVE_NOTIFICATIONS;
	count: number;
}

export const updateActiveNotifications = (count: number): UpdateActiveNotifications => {
	return {
		type: UPDATE_ACTIVE_NOTIFICATIONS,
		count,
	};
};

export const UPDATE_USER_SETTINGS = '@@ux/UPDATE_USER_SETTINGS';
export type _UPDATE_USER_SETTINGS = typeof UPDATE_USER_SETTINGS;

export interface UpdateUserSettings extends Action {
	type: _UPDATE_USER_SETTINGS;
	settings: { [key: string]: any };
	fromProfile?: boolean;
}

export const updateUserSettings = (settings: { [key: string]: any }, fromProfile?: boolean): UpdateUserSettings => {
	return {
		type: UPDATE_USER_SETTINGS,
		settings,
		fromProfile,
	};
};

export const UPDATE_APP_PARAMS = '@@ux/UPDATE_APP_PARAMS';
export type T_UPDATE_APP_PARAMS = typeof UPDATE_APP_PARAMS;

export interface UpdateAppParams extends Action {
	type: T_UPDATE_APP_PARAMS;
	appParams: AppParams;
}

export const updateAppParams = (appParams: AppParams): UpdateAppParams => {
	return {
		type: UPDATE_APP_PARAMS,
		appParams,
	};
};

export const UPDATE_SORT_ORDER = '@@ux/UPDATE_SORT_ORDER';
export type T_UPDATE_SORT_ORDER = typeof UPDATE_SORT_ORDER;

export interface UpdateSortOrder extends Action {
	type: T_UPDATE_SORT_ORDER;
	recordType: string;
	sortValue: string;
}

export const updateSortOrder = (recordType: string, sortValue: string): UpdateSortOrder => {
	return {
		type: UPDATE_SORT_ORDER,
		recordType,
		sortValue,
	};
};

export const UPDATE_FILTERS = '@@ux/UPDATE_FILTERS';
export type T_UPDATE_FILTERS = typeof UPDATE_FILTERS;

export interface UpdateFilters extends Action {
	type: T_UPDATE_FILTERS;
	recordType: string;
	filters: any;
}

export const updateFilters = (recordType: string, filters: any): UpdateFilters => {
	return {
		type: UPDATE_FILTERS,
		recordType,
		filters,
	};
};

export const ACK_NOTIFICATION = '@@ux/ACK_NOTIFICATION';
export type T_ACK_NOTIFICATION = typeof ACK_NOTIFICATION;

export interface AckNotification extends Action {
	type: T_ACK_NOTIFICATION;
	id: string;
	time: number;
}

export const ackNotification = (id: string): AckNotification => {
	return {
		type: ACK_NOTIFICATION,
		id,
		time: new Date().getTime() / 1000,
	};
};

export const UPDATE_TIMER_PRESETS = '@@ux/UPDATE_TIMER_PRESETS';
export type T_UPDATE_TIMER_PRESETS = typeof UPDATE_TIMER_PRESETS;

export interface UpdateTimerPresets extends Action {
	type: T_UPDATE_TIMER_PRESETS;
	presets: TimerPreset[];
}

export const updateTimerPresets = (presets: TimerPreset[]): UpdateTimerPresets => {
	return {
		type: UPDATE_TIMER_PRESETS,
		presets,
	};
};

export const UPDATE_TIMER_MUSIC = '@@ux/UPDATE_TIMER_MUSIC';
export type T_UPDATE_TIMER_MUSIC = typeof UPDATE_TIMER_MUSIC;

export interface UpdateTimerMusic extends Action {
	type: T_UPDATE_TIMER_MUSIC;
	enabled: boolean;
}

export const updateTimerMusic = (enabled: boolean): UpdateTimerMusic => {
	return {
		type: UPDATE_TIMER_MUSIC,
		enabled,
	};
};

export const UPDATE_DHYAN_ON_HOME = '@@ux/UPDATE_DHYAN_ON_HOME';
export type T_UPDATE_DHYAN_ON_HOME = typeof UPDATE_DHYAN_ON_HOME;

export interface UpdateDhyanOnHome extends Action {
	type: T_UPDATE_DHYAN_ON_HOME;
	enabled: boolean;
}

export const updateDhyanOnHome = (enabled: boolean): UpdateDhyanOnHome => {
	return {
		type: UPDATE_DHYAN_ON_HOME,
		enabled,
	};
};
