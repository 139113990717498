import { Box } from '@mui/material';
import { Icons } from 'config/icons';
import React, { useState } from 'react';
import { SortableList } from './SortableList';

const classes = {
	listItem: {
		display: 'flex',
		alignItems: 'center',

		'& .handle': {
			cursor: 'grab',
			touchAction: 'none',
		},

		'&.isActive': { opacity: 0.6 },

		'&.isDragged': { cursor: 'grabbing', '& .handle': { cursor: 'grabbing' } },
	},
	root: { overflow: 'auto', touchAction: 'none' },
};

export interface ReorderRecordsProps {
	records: any[];
	renderTile: (record: any, index: number) => JSX.Element;
	getRecordId: (record: any) => string;
	onOrderChange: (records: any[], oldIndex: number, newIndex: number) => any[];
}

const ReorderRecords = (props: ReorderRecordsProps) => {
	let [records, setRecords] = useState([...props.records].sort((a, b) => a.order - b.order));

	const { renderTile, getRecordId } = props;

	return (
		<Box sx={{ overflow: 'auto' }}>
			<SortableList
				items={records}
				getItemId={(item) => getRecordId(item)}
				renderItem={({ item, isActive, isDragged, ref, props, handleProps, index }) => {
					let className = 'is';
					if (isActive) className += ' isActive';
					if (isDragged) className += ' isDragged';
					let tile = renderTile(item, index);

					return (
						<Box ref={ref} key={getRecordId(item)} className={className} sx={classes.listItem} {...props}>
							<Box className="handle" {...handleProps}>
								<Icons.DragIndicatorIcon style={{ fontSize: '3.5em', alignSelf: 'center', padding: '0' }} />
							</Box>
							{tile}
						</Box>
					);
				}}
				onSort={(oldIndex, newIndex) => {
					let newRecords = props.onOrderChange(records, oldIndex, newIndex);
					setRecords(newRecords);
				}}
			/>
		</Box>
	);
};

export default ReorderRecords;
