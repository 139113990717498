import { Box, Card, CardHeader, IconButton, Paper } from '@mui/material';
import App from 'AppInterface/App';
import DialogPage from 'components/pages/DialogPage';
import FullScreenPlayerTitle from 'components/player/FullScreenPlayerTitle';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { Icons } from 'config/icons';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getTimerDurationByDate } from 'store/data/timer/selectors';
import { toggleBottomDrawer, updateTimerRunState } from 'store/temp/actions';
import { updateDhyanOnHome } from 'store/ux/actions';
import { ApplicationState, TimerRunningState, TimerState, TimerType } from 'types';
import { TimerAnalytics } from './TimerAnalytics';
import { TimerLogsList } from './TimerLogs';
import { TimerPresetWidget } from './TimerPresetWidget';
import { TimerProgress } from './TimerProgress';
import { TimerRunningControl } from './TimerRunningControl';

const classes = {
	root: (props) => ({
		display: props.enabled ? 'flex' : 'none',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		height: '100%',
	}),
	title: {
		flex: '0 0 auto',
	},
	progressWrap: {
		backgroundColor: '#f2f4ef',
		display: 'flex',
		flexDirection: 'column',
		padding: 0.5,
		height: '100%',
	},
	paper: { px: 2, py: 1, m: 0.5, borderRadius: 2 },
};

interface Props {
	enabled: boolean;
	timerState?: TimerState;
	durationsByDate: { [date: string]: number };
	showDhyanOnHome: boolean;
	toggleFullScreen: (e: React.MouseEvent) => void;
	stopTimer: (e: React.MouseEvent) => void;
	updateTimerRunState: (currentState: TimerRunningState) => void;
	toggleDrawer: (content?: any) => void;
	updateDhyanOnHome: (enabled: boolean) => void;
}
const Component = (props: Props) => {
	let rootRef = useRef<HTMLElement>();
	let { timerState, durationsByDate, showDhyanOnHome, enabled, toggleFullScreen, stopTimer, updateTimerRunState, toggleDrawer, updateDhyanOnHome } = props;

	let [isLarge, setLarge] = useState(true);
	let [timerLogsVisible, setTimerLogsVisible] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			if (rootRef.current) {
				let ch = rootRef.current.clientHeight;
				setLarge(ch > 825);
			}
		}, 100);
	}, [enabled]);

	let [tempCompleted, setTempCompleted] = useState(-1);
	let [tempDate, setTempDate] = useState('');
	let [isCustomMode, setIsCustomMode] = useState(false);

	if (timerLogsVisible) {
		return (
			<DialogPage
				title={'Dhyan Logs'}
				dark
				goBackOnClose={false}
				onClose={() => {
					setTimerLogsVisible(false);
				}}
			>
				<TimerLogsList />
			</DialogPage>
		);
	}

	const optionsObj = () => {
		return {
			options: [
				{
					title: showDhyanOnHome ? 'Hide from Home' : 'Show on Home',
					icon: showDhyanOnHome ? Icons.BookmarkRemove : Icons.BookmarkAdd,
					onClick: () => {
						updateDhyanOnHome(!showDhyanOnHome);
						toggleDrawer();
					},
				},
				{
					title: 'Update Data Manually',
					icon: Icons.Edit,
					onClick: () => {
						setIsCustomMode(false);
						setTempDate('');
						setTempCompleted(-1);

						setTimerLogsVisible(true);
						toggleDrawer();
					},
				},
			],
			actionHandler: () => {
				toggleDrawer();
			},
		};
	};

	const onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		toggleDrawer(BottomDrawerOptions(optionsObj()));
	};

	return (
		<Box ref={rootRef} sx={classes.root(props)}>
			<Box sx={classes.title}>
				<FullScreenPlayerTitle
					title={'Dhyan'}
					onMinimize={(e) => {
						if (timerState?.currentState === TimerRunningState.Running) {
							updateTimerRunState(TimerRunningState.Paused);
							App.pauseCountDownTimer();
						}
						toggleFullScreen(e);
					}}
					showMin={timerState?.currentState === TimerRunningState.Running || timerState?.currentState === TimerRunningState.Paused}
					onClose={(e) => {
						if (timerState?.currentState === TimerRunningState.Running || timerState?.currentState === TimerRunningState.Paused) {
							updateTimerRunState(TimerRunningState.Stopped);
							App.cancelCountDownTimer();
						}

						stopTimer(e);
					}}
				/>
			</Box>
			{timerState && timerState.currentState !== TimerRunningState.Stopped && timerState.type === TimerType.Dhyan ? (
				<Box sx={classes.progressWrap} onClick={() => {}}>
					<Box sx={{ flexGrow: 1 }}></Box>
					<Paper sx={classes.paper}>
						<TimerRunningControl sx={{}} />
					</Paper>
					<Box sx={{ flexGrow: 1 }}></Box>
				</Box>
			) : (
				<Box sx={classes.progressWrap} onClick={() => {}}>
					<Card sx={{ ...classes.paper, p: 0, flex: '1 0 auto', position: 'relative', minHeight: 240 }}>
						<CardHeader
							action={
								<IconButton
									onClick={(e) => {
										onMoreIconClick(e);
										// Show on Home
										// Sound on/off
										// Manual Data Update
										// Share?
									}}
								>
									{Icons.MoreVert}
								</IconButton>
							}
						></CardHeader>
						<TimerProgress
							customDate={tempDate}
							customDuration={tempCompleted}
							size={'large'}
							sx={{
								textAlign: 'center',
								position: 'absolute',
								top: 'calc(50% - 110px)',
								left: 'calc(50% - 99px)',
							}}
						/>
					</Card>

					<Paper sx={classes.paper}>
						<TimerAnalytics
							onDateClick={(date: string) => {
								setTempDate(date);
								let tempDuration = date.length > 0 ? durationsByDate[date] : -1;
								setTempCompleted(tempDuration);
								setIsCustomMode(date.length > 0);
							}}
							isCustomMode={isCustomMode}
							sx={{ flexGrow: 1 }}
						/>
					</Paper>

					<Paper sx={classes.paper}>
						<TimerPresetWidget sx={isLarge ? { flexWrap: 'wrap', height: 265, justifyContent: 'center' } : {}} />
					</Paper>
				</Box>
			)}

			{/* <Paper
				sx={{ ...classes.controls, ...classes.paper, bottom: hiddenControls ? '-220px' : 0, boxShadow: '0 -1px 5px lightgrey', height: 100 }}
			></Paper> */}
		</Box>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let timerState = state.tempState.timerState;
	let durationsByDate = getTimerDurationByDate(state);

	return {
		timerState,
		durationsByDate,
		timerMusic: state.uxState.timerMusic ?? false,
		showDhyanOnHome: state.uxState.showDhyanOnHome ?? true,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateTimerRunState: (currentState: TimerRunningState) => {
			dispatch(updateTimerRunState(currentState));
		},
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		updateDhyanOnHome: (enabled: boolean) => {
			dispatch(updateDhyanOnHome(enabled));
		},
	};
}

export const FullScreenTimerControl = connect(mapStateToProps, mapDispatchToProps)(Component);
