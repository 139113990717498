import { Box, IconButton, Typography } from '@mui/material';
import App from 'AppInterface/App';
import { MediaPlayer } from 'components/player/MediaPlayer';
import { Icons } from 'config/icons';
import { getFormattedTimeFromSec } from 'helpers';
import { connect } from 'react-redux';
import { updateTimerRunState, updateTimerState } from 'store/temp/actions';
import { updateTimerMusic } from 'store/ux/actions';
import { ApplicationState, TimerRunningState, TimerRunType, TimerState, TimerType } from 'types';
import { timerThemeColor } from './config';

const classes = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		// padding: '8px 0 0 8px',
	},
};

interface Props {
	timerState?: TimerState;
	timerMusic: boolean;
	miniMode: boolean;
	updateTimerState: (timerState: TimerState) => void;
	updateTimerRunState: (currentState: TimerRunningState) => void;
	enableTimerMusic: (enabled: boolean) => void;
}

const Component = (props: Props) => {
	let { timerState, timerMusic, miniMode } = props;
	let { updateTimerState, updateTimerRunState, enableTimerMusic } = props;
	let isCountDown = timerState?.runType === TimerRunType.CountDown;

	if (!timerState) {
		return <></>;
	}

	return (
		<Box sx={classes.root}>
			<Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<Typography sx={{ color: timerThemeColor }} variant="h4">
					{isCountDown && !timerState.remainingTime
						? 'Done'
						: getFormattedTimeFromSec(
								isCountDown ? timerState.remainingTime : timerState!.elapsedTime + timerState.duration - timerState.remainingTime
						  )}
				</Typography>
				{isCountDown && (
					<Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
						{getFormattedTimeFromSec(timerState.duration + timerState.elapsedTime, true)}
					</Typography>
				)}
			</Box>
			<IconButton
				sx={{ padding: 0.5, color: timerMusic ? 'gray' : 'darkgrey' }}
				onClick={(e) => {
					e.stopPropagation();

					let enabled = !timerMusic;
					enableTimerMusic(enabled);
					App.enableTimerMusic(enabled);
				}}
			>
				{timerMusic ? <Icons.VolumeUpIcon sx={{ fontSize: '4.5rem' }} /> : <Icons.VolumeOffIcon sx={{ fontSize: '4.5rem' }} />}
			</IconButton>

			<IconButton
				sx={{ color: timerThemeColor, padding: 0.5 }}
				onClick={(e) => {
					e.stopPropagation();

					let duration = timerState!.elapsedTime + timerState!.duration;
					if (timerState!.currentState === TimerRunningState.Running) {
						updateTimerRunState(TimerRunningState.Paused);
						App.pauseCountDownTimer();
					} else if (timerState!.currentState === TimerRunningState.Completed) {
						let st = new Date().getTime();
						updateTimerState({
							currentState: TimerRunningState.Running,
							startTime: st,
							duration: duration,
							remainingTime: duration,
							elapsedTime: 0,
							music: false,
							type: TimerType.Dhyan,
							runType: timerState!.runType,
						});

						App.startCountDownTimer(st.toString(), duration, timerState!.runType === TimerRunType.CountUp, timerMusic);
					} else if (timerState!.currentState === TimerRunningState.Paused) {
						let st = new Date().getTime();
						let elapsed = duration - timerState!.remainingTime;
						updateTimerState({
							currentState: TimerRunningState.Running,
							startTime: st,
							duration: timerState!.remainingTime,
							remainingTime: timerState!.remainingTime,
							elapsedTime: elapsed,
							music: false,
							type: TimerType.Dhyan,
							runType: timerState!.runType,
						});

						App.resumeCountDownTimer(st.toString(), timerState!.remainingTime, elapsed, timerMusic);

						// updateTimerRunState(TimerRunningState.Running);
						// App.startCountDownTimer(timerState!.remainingTime);
					}
				}}
			>
				{timerState.currentState === TimerRunningState.Running ? (
					<Icons.PauseCircleFilled sx={{ fontSize: '4.5rem' }} />
				) : timerState.currentState === TimerRunningState.Completed ? (
					<Icons.ReplayCircleFilledIcon sx={{ fontSize: '4.5rem' }} />
				) : (
					<Icons.PlayCircleFilled sx={{ fontSize: '4.5rem' }} />
				)}
			</IconButton>

			<IconButton
				sx={{ padding: 0.5, color: 'gray' }}
				onClick={(e) => {
					e.stopPropagation();

					updateTimerRunState(TimerRunningState.Stopped);
					App.cancelCountDownTimer();

					if (miniMode) {
						MediaPlayer.instance().hideTimerView();
					}
				}}
			>
				<Icons.CancelIcon sx={{ fontSize: '4.5rem' }} />
			</IconButton>
		</Box>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let timerState = state.tempState.timerState;

	return {
		timerState,
		timerMusic: state.uxState.timerMusic ?? false,
		miniMode: state.tempState.audioPlaybackState.isMiniPlayerMode,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateTimerState: (timerState: TimerState) => {
			MediaPlayer.instance().stopMusicPlayer();
			dispatch(updateTimerState(timerState));
		},
		updateTimerRunState: (currentState: TimerRunningState) => {
			dispatch(updateTimerRunState(currentState));
		},
		enableTimerMusic: (enabled: boolean) => {
			dispatch(updateTimerMusic(enabled));
		},
	};
}

export const TimerRunningControl = connect(mapStateToProps, mapDispatchToProps)(Component);
