import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

interface CountDownProps {
	children: (color: string) => any;
	remainingTime: number;
	duration: number;
	colors: string[];
	colorsTime: number[];
	size?: number;
}

const Component = (props: CircularProgressProps & CountDownProps) => {
	let color = props.colors[0];
	for (let i in props.colorsTime) {
		if (props.remainingTime > props.colorsTime[i]) {
			break;
		}
		color = props.colors[i];
	}

	return (
		<>
			<Box sx={{ position: 'relative', display: 'inline-flex' }}>
				<CircularProgress
					variant="determinate"
					sx={{
						color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
					}}
					size={props.size}
					value={100}
					thickness={2}
				/>
				<CircularProgress
					value={((props.duration - props.remainingTime) * 100.0) / props.duration}
					variant="determinate"
					sx={{ color: color, position: 'absolute', left: 0 }}
					size={props.size}
					thickness={2}
				/>
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{props.children(color)}
				</Box>
			</Box>
		</>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export const CountDownTimer = connect(mapStateToProps, mapDispatchToProps)(Component);
