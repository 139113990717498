import { AppBar, Box, Button, IconButton, MobileStepper, Toolbar, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import App from 'AppInterface/App';
import DialogPage, { DialogContext } from 'components/pages/DialogPage';
import TextContent from 'components/TextContent';
import { MainRhyme4 } from 'config';
import { Icons } from 'config/icons';
import domtoimage from 'dom-to-image';
import { getContentStoragePath, getContentStorageUrl, getMediaBasePath, getWebAppAssetPath, isAdmin, timeout, toDataUrl } from 'helpers';
import moment, { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getQuotesByDate } from 'store/data/quotes/selectors';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, ContentType, Quote } from 'types';
import { app_icon_data, bottom_data, qr_code_data, top_data, TRANSPARENT_PIXEL } from './data_uris';
// import QRCodeStyling from 'qr-code-styling';

const classes = {
	quote: {
		textAlign: 'center' as 'center',
		textTransform: 'none' as 'none',
		fontWeight: 'normal' as 'normal',
		lineHeight: 1.7,
		padding: '1px',
	},
	borderImg: {
		height: '3.5rem',
	},
	quoteDiv: (theme: Theme) => ({
		padding: theme.spacing(2),
		backgroundColor: 'white',
		textAlign: 'center',
	}),
	pickerbar: {
		width: '100%',
		marginRight: '-12px',
		textAlign: 'center' as 'center',
	},
	picker: {
		transform: 'translateY(6px)',
		minWidth: 200,

		'& .MuiInput-underline::after': {
			transform: 'scaleX(0) !important',
			borderWidth: 1,
		},
		'& input': {
			textAlign: 'center' as 'center',
			fontSize: '0.9em',
		},
		'& .MuiIconButton-root': {
			visibility: 'visible',
		},
	},
	plainAppBar: (theme: Theme) => ({
		borderTop: `8px solid ${theme.palette.primary.main}`,
		borderBottom: `1px solid lightgrey`,
		backgroundColor: 'white',
		// boxShadow: 'none',
		color: 'inherit',

		'& .MuiIconButton-root': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
	}),
	stepper: (theme: Theme) => ({
		fontSize: theme.typography.body2.fontSize,
		maxWidth: 400,
		flexGrow: 1,
		background: 'white',
		margin: 'auto',
	}),
};

let minQuoteDate = moment('2017-05-26');

interface QuoteProps {
	quotes: { [date: string]: Quote };
	quote?: Quote;
	locale: string;
	toggleDrawer: (content?: any, open?: boolean, styles?: string) => void;
	isModal: boolean;
	showAll: boolean;
	appBuildCode: number;
	appEnv: string;
	forShare?: boolean;
	onImgComplete?: (dataUrl: any) => void;
}

interface QuoteContentProps {
	locale: string;
	hideTopFloral?: boolean;
	text: string;
}

// const qrCode = new QRCodeStyling({
// 	width: 300,
// 	height: 300,
// 	data: 'https://ssdn.app/shri-anandpur-satsang',
// 	image: getContentStorageUrl(ContentType.PKP, 'webapp/app_icon.png'),
// 	dotsOptions: {
// 		color: '#038b00', // '#4267b2',
// 		type: 'square',
// 	},
// 	imageOptions: {
// 		crossOrigin: 'anonymous',
// 		// imageSize: 0.3,
// 		margin: 20,
// 	},
// 	qrOptions: {
// 		errorCorrectionLevel: 'H',
// 	},
// });

class Content extends React.Component<QuoteContentProps> {
	render() {
		let { locale, text, hideTopFloral } = this.props;

		return (
			<Box sx={classes.quoteDiv}>
				{!hideTopFloral && <Box component="img" sx={classes.borderImg} src={top_data} />}
				<TextContent locale={locale} alignRhymes={true} useFontSize={true}>
					<Box sx={classes.quote} dangerouslySetInnerHTML={{ __html: text }}></Box>
				</TextContent>
				<Box component="img" sx={classes.borderImg} src={bottom_data} />
			</Box>
		);
	}
}

export const QuoteContent = Content;

class Component extends React.Component<QuoteProps, { date: Moment; forShare: boolean }> {
	private quoteRef = React.createRef<any>();

	constructor(props) {
		super(props);

		this.state = {
			date: this.props.quote ? moment(this.props.quote.forDate) : moment(),
			forShare: this.props.forShare ?? false,
		};
	}

	onShare = async () => {
		try {
			await timeout(500);
			let quoteEl = this.quoteRef.current as HTMLElement;
			if (quoteEl) {
				const scale = 2;

				const style = {
					transform: 'scale(' + scale + ')',
					transformOrigin: 'top left',
					width: quoteEl.offsetWidth + 'px',
					height: quoteEl.offsetHeight + 'px',
				};

				const param = {
					height: quoteEl.offsetHeight * scale,
					width: quoteEl.offsetWidth * scale,
					style,
					imagePlaceholder: TRANSPARENT_PIXEL,
				};

				let dataUrl = await domtoimage.toPng(quoteEl, param);

				return dataUrl;
			}
		} catch (err) {
			console.error(err);
		}
	};

	componentDidMount = async () => {
		// console.log('app_icon', await toDataUrl(getContentStorageUrl(ContentType.PKP, 'webapp/app_icon_200.png')));
		// console.log('qr_code', await toDataUrl(getContentStorageUrl(ContentType.PKP, 'webapp/qr_ssdn_app.jpeg')));
		// console.log('top', await toDataUrl(getWebAppAssetPath('bg/dark/heading-bg-top.png')));
		// console.log('bottom', await toDataUrl(getWebAppAssetPath('bg/dark/heading-bg-bottom.png')));

		if (this.state.forShare) {
			let dataUrl = await this.onShare();

			if (this.props.onImgComplete) {
				this.props.onImgComplete(dataUrl);
			}
		}
	};

	render() {
		let { quotes, locale, isModal, showAll, appBuildCode } = this.props;
		let quote = this.props.quote ?? quotes[this.state.date.format('YYYY-MM-DD')];

		let text;
		if (quote) {
			text = quote.text[locale] || quote.text.hi || quote.text.en;
		} else {
			text = MainRhyme4;
		}

		let quoteContent = <QuoteContent locale={locale} text={text} hideTopFloral={quote?.hideTopFloral ?? false} />;

		let maxDate = new Date();
		if (showAll) {
			maxDate = new Date(Object.keys(quotes).sort((a, b) => (a < b ? 1 : -1))[0]);
		}
		maxDate = maxDate >= new Date() ? maxDate : new Date();

		let picker = (
			<Box sx={classes.pickerbar}>
				<DatePicker
					key={this.state.date.format('YYYY-MM-DD')}
					sx={classes.picker}
					minDate={minQuoteDate}
					maxDate={moment(maxDate)}
					value={this.state.date}
					openTo="year"
					format="ddd, Do MMM, YYYY"
					views={['year', 'month', 'day']}
					// disableOpenPicker={true}
					slotProps={{ textField: { variant: 'standard' } }}
					shouldDisableDate={(date: Moment) => {
						return !quotes[date.format('YYYY-MM-DD')];
					}}
					onAccept={(date: Moment | null) => {
						if (date) {
							this.setState({
								date: date,
							});
						}
					}}
				/>

				<IconButton
					style={{
						float: 'right',
						visibility: appBuildCode > 45 ? 'visible' : 'hidden',
					}}
					onClick={async () => {
						let shareLink = quote[`${locale}ShareLink`];
						let dataUrl;
						if (shareLink) {
							dataUrl = await toDataUrl(getContentStorageUrl(ContentType.Quote, shareLink));
						} else {
							await new Promise<void>((resolve) => {
								this.setState({ forShare: true }, () => {
									resolve();
								});
							});

							dataUrl = await this.onShare();

							await new Promise<void>((resolve) => {
								this.setState({ forShare: false }, () => {
									resolve();
								});
							});
						}

						if (dataUrl) {
							App.shareImageDataURI(
								'Shri Anandpur Satsang, Quote - ' + this.state.date.format('dddd, Do MMM, YYYY'),
								dataUrl,
								'https://www.shrianandpur.org'
							);
						}
					}}
					size="large"
				>
					{Icons.Share}
				</IconButton>
			</Box>
		);

		let currentIndex = this.state.date.diff(minQuoteDate, 'days');
		let totalDates = moment(maxDate).diff(minQuoteDate, 'days') + 1;

		return (
			<DialogPage
				appBarSx={classes.plainAppBar}
				extraAppBarElement={picker}
				showAppBar={!this.state.forShare}
				title=""
				transition={true}
				drawerType={true}
				goBackOnClose={isModal}
				goBackTo="/"
				style={{ paddingBottom: 0 }}
				disableBackdropClick={true}
				actionButtons={
					!this.state.forShare && (
						<MobileStepper
							variant="text"
							steps={totalDates}
							position="static"
							activeStep={currentIndex}
							sx={classes.stepper}
							nextButton={
								<Button
									size="small"
									onClick={async () => {
										this.setState({ date: this.state.date.add(1, 'day') });
									}}
									disabled={currentIndex >= totalDates - 1}
									color="inherit"
								>
									Next
									{Icons.KeyRightArrow}
								</Button>
							}
							backButton={
								<Button
									size="small"
									onClick={async () => {
										this.setState({ date: this.state.date.subtract(1, 'day') });
									}}
									disabled={currentIndex <= 0}
									color="inherit"
								>
									{Icons.KeyLeftArrow}
									Back
								</Button>
							}
						/>
					)
				}
			>
				<Box ref={this.quoteRef} sx={this.state.forShare ? { minWidth: 480 } : {}}>
					{this.state.forShare && (
						<AppBar elevation={4} sx={classes.plainAppBar} style={{ position: 'relative', display: 'flex', flexDirection: 'row', height: 88 }}>
							<img style={{ width: 80, height: 80 }} src={app_icon_data} />
							<Toolbar style={{ alignSelf: 'center', flexGrow: 1, justifyContent: 'center', textAlign: 'center' }}>
								<Box>
									<Typography variant="h6" noWrap>
										Shri Anandpur Satsang
									</Typography>
									<Typography variant="body2" noWrap>
										{this.state.date.format('ddd, Do MMM, YYYY')}
									</Typography>
								</Box>
							</Toolbar>
							<img style={{ width: 80, height: 80, padding: 4 }} src={qr_code_data} />
						</AppBar>
					)}
					{quoteContent}
					{this.state.forShare && (
						<AppBar
							elevation={4}
							sx={classes.plainAppBar}
							style={{
								position: 'relative',
								transform: 'scaleY(-1)',
								boxShadow: 'none',
							}}
						>
							<Toolbar style={{ transform: 'scaleY(-1)', display: 'flex', flexDirection: 'row', padding: 0 }}>
								<Box sx={{ margin: 0.5, textAlign: 'center', flexGrow: 1 }}>
									<Typography variant="h6" noWrap className={'locale-' + locale}>
										{locale === 'hi' ? 'जय सच्चिदानन्द जी' : 'Jai Sacchidanand Ji'}
									</Typography>
									<Typography variant="subtitle2" noWrap>
										App available for Android and iOS
									</Typography>
								</Box>
							</Toolbar>
						</AppBar>
					)}
				</Box>
			</DialogPage>
		);
	}
}

function mapStateToProps(state: ApplicationState, props: { id?: string; isModal: boolean; quote?: Quote; lang?: string }) {
	let quotes = getQuotesByDate(state);
	let showAll =
		isAdmin(state.userState.userStore.user, state.userState.userStore.userData) &&
		state.userState.userStore.userData?.settings &&
		state.userState.userStore.userData?.settings['admin.records'];

	let quote = props.quote ?? (props.id ? state.dataState.quotes.byId[props.id] : undefined);

	return {
		quotes,
		quote,
		locale: props.lang ?? state.uxState.locale,
		showAll,
		appBuildCode: state.uxState.appParams.appBuildCode,
		appEnv: state.uxState.appParams.appEnv,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any, open?: boolean, styles?: string) => {
			dispatch(toggleBottomDrawer(content, open, styles));
		},
	};
}

Component.contextType = DialogContext;

export const QuoteControl = connect(mapStateToProps, mapDispatchToProps)(Component);
