import { push } from '@lagunovsky/redux-react-router';
import App from 'AppInterface/App';
import { DidDeleteMedia, DidDownloadMedia, DidUpdateDownloadProgress } from 'AppInterface/AppInterface';
import { MediaPlayer } from 'components/player/MediaPlayer';
import { logEvent } from 'components/telemetry';
import { ArticleTypeMap, DBConfig, TimerAudio } from 'config';
import { phoneSigninRoute } from 'config/route';
import { User } from 'firebase/auth';
import { getAudioFilenameFromSrc, getContentStorageUrl, getOpsKey, isTimerEnabled, isWebViewEnv, updatePushSetting } from 'helpers';
import { Context } from 'helpers/context';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { concatMap, debounceTime, switchMap } from 'rxjs/operators';
import { saveExtraUserData, updateProfile } from 'services/api/auth';
import { modifyPlaylistTracks, PlaylistsFireStoreModule, ReorderPlaylist, reorderPlaylistNow, REORDER_PLAYLIST } from 'services/api/playlists';
import { multiAction, performOperationAtInterval, performOperationOnceInSession, updateOperation } from 'store/data/ops/actions';
import { updateTimerLog } from 'store/data/timer/actions';
import { UpdateUser, UpdateUserData, updateUserSubColl, UPDATE_USER, UPDATE_USER_DATA, UPDATE_USER_SUB_COLL } from 'store/data/user/actions';
import {
	addOfflineAudio,
	AddOfflineAudio,
	ADD_OFFLINE_AUDIO,
	removeOfflineAudio,
	RemoveOfflineAudio,
	REMOVE_OFFLINE_AUDIO,
	updateDownloadState,
} from 'store/offline/actions';
import {
	EventType,
	onEvent,
	UpdateAudioPlaybackState,
	UpdateAudioPlaybackTime,
	UpdateTimerRunState,
	updateTimerRunState,
	updateTimerState,
	UpdateTimerTime,
	updateTimerTime,
	UPDATE_AUDIO_PLAYBACK_STATE,
	UPDATE_AUDIO_PLAYBACK_TIME,
	UPDATE_TIMER_RUN_STATE,
	UPDATE_TIMER_TIME,
} from 'store/temp/actions';
import { UpdateUserSettings, UPDATE_USER_SETTINGS } from 'store/ux/actions';
import { ApplicationState, AudioCurrentState, ContentType, DownloadStatus, TimerRunningState, TimerRunType, TimerType } from 'types';

const settingsEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER_SETTINGS),
		debounceTime(2000),
		switchMap((action: UpdateUserSettings) => {
			if (action.fromProfile === true) {
				return EMPTY;
			}

			let user = (state$.value as ApplicationState).userState.userStore.user;
			let settings = (state$.value as ApplicationState).uxState.settings;

			updatePushSetting(settings.push);

			if (user) {
				return of(updateProfile({ settings }, new Context()));
			} else {
				return EMPTY;
			}
		})
	);

const playlistEpic = (action$, state$) =>
	action$.pipe(
		ofType(REORDER_PLAYLIST),
		debounceTime(2000),
		switchMap((action: ReorderPlaylist) => {
			let user = (state$.value as ApplicationState).userState.userStore.user;
			if (user) {
				return of(reorderPlaylistNow(action.data));
			} else {
				return EMPTY;
			}
		})
	);

const offlineAudioEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER_SUB_COLL, UPDATE_USER_SETTINGS),
		debounceTime(2000),
		switchMap((action: any) => {
			let state = state$.value as ApplicationState;
			let user = state.userState.userStore.user;
			if (user) {
				let playlists = state.userState.userStore?.subColl?.playlists;
				let offlinePlaylist: any = playlists ? playlists['0'] ?? {} : {};
				if (offlinePlaylist.deleted) {
					offlinePlaylist = {};
				}

				let tracks = offlinePlaylist.tracks || {};

				let offlineAudio = state.offlineState.audio;
				let articles = state.dataState.articles.byId;
				let configs = state.dataState.configs.byId;
				let autoDownloadIds: string[] = (configs[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];

				let audioToDownload: any[] = [];
				let audioToDelete: any[] = [];

				let settings = state.uxState.settings;

				if (settings?.download) {
					for (let id in tracks) {
						if (articles[id] && !offlineAudio[id] && autoDownloadIds.indexOf(id) < 0) {
							// if (audioToDownload.length === 0) {
							audioToDownload.push(of(addOfflineAudio(articles[id], false)));
							console.log('Adding offline audio: ', id);
							// } else {
							// audioToDownload.push(
							// of(addOfflineAudio(articles[id], false)).pipe(
							// delayWhen(() => action$.ofType(DidDownloadMedia))
							// )
							// );
							// }
						}
					}

					// Following will cause offline audio to sync of someone signout and signsin with different accounts
					for (let id in offlineAudio) {
						if (offlineAudio[id].progress?.status === DownloadStatus.Complete) {
							if (!tracks[id] && autoDownloadIds.indexOf(id) < 0) {
								audioToDelete.push(of(removeOfflineAudio(id, true)));
								console.log('Removing offline audio: ', id);
							}
						}
					}
				}

				if (audioToDownload.length || audioToDelete.length) {
					console.log('Going to sync Offline Audio');
					return concat(
						of(
							onEvent(new Context(), EventType.Information, 'Offline', {
								message: `Syncing offline audio...`,
								success: true,
							})
						),
						...audioToDownload,
						...audioToDelete
					);
				}
			}
			return EMPTY;
		})
	);

const offlineAudioDownloadEpic = (action$, state$) =>
	action$.pipe(
		ofType(ADD_OFFLINE_AUDIO),
		concatMap((action: AddOfflineAudio) => {
			let article = action.article;
			if (!article) {
				return EMPTY;
			}

			if (action.forExport === true) {
				logEvent('exportMedia', { id: article.id, title: article.title.en, uri: article.mediaUri });
				App.exportMedia(
					article.id,
					article.title.en + '.' + article.mediaUri!.split('.')[1],
					getContentStorageUrl(ContentType.Article, article.articleType, article)
				);
			} else {
				// App.deleteMedia(article.id, getAudioFilenameFromSrc(article.mediaUri!));

				// setTimeout(() => {
				logEvent('downloadMedia', { id: article.id, title: article.title.en, uri: article.mediaUri });
				App.downloadMedia(
					article.id,
					getAudioFilenameFromSrc(article.mediaUri!),
					getContentStorageUrl(ContentType.Article, article.articleType, article),
					true
				);
				// }, 1000);
			}

			// if (!isWebViewEnv()) {
			// 	return EMPTY;
			// }

			return EMPTY;
		})
	);

const offlineAudioDeleteEpic = (action$, state$) =>
	action$.pipe(
		ofType(REMOVE_OFFLINE_AUDIO),
		concatMap((action: RemoveOfflineAudio) => {
			// if (!isWebViewEnv()) {
			// 	return EMPTY;
			// }

			let state = state$.value as ApplicationState;
			let articles = state.dataState.articles.byId;

			let article = articles[action.articleId];
			App.deleteMedia(action.articleId, getAudioFilenameFromSrc(article.mediaUri!));

			let configs = state.dataState.configs.byId;
			let autoDownloadIds: string[] = (configs[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];

			return autoDownloadIds.indexOf(action.articleId) < 0 && action.localOnly === false
				? of(modifyPlaylistTracks({ playlistId: '0', articleId: action.articleId, action: 'Remove' }))
				: EMPTY;
		})
	);

const downloadRetries = {};

const offlineAudioProgressEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateDownloadProgress, DidDeleteMedia, DidDownloadMedia),
		concatMap((action: { type: string; payload: any }) => {
			let state = state$.value as ApplicationState;
			let configs = state.dataState.configs.byId;
			let autoDownloadIds: string[] = (configs[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];

			switch (action.type) {
				case DidUpdateDownloadProgress:
					let curState = state.offlineState.audio[action.payload.audioId];

					let curProgress;
					if (curState?.forExport === true) {
						curProgress = curState?.exportProgress?.value;
					} else {
						curProgress = curState?.progress?.value;
					}

					if (Math.floor(curProgress) + 10 <= Math.floor(action.payload.progress)) {
						return of(
							updateDownloadState(action.payload.audioId, {
								status: DownloadStatus.InProgress,
								value: action.payload.progress,
								bytes: action.payload.totalBytesWritten,
								updatedAt: new Date(),
							})
						);
					} else {
						return EMPTY;
					}
				case DidDownloadMedia:
					let crState = state.offlineState.audio[action.payload.audioId];
					let progressBytes = state.offlineState.audio[action.payload.audioId]?.progress?.bytes;

					if (crState?.forExport === true) {
						return of(
							updateDownloadState(action.payload.audioId, {
								status: DownloadStatus.Complete,
								value: 100,
								bytes: progressBytes ?? 0,
								updatedAt: new Date(),
							})
						);
					}
					if (action.payload.result === false) {
						let articleId = action.payload.audioId;
						if (!downloadRetries[articleId]) {
							let articles = state.dataState.articles.byId;
							downloadRetries[articleId] = true;
							return concat(of(addOfflineAudio(articles[articleId], false)));
						} else {
							return EMPTY;
							// of(
							// 	onEvent(new Context(), EventType.Information, 'Offline', {
							// 		message: 'Download failed! Please retry...',
							// 		success: false,
							// 	})
							// )
						}
					}

					if (progressBytes && progressBytes < 10000) {
						return of(removeOfflineAudio(action.payload.audioId, true));
					}

					let playlists = state.userState.userStore?.subColl?.playlists;
					let offlinePlaylist: any = playlists ? playlists['0'] ?? {} : {};
					if (offlinePlaylist.deleted) {
						offlinePlaylist = {};
					}

					let tracks = offlinePlaylist.tracks || {};
					let trackIds = Object.keys(tracks);

					return concat(
						// of(
						// 	onEvent(new Context(), EventType.Information, 'Offline', {
						// 		message: 'Download Completed...',
						// 		success: true,
						// 	})
						// ),
						of(
							updateDownloadState(action.payload.audioId, {
								status: DownloadStatus.Complete,
								value: 100,
								bytes: progressBytes ?? 0,
								updatedAt: new Date(),
							})
						),
						autoDownloadIds.indexOf(action.payload.audioId) < 0 && trackIds.indexOf(action.payload.audioId) < 0
							? of(
									modifyPlaylistTracks({
										playlistId: '0',
										articleId: action.payload.audioId,
										action: 'Add',
									})
							  )
							: EMPTY
					);
				case DidDeleteMedia:
				// return of(modifyPlaylistTracks({ playlistId: '0', articleId: action.payload, action: 'Remove' }));
				// return of(
				// 	onEvent(new Context(), EventType.Information, 'Deleted', {
				// 		message: 'Audio file has been deleted',
				// 		success: true,
				// 	})
				// );
			}

			return EMPTY;
		})
	);

const userEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER),
		debounceTime(5000),
		switchMap((action: UpdateUser) => {
			let state = state$.value as ApplicationState;
			let user: User | undefined = action.user;

			let signinSkipped = true || parseInt(localStorage.getItem('SkippedSignIn') ?? '0') >= new Date().getTime() - 24 * 60 * 60 * 1000;

			if (isWebViewEnv() && !signinSkipped) {
				if (!user) {
					let phoneRouteAction = multiAction([push('/'), push(phoneSigninRoute.to, phoneSigninRoute.state)]);
					return of(
						performOperationOnceInSession('usersignin', phoneRouteAction, false, () => {
							return !state.userState.userStore.user;
						})
					);
				}

				// if (!user?.phoneNumber) {
				// 	let phoneRouteAction = multiAction([push('/'), push(phoneSigninRoute.to, phoneSigninRoute.state)]);
				// 	return of(
				// 		performOperationOnceInSession('phonesignin', phoneRouteAction, false, () => {
				// 			return !(state$.value as ApplicationState).userState.userStore.user;
				// 		})
				// 	);
				// }
			}

			let userData = state.userState.userStore.userData;

			// if (userData && (!userData.fullName || !userData.fullName.length)) {
			// 	let profileRouteAction = multiAction([/*push('/'), */ push('/account/profile', { isModal: true })]);
			// 	return of(
			// 		performOperationOnceInSession('profilefullname', profileRouteAction, false, () => {
			// 			let userData = (state$.value as ApplicationState).userState.userStore.userData;
			// 			return !userData || !userData.fullName || !userData.fullName.length;
			// 		})
			// 	).pipe(delay(60 * 60 * 1000));
			// }

			let result: any[] = [];
			if (user) {
				let interval = 24 * 60; // minutes
				result.push(of(performOperationAtInterval(`extrauserdata-${user.uid}`, interval * 60, saveExtraUserData(), true)));

				// If this is a new user then previous users playlists get removed by following action
				result.push(of(updateUserSubColl('playlists', user.uid, [])));

				let pfm = new PlaylistsFireStoreModule(user);
				let key = getOpsKey(pfm);
				let suffix = pfm.getOpsKeySuffix();

				let prefix = key.replace(suffix, '');

				let opsById = state.opsState.byId;
				for (let opsKey in opsById) {
					if (opsKey.indexOf(prefix) >= 0 && opsKey !== key) {
						result.push(of(updateOperation({ key: opsKey, updatedAt: new Date() } as any)));
					}
				}

				return concat(...[...result]);
			}

			return EMPTY;
		})
	);

const userDataUpdateEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER_DATA),
		debounceTime(5000),
		switchMap((action: UpdateUserData) => {
			let userData = action.userData;

			if (userData && userData.roles && userData.roles.indexOf('test') >= 0) {
				App.subscribeToTopic('test');
			}

			return EMPTY;
		})
	);

const offlineTimerProgressEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_TIMER_TIME, UPDATE_TIMER_RUN_STATE, UPDATE_AUDIO_PLAYBACK_TIME, UPDATE_AUDIO_PLAYBACK_STATE),
		concatMap((action: UpdateTimerTime | UpdateTimerRunState | UpdateAudioPlaybackTime | UpdateAudioPlaybackState) => {
			if (!isTimerEnabled()) {
				return EMPTY;
			}

			let state = state$.value as ApplicationState;
			let timerState = state.tempState.timerState;
			if (!timerState) {
				return EMPTY;
			}

			let audioDetails = state.tempState.audioPlaybackDetails;
			let playbackState = state.tempState.audioPlaybackState;
			let article = audioDetails?.articleList[audioDetails?.currentIndex];

			let timerAction: any = EMPTY;
			TimerAudio.pause();

			switch (action.type) {
				case UPDATE_TIMER_TIME:
					let storedElapsed = state.offlineState.timerLogs[timerState.startTime]?.duration ?? 0;
					let remTime = (action as UpdateTimerTime).remainingTime;
					let curElapsed = timerState.duration - remTime;

					if (storedElapsed + 10 <= curElapsed) {
						return of(
							updateTimerLog(
								{
									type: timerState.type,
									music: timerState.music,
									endTime: new Date().getTime(),
									duration: timerState.duration - remTime,
									updatedAt: new Date().getTime(),
								},
								timerState.startTime,
								false
							)
						);
					} else {
						return EMPTY;
					}
				case UPDATE_TIMER_RUN_STATE:
					let spentTime = timerState.duration - timerState.remainingTime;
					// if (spentTime < 10) {
					// 	return EMPTY;
					// }

					let act = of(
						updateTimerLog(
							{
								type: timerState.type,
								music: timerState.music,
								endTime: new Date().getTime(),
								duration: spentTime,
								updatedAt: new Date().getTime(),
							},
							timerState.startTime,
							true
						)
					);

					let curState = (action as UpdateTimerRunState).currentState;

					if (spentTime >= 15 * 60 && curState === TimerRunningState.Completed) {
						return concat(act, of(saveExtraUserData()));
					} else {
						return act;
					}
				case UPDATE_AUDIO_PLAYBACK_TIME:
					if (!article) {
						return EMPTY;
					}
					let currTime = (action as UpdateAudioPlaybackTime).currentTime;
					currTime = Math.round(currTime);

					if (article.group === ArticleTypeMap.dhyan) {
						if (timerState?.currentState === TimerRunningState.Running && timerState.type === TimerType.DhyanMusic) {
							let length = Math.floor(article.mediaLength);
							let startedPos = length - timerState.duration;
							let totalRemaining = length - currTime;
							if (totalRemaining !== timerState.remainingTime) {
								timerAction = of(updateTimerTime(totalRemaining));
							}
						} else {
							let totalRemaining = Math.floor(article.mediaLength - currTime);
							let updateStateAction = of(
								updateTimerState({
									type: TimerType.DhyanMusic,
									startTime: new Date().getTime(),
									currentState: TimerRunningState.Running,
									duration: totalRemaining,
									remainingTime: totalRemaining,
									elapsedTime: 0,
									music: true,
									runType: TimerRunType.CountDown,
								})
							);
							MediaPlayer.instance().hideTimerView();

							if (timerState?.currentState === TimerRunningState.Running) {
								App.cancelCountDownTimer();
								timerAction = concat(of(updateTimerRunState(TimerRunningState.Stopped)), updateStateAction);
							} else {
								timerAction = updateStateAction;
							}
						}
					} else {
						if (timerState?.currentState === TimerRunningState.Running) {
							App.cancelCountDownTimer();
							timerAction = of(updateTimerRunState(TimerRunningState.Stopped));
						}
					}
					return timerAction;
				case UPDATE_AUDIO_PLAYBACK_STATE:
					// if (!article) {
					// 	return EMPTY;
					// }

					let audioCurrentState = (action as UpdateAudioPlaybackState).currentState;

					if (
						timerState?.currentState === TimerRunningState.Running &&
						timerState?.type === TimerType.DhyanMusic
						// && article.group === ArticleTypeMap.dhyan
					) {
						let map = {
							[AudioCurrentState.Ended]: TimerRunningState.Completed,
							[AudioCurrentState.Playing]: TimerRunningState.Running,
							[AudioCurrentState.Paused]: TimerRunningState.Paused,
							[AudioCurrentState.Buffering]: TimerRunningState.Paused,
							[AudioCurrentState.Stopped]: TimerRunningState.Stopped,
							[AudioCurrentState.Seeking]: TimerRunningState.Paused,
							[AudioCurrentState.Initializing]: TimerRunningState.Stopped,
						};
						timerAction = of(updateTimerRunState(map[audioCurrentState]));
					}

					return timerAction;
			}

			return EMPTY;
		})
	);

export const userDataEpic = combineEpics(
	settingsEpic,
	playlistEpic,
	offlineAudioDownloadEpic,
	offlineAudioDeleteEpic,
	offlineAudioProgressEpic,
	offlineAudioEpic,
	userEpic,
	userDataUpdateEpic,
	offlineTimerProgressEpic
);
