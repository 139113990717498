import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { CustomDatePicker } from 'components/admin/configs/configHelpers';
import DialogPage from 'components/pages/DialogPage';
import { Icons } from 'config/icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { TimePicker } from 'formik-mui-x-date-pickers';
import { getFormattedTimeFromSec } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';
import { timerThemeColor } from './config';

const classes = {
	formControl: (theme) => ({
		margin: theme.spacing(1),
		minWidth: 120,
	}),
	root: {
		// padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',

		'& .MuiCardActions-root': {
			padding: '16px',
			borderTop: '1px solid darkgrey',
		},
	},
	formRoot: (theme) => ({
		// maxWidth: 800,
		width: '100%',
		marginTop: theme.spacing(2),
		marginLeft: 'auto',
		marginRight: 'auto',
	}),
	header: {},
	avatar: {
		color: '#fff',
		backgroundColor: timerThemeColor,
	},
	message: (theme) => ({
		marginBottom: theme.spacing(8),
	}),
	label: (theme) => ({
		color: theme.palette.grey[500],
		marginBottom: theme.spacing(-1) + 'px !important',
		transform: 'scale(0.75)',
		transformOrigin: 'top left',
	}),
	form: (isValid) => (theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > .MuiTextField-root': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},

		'& .MuiOutlinedInput-notchedOutline': isValid
			? {}
			: {
					borderColor: 'crimson',
			  },
	}),
	badge: {
		'& .MuiBadge-colorSecondary': {
			backgroundColor: 'darkgrey',
		},

		'& .MuiBadge-anchorOriginTopRightRectangular': {
			transform: 'scale(1) translate(-100%, 0%)',
		},
	},
	error: {
		color: 'crimson',
	},
};

interface Props {
	startTime?: number;
	duration?: number;
	actionText: string;
	action: (startTime: number, duration: number) => void;
	onClose?: () => void;
}

const getDateFromParts = (valueDatePart, valueTimePart) => {
	let timePart = moment.utc(0).add({
		hours: valueTimePart.hours(),
		minutes: valueTimePart.minutes(),
		seconds: valueTimePart.seconds(),
	});
	return new Date(timePart.valueOf() + valueDatePart.valueOf());
};

export function TimerLogForm(props: Props) {
	let [startTime, setStartTime] = React.useState(props.startTime || new Date().getTime());
	let [duration, setDuration] = React.useState(props.duration || 0);
	let { actionText, action } = props;

	let onClose = () => {
		setStartTime(0);
		setDuration(0);

		if (props.onClose) {
			props.onClose();
		}
	};

	let onAction = async () => {
		action(startTime, duration);
		onClose();
	};

	React.useEffect(() => {
		const listener = async (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				await onAction();
			}
		};

		document.addEventListener('keydown', listener);

		return () => {
			document.removeEventListener('keydown', listener);
		};
		// eslint-disable-next-line
	}, [startTime, duration]);

	let initValues: any = {};

	let start = moment(startTime);
	initValues.startTimePart = moment.utc(0).add({
		hours: start.hours(),
		minutes: start.minutes(),
		seconds: start.seconds(),
	});
	initValues.startDatePart = start.seconds(0).minutes(0).hours(0);

	let end = moment(startTime + duration * 1000);
	initValues.endTimePart = moment.utc(0).add({
		hours: end.hours(),
		minutes: end.minutes(),
		seconds: end.seconds(),
	});
	initValues.endDatePart = end.seconds(0).minutes(0).hours(0);

	const updateTimerState = (values) => {
		values.start = moment(getDateFromParts(values.startDatePart, values.startTimePart));
		values.end = moment(getDateFromParts(values.endDatePart, values.endTimePart));
	};

	return (
		<DialogPage
			onClose={onClose}
			disableEnforceFocus
			disableBackdropClick
			goBackOnClose={false}
			title={actionText + ' Log'}
			transition={false}
			dark
			scroll="paper"
			maxWidth="lg"
		>
			<Formik
				initialValues={initValues}
				validationSchema={() => {
					return Yup.object({
						startDatePart: Yup.date().required(),
						startTimePart: Yup.date().required(),
						endDatePart: Yup.date().required(),
						endTimePart: Yup.date()
							.required()
							.test('date-check', 'End Time should be after Start Time', function (val) {
								let startDatePart = this.parent.startDatePart;
								let startTimePart = this.parent.startTimePart;
								let endDatePart = this.parent.endDatePart;
								let endTimePart = this.parent.endTimePart;

								let startT = startDatePart.getTime() + startTimePart.getTime();
								let endT = endDatePart.getTime() + endTimePart.getTime();

								console.log(new Date(startT), new Date(endT), endT - startT);

								if (startT < endT) {
									return true;
								}

								return false;
							}),
					});
				}}
				onSubmit={async (values, { setSubmitting, setFieldValue }) => {
					values = { ...values };

					// await setRecord(collection, id, values);

					setSubmitting(false);

					let start = getDateFromParts(values.startDatePart, values.startTimePart).getTime();
					let end = getDateFromParts(values.endDatePart, values.endTimePart).getTime();
					let duration = Math.floor((end - start) / 1000);

					// console.log(new Date(start), new Date(end), duration);
					action(start, duration);

					onClose();
					// if (props.onClose) {
					// 	props.onClose();
					// 	// } else {
					// 	// 	navigate(-1);
					// }
				}}
			>
				{({ submitForm, isSubmitting, values, setFieldValue, isValid, errors }) => (
					<Box sx={classes.root}>
						<Card sx={classes.formRoot}>
							<CardHeader
								sx={classes.header}
								avatar={
									<Avatar sx={classes.avatar}>
										<Icons.TimerIcon />
									</Avatar>
								}
								title={
									<Typography variant="subtitle1" noWrap>
										{new AnandDate()
											.setDateObj(getDateFromParts(values.startDatePart, values.startTimePart))
											.format("0DD MMM'YY, hh:mm:ss A")}
									</Typography>
								}
								subheader={
									<Typography variant="body2" color="textSecondary" noWrap>
										{getFormattedTimeFromSec(
											(getDateFromParts(values.endDatePart, values.endTimePart).getTime() -
												getDateFromParts(values.startDatePart, values.startTimePart).getTime()) /
												1000,
											true
										)}
									</Typography>
								}
							/>
							<CardContent>
								<Box component={Form} sx={classes.form(isValid)} translate="yes">
									<div style={{ flexDirection: 'row' }}>
										<Field
											component={CustomDatePicker}
											format="DD/MM/YYYY"
											name="startDatePart"
											label="Start Time"
											autoOk
											sx={{ width: '49%' }}
										/>
										<Field
											component={TimePicker}
											views={['hours', 'minutes', 'seconds']}
											format="hh:mm:ss A"
											name="startTimePart"
											label=" "
											sx={{ width: '49%', float: 'right' }}
										/>
									</div>

									<div style={{ flexDirection: 'row' }}>
										<Field
											component={CustomDatePicker}
											format="DD/MM/YYYY"
											name="endDatePart"
											label="End Time"
											autoOk
											onChangeCommitted={() => updateTimerState(values)}
											sx={{ width: '49%' }}
										/>
										<Field
											component={TimePicker}
											views={['hours', 'minutes', 'seconds']}
											format="hh:mm:ss A"
											name="endTimePart"
											label=" "
											sx={{ width: '49%', float: 'right' }}
										/>
									</div>

									<ErrorMessage name={'endTimePart'}>{(msg) => <Box sx={classes.error}>{msg}</Box>}</ErrorMessage>
								</Box>
							</CardContent>
							<CardActions>
								<Button
									variant="contained"
									// size='small'
									color="primary"
									onClick={submitForm}
									style={{ marginLeft: 'auto' }}
									disabled={isSubmitting || !isValid}
								>
									{actionText}
								</Button>
							</CardActions>
						</Card>
					</Box>
				)}
			</Formik>
		</DialogPage>
	);
}
