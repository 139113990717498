import { Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import App from 'AppInterface/App';
import { AndroidPlayStoreUrl, AppleAppStoreUrl } from 'config';
import { isAndroid } from 'helpers';
import { useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import DialogPage from './pages/DialogPage';

interface Props {
	message: { [lang: string]: string };
	policy: string;
	locale: string;
}

enum Policy {
	Force = 'force',
	Easy = 'easy',
}

const Component = (props: Props) => {
	let { locale, message, policy } = props;

	let [open, setOpen] = useState(true);

	return (
		<DialogPage open={open} title="App Update" maxWidth="xs" dark goBackOnClose={true} goBackTo="/" onClose={() => policy !== Policy.Force}>
			<Card sx={{ m: 2 }}>
				<CardContent sx={{ m: 2, mb: 4 }}>
					<Typography component={'div'} className={`locale-${locale}`}>
						<div dangerouslySetInnerHTML={{ __html: locale === 'hi' ? message.hi : message.en }} />
					</Typography>
				</CardContent>
				<Divider sx={{ mx: 2 }} />
				<CardActions sx={{ display: 'flex', flexDirection: 'row' }}>
					{policy !== Policy.Force && (
						<Button
							sx={{ m: 1, width: 150 }}
							variant="outlined"
							onClick={() => {
								setOpen(false);
							}}
						>
							Skip
						</Button>
					)}
					<Button
						variant="contained"
						sx={{ m: 1, ml: 'auto !important', width: 150 }}
						onClick={() => {
							App.openUrlInBrowser(isAndroid() ? AndroidPlayStoreUrl : AppleAppStoreUrl);
						}}
					>
						Update App
					</Button>
				</CardActions>
			</Card>
		</DialogPage>
	);
};

function mapStateToProps(state: ApplicationState) {
	return {
		locale: state.uxState.locale,
	};
}

export const AppUpdateControl = connect(mapStateToProps)(Component);
