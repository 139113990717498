import Card from '@mui/material/Card';
import App from 'AppInterface/App';
import clsx from 'clsx';
import { FullScreenTimerControl } from 'components/dhyan/FullScreenTimerControl';
import MiniTimerControl from 'components/dhyan/MiniTimerControl';
import { withRouter } from 'components/shell/WithRouter';
import { logEvent } from 'components/telemetry';
import { DBConfig } from 'config';
import { canContinouslyPlay, capitalize, getAudioFilenameFromSrc, getContentStorageUrl, isWebViewEnv } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { triggerFunction } from 'services/api/functions';
import { getArticlesByType } from 'store/data/articles/selectors';
import { addOfflineAudio } from 'store/offline/actions';
import { removeAudioPlaybackDetails, updateAudioPlaybackArticleIndex, updateAudioPlaybackArticleList, updateMiniPlayerMode } from 'store/temp/actions';
import {
	ApplicationState,
	Article,
	ArticlesById,
	ArticlesByType,
	AudioCurrentState,
	ContentType,
	DownloadState,
	DownloadStatus,
	TimerRunningState,
	TimerState,
	TimerType,
} from 'types';
import { AudioPlaybackInstance } from './AudioPlayback';
import FullScreenPlayer from './FullScreenPlayer';
import MiniAudioPlayer from './MiniPlayer';

interface MediaPlayerProps {
	currentState?: AudioCurrentState;
	repeat: boolean;
	currentIndex: number;
	history: any;
	location: any;
	articlesById: ArticlesById;
	articlesByType: ArticlesByType;
	miniMode: boolean;
	autoDownloadIds: string[];
	offlineAudio: { [id: string]: DownloadState };
	timerState?: TimerState;
	// fetchArticle: (href: string, articleType: string) => Promise<FBArticle>;
	// fetchRecords: (recordType: string) => Promise<Article[]>;
	updateAudioPlaybackArticleList: (articleList: Article[], currentIndex: number) => void;
	updateAudioPlaybackArticleIndex: (currentIndex: number) => void;
	removeAudioPlaybackDetails: () => void;
	updateMiniPlayerMode: (value: boolean) => void;
	addOfflineAudio: (article: Article) => void;
}

const classes = {
	root: {
		position: 'fixed' as 'fixed',
		bottom: '55px; bottom: calc(55px + env(safe-area-inset-bottom)/2)',
		left: 0,
		right: 0,
		height: 80,
		lineHeight: 0,

		transition: 'all ease 0.1s',
		zIndex: 1199,

		'&.full': {
			bottom: 0,
			height: '100%',
		},
	},
};

interface MediaPlayerState {
	fetching: boolean;
	articles?: Article[];
	timerEnabled: boolean;
	isHidden?: boolean;
}

export class MediaPlayer extends React.Component<MediaPlayerProps, MediaPlayerState> {
	private static __instance: MediaPlayer;

	constructor(props: any) {
		super(props);

		MediaPlayer.__instance = this;

		this.state = {
			articles: undefined,
			fetching: false,
			timerEnabled: false,
		};
	}

	public static instance(): MediaPlayer {
		return MediaPlayer.__instance;
	}

	private autoDownloadIfRequired(article: Article) {
		let { autoDownloadIds, offlineAudio, addOfflineAudio } = this.props;

		if (autoDownloadIds.indexOf(article.id) > -1 && (!offlineAudio[article.id] || offlineAudio[article.id].progress?.status !== DownloadStatus.Complete)) {
			addOfflineAudio(article);
		}

		if (!article.mediaUriR2) {
			triggerFunction('onAudioPlayed', { article });
		}
	}

	public showTimerView = () => {
		this.stopMusicPlayer();
		this.setState({ timerEnabled: true, isHidden: false });
		this.props.updateMiniPlayerMode(false);
	};

	public hideTimerView = () => {
		this.setState({ timerEnabled: false });
	};

	public playAudio(href: string, articleType: string, playedTime: number, isHidden: boolean = false) {
		if (this.state.articles) {
			AudioPlaybackInstance().stopAudio();
		}

		this.setState({ isHidden });

		// this.setState({
		// 	fetching: true,
		// });

		// this.props.fetchArticle(href, articleType).then((article) => {
		let article = this.props.articlesById[href];
		if (!article && this.retries <= 10) {
			this.retries++;
			setTimeout(() => this.playAudio(href, articleType, playedTime), 500);
			return;
		} else if (!article) {
			return;
		} else {
			this.retries = 0;
		}

		let articles = [article];

		this.autoDownloadIfRequired(article);

		if (isWebViewEnv() && article.mediaType === 'video') {
			this.stopMusicPlayer();
			let mediaSrc = getContentStorageUrl(ContentType.Article, article.articleType, article);

			let albumTitle = article.articleType === 'shriaarti' ? 'Pooja Ke Phool' : capitalize(article.articleType);
			logEvent('playMedia', {
				id: article.id,
				title: article.title.hi || article.title.en || albumTitle,
				uri: article.mediaUri,
			});
			App.playMedia(
				article.id,
				mediaSrc,
				getAudioFilenameFromSrc(article.mediaUri!),
				article.title.hi || article.title.en || albumTitle,
				false,
				true,
				0,
				albumTitle,
				'Shri Anandpur Satsang'
			);

			return;
		}

		this.setState(
			{
				// fetching: false,
				articles: articles,
			},
			() => {
				this.props.updateAudioPlaybackArticleList([article], 0);
				setTimeout(() => {
					AudioPlaybackInstance().playAudio();

					if (playedTime > 5) {
						let count = 0;
						let timer = setInterval(() => {
							if (this.props.currentState === AudioCurrentState.Playing) {
								AudioPlaybackInstance().seekTo(playedTime - 3);
								clearInterval(timer);
							} else if (count++ > 20) {
								clearInterval(timer);
							}
						}, 500);
					}
				}, 100);
			}
		);
		// });
	}

	private retries = 0;
	public playAllAudio(href: string, articleType: string) {
		if (this.state.articles) {
			AudioPlaybackInstance().stopAudio();
		}

		// this.setState({
		// 	fetching: true,
		// });

		// this.props.fetchRecords(articleType).then((articles) => {
		let articles = this.props.articlesByType[articleType];
		let index = articles.findIndex((value: Article) => {
			return value.id === href;
		});

		if (index < 0 && this.retries <= 10) {
			this.retries++;
			setTimeout(() => this.playAllAudio(href, articleType), 500);
			return;
		} else if (index < 0) {
			return;
		} else {
			this.retries = 0;
		}

		this.setState(
			{
				fetching: false,
				articles: articles,
			},
			() => {
				this.props.updateAudioPlaybackArticleList(articles, index);

				setTimeout(() => {
					AudioPlaybackInstance().playAudio();
				}, 100);
			}
		);
		// });
	}

	public playRecords(records: Article[], index: number = 0) {
		if (this.state.articles) {
			AudioPlaybackInstance().stopAudio();
		}

		this.setState(
			{
				// fetching: false,
				articles: records,
			},
			() => {
				this.props.updateAudioPlaybackArticleList(records, index);
				setTimeout(() => {
					AudioPlaybackInstance().playAudio();
				}, 100);
			}
		);
	}

	public stopMusicPlayer = (e?: React.MouseEvent) => {
		e?.stopPropagation();

		this.setState(
			{
				articles: undefined,
				fetching: false,
			},
			() => {
				AudioPlaybackInstance().stopAudio();
				this.props.removeAudioPlaybackDetails();
			}
		);
	};

	public playPauseAudio = (e: React.MouseEvent) => {
		e.stopPropagation();

		AudioPlaybackInstance().playPauseAudio();
	};

	public showFullScreen = () => {
		this.props.updateMiniPlayerMode(true);
	};

	public toggleFullScreen = () => {
		this.props.updateMiniPlayerMode(!this.props.miniMode);
	};

	private moveToIndex = (index: number) => {
		this.props.updateAudioPlaybackArticleIndex(index);

		// if (canContinouslyPlay()) {
		// 	App.seekToMediaItemIndex(index, 0);
		// } else {
		// 	this.props.updateAudioPlaybackArticleIndex(index);
		// }
	};

	UNSAFE_componentWillReceiveProps(newProps: MediaPlayerProps) {
		if (newProps.timerState?.currentState === TimerRunningState.Running && newProps.timerState.type === TimerType.Dhyan) {
			this.setState({ timerEnabled: true });
			this.props.updateMiniPlayerMode(false);
		}

		if (
			!canContinouslyPlay() &&
			this.state.articles &&
			newProps.currentState === AudioCurrentState.Ended &&
			newProps.currentState !== this.props.currentState
		) {
			let index = this.props.currentIndex;

			if (index < this.state.articles.length - 1) {
				this.moveToIndex(index + 1);
			} else if (newProps.repeat) {
				this.moveToIndex(0);
			} else {
				return;
			}

			setTimeout(() => {
				AudioPlaybackInstance().playAudio();
			}, 100);
		}
	}

	render() {
		let { miniMode } = this.props;
		let { articles, fetching, timerEnabled, isHidden } = this.state;

		if (isHidden) {
			return <></>;
		}

		if (!((!articles || articles?.length === 0) && !fetching)) {
			return (
				<Card sx={classes.root} className={clsx({ full: !miniMode })} elevation={1} square onClick={!miniMode ? undefined : this.toggleFullScreen}>
					<FullScreenPlayer
						enabled={!miniMode}
						playPauseAudio={this.playPauseAudio}
						stopMusicPlayer={this.stopMusicPlayer}
						toggleFullScreen={this.toggleFullScreen}
						moveToIndex={this.moveToIndex}
					/>
					<MiniAudioPlayer enabled={miniMode} playPauseAudio={this.playPauseAudio} stopMusicPlayer={this.stopMusicPlayer} />
				</Card>
			);
		}

		if (timerEnabled) {
			return (
				<Card sx={classes.root} className={clsx({ full: !miniMode })} elevation={1} square onClick={!miniMode ? undefined : this.toggleFullScreen}>
					<FullScreenTimerControl enabled={!miniMode} stopTimer={this.hideTimerView} toggleFullScreen={this.toggleFullScreen} />
					<MiniTimerControl enabled={miniMode} />
				</Card>
			);
		}
	}
}

function mapStateToProps(state: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = state.tempState;
	let autoDownloadIds: string[] = (state.dataState.configs.byId[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];
	let offlineAudio = state.offlineState.audio;

	return {
		currentState: audioPlaybackState.currentState,
		currentIndex: audioPlaybackDetails?.currentIndex,
		articlesById: state.dataState.articles.byId,
		articlesByType: getArticlesByType(state),
		repeat: audioPlaybackState.repeat,
		miniMode: audioPlaybackState.isMiniPlayerMode,
		autoDownloadIds,
		offlineAudio,
		timerState: state.tempState.timerState,
	};
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, unknown, AnyAction>) {
	return {
		updateAudioPlaybackArticleList: (articleList: Article[], currentIndex: number) => {
			dispatch(updateAudioPlaybackArticleList(articleList, currentIndex));
		},
		updateAudioPlaybackArticleIndex: (currentIndex: number) => {
			dispatch(updateAudioPlaybackArticleIndex(currentIndex));
		},
		removeAudioPlaybackDetails: () => {
			dispatch(removeAudioPlaybackDetails());
		},
		updateMiniPlayerMode: (value: boolean) => {
			dispatch(updateMiniPlayerMode(value));
		},
		addOfflineAudio: (article: Article) => {
			dispatch(addOfflineAudio(article, false));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MediaPlayer));
