import AnandDate from 'helpers/AnandDate';
import { combineReducers } from 'redux';
import {
	AppTempState,
	AudioCurrentState,
	AudioPlaybackDetails,
	AudioPlaybackState,
	NetStatus,
	TimerRunningState,
	TimerRunType,
	TimerState,
	TimerType,
} from 'types';
import {
	ActionDrawer,
	ActionLoading,
	AdminPreview,
	CLOSE_DRAWER,
	CLOSE_SNACKBAR,
	ENQUEUE_SNACKBAR,
	OPEN_DRAWER,
	RemoveAudioPlaybackDetails,
	REMOVE_AUDIO_PLAYBACK_DETAILS,
	REMOVE_SNACKBAR,
	SearchFor,
	SEARCH_FOR,
	SetPreviewSrc,
	SET_PREVIEW_SRC,
	SHOW_PREVIEW,
	START_LOADING,
	STOP_LOADING,
	ToggleAlertDialog,
	ToggleBottomDrawer,
	ToggleOnDemandPage,
	TOGGLE_ALERT_DIALOG,
	TOGGLE_BOTTOM_DRAWER,
	TOGGLE_DRAWER,
	TOGGLE_ON_DEMAND_PAGE,
	UpdateAppBarTitle,
	UpdateAudioPlaybackArticleIndex,
	UpdateAudioPlaybackArticleList,
	UpdateAudioPlaybackDuration,
	UpdateAudioPlaybackRepeat,
	UpdateAudioPlaybackSpeed,
	UpdateAudioPlaybackState,
	UpdateAudioPlaybackTime,
	UpdateFullScreen,
	UpdateMiniPlayerMode,
	UpdateNetStatus,
	UpdateTimerRunState,
	UpdateTimerState,
	UpdateTimerTime,
	UpdateToday,
	UPDATE_APP_BAR_TITLE,
	UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX,
	UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST,
	UPDATE_AUDIO_PLAYBACK_DURATION,
	UPDATE_AUDIO_PLAYBACK_REPEAT,
	UPDATE_AUDIO_PLAYBACK_SPEED,
	UPDATE_AUDIO_PLAYBACK_STATE,
	UPDATE_AUDIO_PLAYBACK_TIME,
	UPDATE_FULLSCREEN,
	UPDATE_MINI_PLAYER_MODE,
	UPDATE_NET_STATUS,
	UPDATE_TIMER_RUN_STATE,
	UPDATE_TIMER_STATE,
	UPDATE_TIMER_TIME,
	UPDATE_TODAY,
} from './actions';

function loadingReducer(state: number = 0, action: ActionLoading) {
	switch (action.type) {
		case START_LOADING:
			return state + 1;
		case STOP_LOADING:
			return state - 1;
		default:
			return state;
	}
}

function appDrawerReducer(state: boolean = false, action: ActionDrawer) {
	switch (action.type) {
		case OPEN_DRAWER:
			return true;
		case CLOSE_DRAWER:
			return false;
		case TOGGLE_DRAWER:
			return !state;
		default:
			return state;
	}
}

function appBarReducer(state: string = '', action: UpdateAppBarTitle) {
	switch (action.type) {
		case UPDATE_APP_BAR_TITLE:
			return action.title;
		default:
			return state;
	}
}

function bottomDrawerReducer(state: { open: boolean; content?: any } = { open: false }, action: ToggleBottomDrawer) {
	switch (action.type) {
		case TOGGLE_BOTTOM_DRAWER:
			return {
				open: action.open ?? !state.open,
				content: action.content ? action.content : state.content,
				styles: action.styles,
			};
		default:
			return state;
	}
}

function alertDialogReducer(state: { open: boolean; content?: any } = { open: false }, action: ToggleAlertDialog) {
	switch (action.type) {
		case TOGGLE_ALERT_DIALOG:
			return { open: !state.open, content: action.content ? action.content : state.content };
		default:
			return state;
	}
}

function onDemandPageReducer(state: { open: boolean; component?: any } = { open: false }, action: ToggleOnDemandPage) {
	switch (action.type) {
		case TOGGLE_ON_DEMAND_PAGE:
			return { open: !state.open, component: action.component ? action.component : state.component };
		default:
			return state;
	}
}

function netStatusReducer(state: NetStatus = 'online', action: UpdateNetStatus) {
	switch (action.type) {
		case UPDATE_NET_STATUS:
			return action.status;
		default:
			return state;
	}
}

function audioPlaybackDetailsReducer(
	state: AudioPlaybackDetails | null = null,
	action: UpdateAudioPlaybackArticleList | UpdateAudioPlaybackArticleIndex | RemoveAudioPlaybackDetails
) {
	switch (action?.type) {
		case UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST:
			if (!state) {
				return {
					articleList: action.articleList,
					currentIndex: action.currentIndex,
				};
			} else {
				return { ...state, articleList: action.articleList, currentIndex: action.currentIndex };
			}
		case UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX:
			if (!state) {
				break;
			} else {
				return { ...state, currentIndex: action.currentIndex };
			}
		case REMOVE_AUDIO_PLAYBACK_DETAILS:
			return null;
		default:
			return state;
	}
	throw new Error('No article being played');
}

function audioPlaybackStateReducer(
	state: AudioPlaybackState = {
		currentState: AudioCurrentState.Initializing,
		duration: undefined,
		currentTime: 0,
		repeat: 0,
		isMiniPlayerMode: false,
	},
	action:
		| UpdateAudioPlaybackState
		| UpdateAudioPlaybackDuration
		| UpdateAudioPlaybackSpeed
		| UpdateAudioPlaybackTime
		| UpdateAudioPlaybackRepeat
		| UpdateMiniPlayerMode
) {
	switch (action?.type) {
		case UPDATE_AUDIO_PLAYBACK_STATE:
			return { ...state, currentState: action.currentState };
		case UPDATE_AUDIO_PLAYBACK_TIME:
			return { ...state, currentTime: action.currentTime };
		case UPDATE_AUDIO_PLAYBACK_REPEAT:
			return { ...state, repeat: action.repeat };
		case UPDATE_AUDIO_PLAYBACK_DURATION:
			return { ...state, duration: action.duration };
		case UPDATE_AUDIO_PLAYBACK_SPEED:
			return { ...state, playbackSpeed: action.playbackSpeed };
		case UPDATE_MINI_PLAYER_MODE:
			return { ...state, isMiniPlayerMode: action.value };
		default:
			return state;
	}
}

const notifierReducre = (state: any[] = [], action) => {
	switch (action.type) {
		case ENQUEUE_SNACKBAR:
			return [
				...state,
				{
					key: action.key,
					...action.notification,
				},
			];

		case CLOSE_SNACKBAR:
			return [
				state.map((notification) =>
					action.dismissAll || notification.key === action.key ? { ...notification, dismissed: true } : { ...notification }
				),
			];

		case REMOVE_SNACKBAR:
			return state.filter((notification) => notification.key !== action.key);

		default:
			return state;
	}
};

const searchReducer = (state: string = '', action: SearchFor) => {
	switch (action.type) {
		case SEARCH_FOR:
			return action.term;
		default:
			return state;
	}
};

const previewReducer = (state: boolean = true, action: AdminPreview) => {
	switch (action.type) {
		case SHOW_PREVIEW:
			return action.value;
		default:
			return state;
	}
};

const previewSrcReducer = (state: string = window.location.origin, action: SetPreviewSrc) => {
	switch (action.type) {
		case SET_PREVIEW_SRC:
			return action.value + '#' + Math.random();
		default:
			return state;
	}
};

function timerStateReducer(
	state: TimerState = {
		type: TimerType.Dhyan,
		currentState: TimerRunningState.Stopped,
		duration: 0,
		elapsedTime: 0,
		startTime: 0,
		remainingTime: 0,
		music: false,
		runType: TimerRunType.CountDown,
	},
	action: UpdateTimerState | UpdateTimerTime | UpdateTimerRunState
) {
	switch (action?.type) {
		case UPDATE_TIMER_STATE:
			return { ...action.state };
		case UPDATE_TIMER_TIME:
			return { ...state, remainingTime: action.remainingTime };
		case UPDATE_TIMER_RUN_STATE:
			return { ...state, currentState: action.currentState };
		default:
			return state;
	}
}

const fullScreenReducer = (state: boolean = false, action: UpdateFullScreen) => {
	switch (action.type) {
		case UPDATE_FULLSCREEN:
			return action.enabled;
		default:
			return state;
	}
};

const todayReducer = (state: AnandDate = new AnandDate().setHours(0, 0, 0), action: UpdateToday) => {
	switch (action.type) {
		case UPDATE_TODAY:
			return action.today ?? new AnandDate().setHours(0, 0, 0);
		default:
			return state;
	}
};

export default combineReducers<AppTempState>({
	isLoading: loadingReducer,
	isDrawerOpen: appDrawerReducer,
	bottomDrawer: bottomDrawerReducer,
	alertDialog: alertDialogReducer,
	onDemandPage: onDemandPageReducer,
	appBarTitle: appBarReducer,
	netStatus: netStatusReducer,
	audioPlaybackDetails: audioPlaybackDetailsReducer,
	audioPlaybackState: audioPlaybackStateReducer,
	notifications: notifierReducre,
	searchTerm: searchReducer,
	showAdminPreview: previewReducer,
	previewSrc: previewSrcReducer,
	timerState: timerStateReducer,
	isFullScreen: fullScreenReducer,
	today: todayReducer,
});
