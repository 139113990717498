import React from 'react';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import 'whatwg-fetch';
import App from './App';
import WebViewApp from './AppInterface/App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

let window_ = window as any;

var logBackup = console.log;
var errorBackup = console.error;
window_.logMessages = [];

console.log = function (...args) {
	window_.logMessages.push.apply(window_.logMessages, args);
	logBackup.apply(console, args);
};

console.error = function (...args) {
	window_.logMessages.push.apply(window_.logMessages, args);
	errorBackup.apply(console, args);
};

if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production' && window.location.hostname !== 'localhost') {
	window.location.replace(window.location.href.replace('http:', 'https:'));
} else {
	let domNode = document.getElementById('root');
	let root = createRoot(domNode!);
	root.render(<App />);

	if ('serviceWorker' in navigator) {
		console.log('serviceWorker');
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		const onSWUpdate = (registration: ServiceWorkerRegistration) => {
			console.log('onSWUpdate');
			registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
			// WebViewApp.showToast('Loading latest updates...');
			// window.location.href = '/';
			// setTimeout(() => {
			// 	if (window.location.pathname !== '/') {
			// 		window.location.href = '/';
			// 	} else {
			// 		window.location.reload();
			// 	}
			// }, 500);
		};

		const onSWSuccess = (registration: ServiceWorkerRegistration) => {
			console.log('onSWSuccess');
			WebViewApp.showToast('Ready for offline use!');
		};

		if (window.location.hash === '#nocache') {
			serviceWorkerRegistration.unregister();
		} else {
			console.log('going to register SW');
			serviceWorkerRegistration.register({ onUpdate: onSWUpdate, onSuccess: onSWSuccess });
		}
	} else if (window_.applicationCache) {
		console.log('appCache');
		window.addEventListener(
			'load',
			function (e) {
				window_.applicationCache.addEventListener(
					'updateready',
					function (e) {
						console.log('appCache updateready, status:', window_.applicationCache.status);
						// eslint-disable-next-line
						if (window_.applicationCache.status == window_.applicationCache.UPDATEREADY) {
							// Browser downloaded a new app cache.
							// WebViewApp.showToast('Loading latest updates...');
							// setTimeout(() => {
							// 	if (window.location.pathname !== '/') {
							// 		window.location.href = '/';
							// 	} else {
							// 		window_.location.reload();
							// 	}
							// }, 200);
						}
					},
					false
				);

				window_.applicationCache.addEventListener(
					'cached',
					function (e) {
						console.log('appCache cached');
						WebViewApp.showToast('Ready for offline use!');
					},
					false
				);
			},
			false
		);
	}
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
	// reportWebVitals(console.log);
}
