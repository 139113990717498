import { Badge, BadgeProps, Box, Button, Divider, styled } from '@mui/material';
import App from 'AppInterface/App';
import { MediaPlayer } from 'components/player/MediaPlayer';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { TimeSelectorControl } from 'components/TimeSelectorControl';
import { DefaultTimerPresets } from 'config';
import { Icons } from 'config/icons';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import { toggleBottomDrawer, updateTimerState } from 'store/temp/actions';
import { updateTimerPresets } from 'store/ux/actions';
import { ApplicationState, TimerPreset, TimerRunningState, TimerRunType, TimerState, TimerType } from 'types';
import { timerThemeColor } from './config';
import { TimerPresetControl } from './TimerPresetControl';

const classes = {
	root: {},
	presets: { display: 'flex', flexDirection: 'row', overflow: 'auto' },
	badge: (pinned) => ({
		'& .MuiBadge-badge': {
			p: 1,
			color: pinned ? timerThemeColor : 'darkgray',
		},
	}),
};

interface Props {
	timerPresets: TimerPreset[];
	timerMusic: boolean;
	updateTimerPresets: (presets: TimerPreset[]) => void;
	updateTimerState: (timerState: TimerState) => void;
	toggleDrawer: (content?: any) => void;
	sx?: any;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: 0,
		top: 16,
		background: 'none',
		color: 'darkgray',
		transform: 'rotate(37deg)',
	},
}));

const TimeSelector = (props) => {
	let { toggleDrawer, onSubmit } = props;
	let [timeValue, setTimeValue] = useState<Moment | null>(moment.utc(0));

	return (
		<Box sx={{ width: '100%' }}>
			<TimeSelectorControl
				maxHours={12}
				onAccept={(secs) => {
					if (secs <= 0) {
						return;
					}
					onSubmit(secs);

					toggleDrawer();
				}}
			/>
		</Box>
	);
};

const Component = (props: Props) => {
	let { timerPresets, timerMusic, sx } = props;
	let { updateTimerPresets, updateTimerState, toggleDrawer } = props;

	const optionsObj = {
		element: (
			<TimeSelector
				toggleDrawer={toggleDrawer}
				onSubmit={(secs) => {
					let st = new Date().getTime();
					updateTimerState({
						currentState: TimerRunningState.Running,
						startTime: st,
						duration: secs,
						remainingTime: secs,
						elapsedTime: 0,
						music: false,
						type: TimerType.Dhyan,
						runType: TimerRunType.CountDown,
					});

					App.startCountDownTimer(st.toString(), secs, false, timerMusic);

					// timerPresets.push({ value: secs / 60, frequency: 1 });
					// updateTimerPresets(timerPresets);
				}}
			/>
		),
		options: [],
		actionHandler: () => {
			// setTimeValue(moment.utc(0));
			toggleDrawer();
		},
	};

	const presetBtn = (preset) => {
		let val = preset.value;
		return (
			<StyledBadge
				key={val}
				onClick={() => {
					preset.pinned = !preset.pinned;
					preset.pinnedAt = new Date().getTime();
					updateTimerPresets(timerPresets);
				}}
				badgeContent={<Icons.PushPinIcon />}
				sx={classes.badge(preset.pinned)}
			>
				<Box>
					<Button
						onClick={(e) => {
							e.stopPropagation();

							let st = new Date().getTime();
							updateTimerState({
								currentState: TimerRunningState.Running,
								startTime: st,
								duration: val * 60,
								remainingTime: val * 60,
								elapsedTime: 0,
								music: false,
								type: TimerType.Dhyan,
								runType: TimerRunType.CountDown,
							});

							App.startCountDownTimer(st.toString(), val * 60, false, timerMusic);
							// MediaPlayer.instance().showTimerView();
						}}
					>
						<TimerPresetControl duration={val >= 60 ? val / 60 : val} label={val >= 60 ? 'hr' : 'min'} />
					</Button>
				</Box>
			</StyledBadge>
		);
	};

	let pinned = timerPresets.filter((preset) => preset.pinned).sort((a, b) => (a.pinnedAt ?? 0) - (b.pinnedAt ?? 0));
	return (
		<Box sx={{ ...classes.presets, ...sx }}>
			{pinned.map((preset) => {
				return presetBtn(preset);
			})}
			{pinned.length > 0 && <Divider orientation="vertical" flexItem />}
			{timerPresets
				.sort((a, b) => b.value - a.value)
				.map((preset) => {
					return presetBtn(preset);
				})}
			<Box>
				<Button
					onClick={() => {
						let upTimeDuration = 180 * 60;
						let st = new Date().getTime();
						updateTimerState({
							currentState: TimerRunningState.Running,
							startTime: st,
							duration: upTimeDuration,
							remainingTime: upTimeDuration,
							elapsedTime: 0,
							music: false,
							type: TimerType.Dhyan,
							runType: TimerRunType.CountUp,
						});

						App.startCountDownTimer(st.toString(), upTimeDuration, true, timerMusic);
					}}
				>
					<TimerPresetControl duration={<Icons.TimerIcon sx={{ fontSize: '5rem' }} />} />
				</Button>
			</Box>
			<Box>
				<Button
					onClick={(e) => {
						e.stopPropagation();
						toggleDrawer(BottomDrawerOptions(optionsObj));
					}}
				>
					<TimerPresetControl duration={'+'} />
				</Button>
			</Box>
		</Box>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let timerPresets = state.uxState.timerPresets;
	if (!timerPresets || !timerPresets.length) {
		timerPresets = DefaultTimerPresets.sort((a, b) => b - a).map((val) => ({ value: val, frequency: 1 }));
	}

	return {
		timerPresets,
		timerMusic: state.uxState.timerMusic ?? false,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateTimerPresets: (presets: TimerPreset[]) => {
			dispatch(updateTimerPresets(presets));
		},
		updateTimerState: (timerState: TimerState) => {
			MediaPlayer.instance().stopMusicPlayer();
			dispatch(updateTimerState(timerState));
		},
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
	};
}

export const TimerPresetWidget = connect(mapStateToProps, mapDispatchToProps)(Component);
