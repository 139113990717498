import { Box, IconButton, Slider } from '@mui/material';
import { ArticleTextContent } from 'components/articles/ArticleTextContent';
import Page from 'components/pages/Page';
import { PrimaryMainColor } from 'config';
import { getContentStorageUrl, timeout } from 'helpers';
import { RefObject, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { updateBookletIndex } from 'store/ux/actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { ApplicationState, Article, AudioCurrentState, AudioPlaybackDetails, AudioPlaybackState, Booklet, BookletPage, BookletsById, ContentType } from 'types';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import './album.css';
// import required modules
import { EndContent } from 'components/EndContent';
import TextContent from 'components/TextContent';
import { PrimaryMainColorDark } from 'config/theme';
import { toggleBottomDrawer, updateAudioPlaybackSpeed, updateFullScreen } from 'store/temp/actions';
import { Autoplay, EffectCoverflow, Pagination, Virtual, Zoom } from 'swiper/modules';
import { Icons } from 'config/icons';
import { MediaPlayer } from 'components/player/MediaPlayer';
import { AudioPlaybackInstance } from 'components/player/AudioPlayback';

const classes = {
	wrapper: {
		padding: 0,
	},
	vertical: {
		height: '100%',

		'& .swiper': {
			width: '100%',
			height: '100%',
		},

		'& .swiper-slide': {
			textAlign: 'center',
			fontSize: 18,
			// background: '#fff',

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			overflow: 'hidden',

			'& img.pic': {
				display: 'block',
				// width: '100%',
				height: '100%',
				objectFit: 'contain',
			},
		},
	},
	root: (fullScreen, landscape) => ({
		// height: 'auto',
		padding: 0,
		position: 'relative',

		'& .swiper': {
			width: '100%',
		},
		'& .mainSwiper': {
			pt: fullScreen ? 0 : 0, //2,
			pb: 6,
			overflow: 'visible',

			'& .swiper-slide': {
				width: fullScreen ? '100vw' : '100vw',
				// height: fullScreen ? '100vw' : '75vw',
				// maxWidth: 500,
				// maxHeight: 'min(75vh, 500px)',
			},
		},
		'& .thumbSwiper': {
			pt: 0,
			'& .swiper-slide': {
				maxWidth: 100,
				maxHeight: 100,
			},
		},
		'& .swiper-slide': {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			opacity: 1,
			transition: 'opacity 4s ease-in-out',
			overflow: 'hidden',

			'& img.pic': {
				display: 'block',
				width: '100%',
				opacity: 1,
			},
		},
		'& .swiper-slide-next, & .swiper-slide-prev': {
			opacity: 1,
		},

		'& .swiper-pagination-bullet-active': {
			color: 'green',
		},
	}),
	stepper: { maxWidth: 400, flexGrow: 1, background: 'white', margin: 'auto' },
	next: {
		marginLeft: 'auto !important',
	},
	details: {
		'& p:first-of-type': {
			marginTop: 0,
		},
	},
};

interface AlbumProps {
	isEmbed?: boolean;
	booklet: Booklet;
	articles: Article[];
	flatPages: BookletPage[];
	currentIndex: number;
	isFullScreen: boolean;
	locale: string;
	audioPlaybackState: AudioPlaybackState;
	audioPlaybackDetails: AudioPlaybackDetails | null;
	updateBookletIndex: (booklet: string, index: number) => void;
	updateFullScreen: (enabled: boolean) => void;
	toggleDrawer: (content?: any, open?: boolean) => void;
	updateAudioPlaybackSpeed: (speed: number) => void;
	style?: any;
}

const screen = window.screen;
const getOrientation = () => {
	if (screen.orientation) {
		return screen.orientation.type;
	} else {
		if (screen.width > screen.height) {
			return 'landscape';
		} else {
			return 'portrait';
		}
	}
};

const swiperStyles = (dark) => ({
	// '--swiper-navigation-color': dark ? 'white' : PrimaryMainColor,
	// '--swiper-pagination-color': dark ? 'white' : PrimaryMainColor,
	'--swiper-theme-color': PrimaryMainColor,
	'--swiper-pagination-bullet-inactive-color': dark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.2)',
});

const onAutoplayTimeLeft = (circle: RefObject<HTMLElement>, content: RefObject<HTMLElement>) => (s, time, progress) => {
	if (!circle.current || !content.current) {
		return;
	}

	circle.current.style.setProperty('--progress', (1 - progress).toString());
	content.current.textContent = `${Math.ceil(time / 1000)}s`;
};

const audioId = '54RFjG1n2ijUVijHfQme';
let timeSlidesMap = {
	0: 0,
	1: 75,
	2: 125,
	3: 183,
	4: 232,
	5: 284,
	6: 338,
	7: 392,
	8: 447,
	9: 499,
	10: 549,
	11: 607,
	12: 656,
	13: 709,
	14: 763,
	15: 818,
	16: 869,
	17: 924,
	18: 977,
	19: 1032,
	20: 1083,
	21: 1145,
	22: 1184,
	23: 1246,
	24: 1296,
	25: 1354,
	26: 1398,
	27: 1459,
	28: 1507,
	29: 1564,
	30: 1616,
	31: 1664,
};

const Component = (props: AlbumProps) => {
	let {
		booklet,
		flatPages: pages,
		updateBookletIndex,
		currentIndex,
		style,
		articles,
		isFullScreen,
		audioPlaybackState,
		audioPlaybackDetails,
		updateFullScreen,
		toggleDrawer,
		updateAudioPlaybackSpeed,
		locale,
	} = props;
	const progressCircle = useRef<any>(null);
	const progressContent = useRef<any>(null);
	const progressCircleVert = useRef<any>(null);
	const progressContentVert = useRef<any>(null);

	const [swiperRef, setSwiperRef] = useState<SwiperType>(null);
	// const [swiperRefVert, setSwiperRefVert] = useState<SwiperType>(null);
	const [orientation, setOrientation] = useState(getOrientation());

	const [isAudioPlaying, setAudioPlaying] = useState(false);

	let curAudio = audioPlaybackDetails?.articleList[audioPlaybackDetails?.currentIndex];
	let isChroniclesAudioActive = curAudio?.id === audioId;

	const audioActiveRef = useRef<boolean>();
	audioActiveRef.current = isChroniclesAudioActive;

	useEffect(() => {
		const handleOrientationChange = () => {
			let val = getOrientation();
			setOrientation(val);
			updateFullScreen(val.indexOf('landscape') >= 0);
		};

		handleOrientationChange();

		screen.orientation?.addEventListener('change', handleOrientationChange);
		return () => {
			screen.orientation?.removeEventListener('change', handleOrientationChange);

			if (audioActiveRef.current) {
				MediaPlayer.instance().stopMusicPlayer();
			}
		};
	}, []);

	useEffect(() => {
		let curAudio = audioPlaybackDetails?.articleList[audioPlaybackDetails?.currentIndex];
		let isChroniclesAudioActive = curAudio?.id === audioId;

		if (isChroniclesAudioActive && audioPlaybackState.currentState === AudioCurrentState.Playing) {
			let points = Object.values(timeSlidesMap).sort((a, b) => a - b);
			let slide = points.length - 1;
			for (let i = 0; i < points.length - 1; i++) {
				if (audioPlaybackState.currentTime + 0.5 < points[i + 1]) {
					slide = i;
					break;
				}
			}

			if (!isAudioPlaying) {
				setAudioPlaying(true);
			}
			if (swiperRef?.autoplay?.running) {
				swiperRef?.autoplay?.stop();
			}

			if (swiperRef?.activeIndex !== slide) {
				swiperRef?.slideTo(slide, 1000, false);
			}
		} else if (
			(isChroniclesAudioActive || !curAudio) &&
			(audioPlaybackState.currentState === AudioCurrentState.Ended || audioPlaybackState.currentState === AudioCurrentState.Stopped)
		) {
			// swiperRef.slideTo(0, 1000, false);
			if (audioPlaybackState.currentState === AudioCurrentState.Ended) {
				MediaPlayer.instance().stopMusicPlayer();
			}
			setAudioPlaying(false);
			swiperRef?.autoplay?.start();
		}
	}, [audioPlaybackState, audioPlaybackDetails]);

	let onAutoplayClick = () => {
		if (swiperRef?.autoplay?.running) {
			swiperRef?.autoplay?.stop();
		} else {
			swiperRef?.autoplay?.start();
		}
	};

	let fullScreen = isFullScreen;
	let isLandscape = orientation.indexOf('landscape') >= 0;

	let urls: string[] = [];
	articles.forEach((article) => {
		let url = getContentStorageUrl(ContentType.Article, article.articleType, article);
		urls.push(url);
	});

	currentIndex = currentIndex >= 0 && currentIndex < urls.length ? currentIndex : 0;

	let curArticle = articles[currentIndex];
	let isLastSlide = currentIndex === urls.length - 1;

	let lang = curArticle?.text && curArticle?.text[locale] ? locale : curArticle?.text?.hi ? 'hi' : 'en';

	let contextText = curArticle?.context ? curArticle?.context[locale] || curArticle?.context.hi || curArticle.context?.en : undefined;
	let isHtml = contextText?.trim().startsWith('<');

	let context = contextText ? (
		<Box
			sx={{
				// position: 'absolute', top: 0
				mt: isLandscape ? -5.25 : -5,
			}}
			className={'locale-' + lang}
		>
			<TextContent
				style={{
					fontSize: '1rem',
					fontWeight: 'bold',
					paddingTop: 5,
					textAlign: 'center',
					marginTop: -12,
					color: PrimaryMainColorDark,
					lineHeight: 1,
				}}
				locale={lang}
			>
				{isHtml ? (
					<div
						dangerouslySetInnerHTML={{
							__html: contextText,
						}}
					></div>
				) : (
					<div>({contextText})</div>
				)}
			</TextContent>
		</Box>
	) : null;

	let slides = urls.map((url, index) => {
		return (
			<SwiperSlide key={index} virtualIndex={index}>
				<div className="swiper-zoom-container">
					<Box className="swiper-zoom-target" sx={{ width: '100%', height: '100%', mx: 'auto', display: 'flex', justifyContent: 'center' }}>
						<Box
							component={'img'}
							className={'pic'}
							src={url}
							loading="lazy"
							sx={{
								width: 'auto',
								boxSizing: 'border-box',
								border: '16px solid transparent',
								borderImage: `url(${getContentStorageUrl(ContentType.WebApp, 'chronicles_border.png')}) 55 stretch`,
							}}
						/>
						<Box
							sx={{
								position: 'absolute',
								top: -7,
								height: 30,
								width: 40 + ((contextText?.length ?? 0) - 7) * 7,
								left: `calc(50vw - ${(40 + ((contextText?.length ?? 0) - 7) * 7) / 2}px)`,
							}}
						>
							<Box
								component={'img'}
								src={getContentStorageUrl(ContentType.WebApp, 'small_glow.png')}
								sx={{
									// position: 'absolute',
									// width: 40 + ((contextText?.length ?? 0) - 7) * 7,
									// height: 28,
									// top: -8,
									objectFit: 'fill !important',
									width: '100%',
									height: '100%',
								}}
							></Box>
							{context}
						</Box>
					</Box>
				</div>
			</SwiperSlide>
		);
	});

	let autoplaySettings = {
		delay: 10000,
		waitForTransition: false,
		disableOnInteraction: true,
		// stopOnLastSlide: true,
	};

	let paginationSettings = {
		pagination: {
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 3,
			renderBullet: function (index, className) {
				return '<span class="' + className + '">' + (index + 1) + '</span>';
			},
		},
	};

	let commonSettings = (isl) => ({
		onSwiper: setSwiperRef,
		style: swiperStyles(isl),
		...paginationSettings,
		initialSlide: currentIndex,
		zoom: {
			maxRatio: 5,
		},
		rewind: true,
		onActiveIndexChange: (swiper: SwiperType) => {
			updateBookletIndex(booklet.id, swiper.activeIndex);
		},
		onSlideChangeTransitionStart: (swiper) => {
			if (isAudioPlaying) {
				let seekTo = timeSlidesMap[swiper.activeIndex];
				AudioPlaybackInstance().seekTo(seekTo);
			}
		},
		autoplay: autoplaySettings,
		lazy: 'true',
		autoHeight: true,
		grabCursor: true,
		spaceBetween: isl ? 10 : -10,
		modules: [Pagination, Autoplay, Zoom, EffectCoverflow, Virtual],
		direction: isl ? 'vertical' : 'horizontal',
		virtual: true,
		slidesPerView: 1,
	});

	let audioPlaybackControls = (
		<Box
			sx={{
				position: 'absolute',
				left: 4,
				bottom: isLandscape ? 12 : 0,
				zIndex: 10,
				display: 'flex',
				flexDirection: isLandscape ? 'column' : 'row',
				transform: 'scaleY(-1)',
			}}
		>
			<IconButton
				sx={{ p: 0.25 }}
				color="primary"
				size="large"
				onClick={(e) => {
					if (isChroniclesAudioActive) {
						MediaPlayer.instance().playPauseAudio(e);
						setAudioPlaying(!isAudioPlaying);
					} else {
						MediaPlayer.instance().playAudio(audioId, 'dhyan', 0, true);
						setAudioPlaying(true);
					}
					swiperRef?.autoplay?.stop();
				}}
			>
				{isChroniclesAudioActive && isAudioPlaying ? <Icons.PauseCircleFilled fontSize="large" /> : <Icons.PlayCircleFilled fontSize="large" />}
			</IconButton>
			{isChroniclesAudioActive && (
				<IconButton
					sx={{ p: 0.25 }}
					color="warning"
					size="large"
					onClick={(e) => {
						MediaPlayer.instance().stopMusicPlayer();
						setAudioPlaying(false);
						swiperRef?.autoplay?.start();
					}}
				>
					<Icons.StopCircleIcon fontSize="large" />
				</IconButton>
			)}
		</Box>
	);

	let landscapeSwiper = (
		<Box sx={{ height: '100%' }}>
			<Swiper
				{...commonSettings(true)}
				centeredSlides={true}
				className="mainVerticalSwiper"
				onAutoplayTimeLeft={onAutoplayTimeLeft(progressCircleVert, progressContentVert)}
			>
				{slides}
				{audioPlaybackControls}
				{!isChroniclesAudioActive && (
					<Box sx={{ bottom: 16 }} onClick={onAutoplayClick} className="autoplay-progress" slot="container-end">
						<svg viewBox="0 0 48 48" ref={progressCircleVert}>
							<circle cx="24" cy="24" r="20"></circle>
						</svg>
						<span ref={progressContentVert}></span>
					</Box>
				)}
			</Swiper>
		</Box>
	);

	let portraitSwiper = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: `calc(100vh - env(safe-area-inset-bottom)/2 - ${fullScreen ? 0 : 112}px)`,
				overflow: 'hidden',
			}}
		>
			<Swiper
				{...commonSettings(false)}
				className="mainSwiper"
				onAutoplayTimeLeft={onAutoplayTimeLeft(progressCircle, progressContent)}
				effect={'coverflow'}
				centeredSlides={true}
				// slidesPerView={'auto'}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				}}
			>
				{slides}
				{audioPlaybackControls}
				{!isChroniclesAudioActive && (
					<Box sx={{ bottom: 4 }} onClick={onAutoplayClick} className="autoplay-progress" slot="container-end">
						<svg viewBox="0 0 48 48" ref={progressCircle}>
							<circle cx="24" cy="24" r="20"></circle>
						</svg>
						<span ref={progressContent}></span>
					</Box>
				)}
			</Swiper>

			<Box sx={{ p: 2, pt: 1, flexGrow: 1, overflow: 'auto' }}>
				<ArticleTextContent includeContext={false} isEmbed={true} includeTitle={true} articleType={curArticle?.articleType} href={curArticle?.id} />
				<EndContent />
			</Box>
		</Box>
	);

	let curSpeed = audioPlaybackState?.playbackSpeed ?? 1;
	return (
		<Page
			key={'album' + (isLandscape ? '-land' : '-port')}
			title="Immortal Memories"
			includeBottomNavigation
			fullscreen={isLandscape || fullScreen ? true : false}
			style={isLandscape ? { backgroundColor: '#000000' } : { backgroundColor: '#f2faf0' }}
			contextualMoreOptions={
				isAudioPlaying
					? [
							{
								element: (
									<Slider
										sx={{ mx: 2 }}
										key={'pb-' + curSpeed}
										value={curSpeed}
										valueLabelDisplay="off"
										track={false}
										step={0.25}
										marks={((min: number, max: number, step: number) =>
											Array.from(new Array((max - min) / step + 1)).map((v, i) => ({
												value: min + i * step,
												label: i % 2 ? undefined : min + i * step + 'x',
											})))(0.5, 3, 0.25)}
										min={0.5}
										max={3}
										valueLabelFormat={(value: number) => value + 'x'}
										onChangeCommitted={async (event, value) => {
											if (value !== curSpeed) {
												curSpeed = value as number;
												updateAudioPlaybackSpeed(curSpeed);
												AudioPlaybackInstance().setPlaybackSpeed(curSpeed);
											}

											await timeout(20);
											toggleDrawer(undefined, false);
										}}
									/>
								),
								icon: Icons.DoubleArrow,
							},
					  ]
					: []
			}
		>
			<Box sx={isLandscape ? classes.vertical : classes.root(fullScreen, isLandscape)}>{isLandscape ? landscapeSwiper : portraitSwiper}</Box>
		</Page>
	);
};

function flattenBooklet(pages: BookletPage[], booklets: BookletsById, flatPages: BookletPage[] = []) {
	pages = pages.sort((a, b) => a.order - b.order);
	for (let i = 0; i < pages.length; i++) {
		let page = pages[i];
		page.flatIndex = flatPages.length;
		if (page.pageCollectionType === 'booklets') {
			let bookletId = page.pageRefId;
			let sectionPages = booklets[bookletId]?.pages || [];
			flatPages = flattenBooklet(sectionPages, booklets, flatPages);
		} else {
			flatPages.push(page);
		}
	}

	return flatPages;
}

interface Config {
	configKey: string;
}

interface BookletId {
	bookletId: string;
}

function mapStateToProps(state: ApplicationState, props: Config | BookletId | { isEmbed?: boolean; style?: any }) {
	let bookletId = (props as BookletId).bookletId;
	if (!bookletId) {
		let configs = state.dataState.configs.byId; //getDataById(state, 'configs') || {};
		bookletId = configs[(props as Config).configKey]?.value || '';
	}
	let booklets = state.dataState.booklets.byId || {};
	let booklet = booklets[bookletId] || {};

	let articles = state.dataState.articles.byId || {};
	let flatPages = flattenBooklet(booklet?.pages || [], booklets);

	let imageArticles: Article[] = [];
	for (let page of flatPages) {
		let collection = page.pageCollectionType;
		let refId = page.pageRefId;

		if (collection === 'articles') {
			let article = articles[refId];
			if (article.mediaType === 'image') {
				let url = getContentStorageUrl(ContentType.Article, article.articleType, article);
				imageArticles.push(article);
			}
		}
	}

	let currentIndex = state.uxState.bookletCurrentIndex[booklet.id] || 0;

	return {
		booklet,
		articles: imageArticles,
		flatPages,
		currentIndex,
		isFullScreen: state.tempState.isFullScreen ?? false,
		locale: state.uxState.locale,
		audioPlaybackState: state.tempState.audioPlaybackState,
		audioPlaybackDetails: state.tempState.audioPlaybackDetails,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateBookletIndex: (bookletId: string, index: number) => {
			dispatch(updateBookletIndex(bookletId, index));
		},
		updateFullScreen: (enabled: boolean) => {
			dispatch(updateFullScreen(enabled));
		},
		toggleDrawer: (content?: any, open?: boolean) => {
			dispatch(toggleBottomDrawer(content, open));
		},
		updateAudioPlaybackSpeed: (speed: number) => {
			dispatch(updateAudioPlaybackSpeed(speed));
		},
	};
}

export const AlbumControl = connect(mapStateToProps, mapDispatchToProps)(Component);
