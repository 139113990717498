import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import { TimerRunningControl } from './TimerRunningControl';

const classes = {
	root: (props) => ({
		display: props.enabled ? 'block' : 'none',
		backgroundColor: 'rgba(50, 183, 140, 0.15)',
		py: 1,
		px: 2,
	}),
};

interface Props {
	enabled: boolean;
}

function miniTimerControl(props: Props) {
	return (
		<Box component="div" sx={classes.root(props)}>
			<TimerRunningControl sx={{}} />
		</Box>
	);
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export default connect(mapStateToProps)(miniTimerControl);
