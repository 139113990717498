import { ReduxRouterState } from '@lagunovsky/redux-react-router';
import { DefaultUserSettings, OrgName } from 'config';
import { ApplicationState, AudioCurrentState, ConfigsById, ConfigStore } from 'types';
import { ConfigDump } from './Configs';
import { ArticlesDump } from './Articles';
import AnandDate from 'helpers/AnandDate';

export const initialState: ApplicationState = {
	router: undefined as any as ReduxRouterState,
	dataState: {
		articles: {
			byId: ArticlesDump,
		},
		rhymes: {
			byId: {},
		},
		publications: {
			byId: {},
		},
		editions: {
			byId: {},
		},
		booklets: {
			byId: {},
		},
		orders: {
			byId: {},
		},
		configs: { byId: ConfigDump },
		notifications: { byId: {} },
		quotes: { byId: {} },
		features: { byId: {} },
		hd: { byId: {} },
		sandesh: { byId: {} },
		cart: { items: {} },
	},
	userState: {
		userStore: {},
	},
	filteredDataState: {
		articles: [],
		quotes: [],
		rhymes: [],
		publications: [],
		editions: [],
		booklets: [],
		notifications: [],
		features: [],
		hd: [],
		orders: [],
		sandesh: [],
	},
	uxState: {
		locale: 'hi',
		bookletCurrentIndex: {},
		pageMarker: {},
		activeNotifications: 0,
		settings: DefaultUserSettings,
		appParams: { appId: '', appEnv: '', appVersion: -1, appBuildCode: -1, webappBuildVersion: 0 },
		sort: {},
		filters: {},
		acknowledge: {},
		timerPresets: [],
		timerMusic: false,
		showDhyanOnHome: true,
	},
	tempState: {
		isLoading: 0,
		isDrawerOpen: false,
		bottomDrawer: { open: false },
		alertDialog: { open: false },
		onDemandPage: { open: false },
		appBarTitle: OrgName,
		netStatus: 'online',
		audioPlaybackDetails: null,
		audioPlaybackState: {
			currentState: AudioCurrentState.Initializing,
			duration: undefined,
			currentTime: 0,
			repeat: 0,
			isMiniPlayerMode: false,
		},
		notifications: [],
		searchTerm: '',
		showAdminPreview: true,
		isFullScreen: false,
		today: new AnandDate().setHours(0, 0, 0),
	},
	opsState: { byId: {} },
	offlineState: { audio: {}, playedMedia: {}, timerLogs: {} },
};
