import App from 'AppInterface/App';
import DialogForResult from 'components/shell/DialogForResult';
import { logEvent, setUserProperty } from 'components/telemetry';
import { DBConfig, DefaultUserSettings, IsMinimalRhymeSite, IsOwnerSAPD, SiteOwner } from 'config';
import { fireAuth, fireDB } from 'fb/initconfig';
import 'firebase/auth';
import {
	ConfirmationResult,
	EmailAuthProvider,
	fetchSignInMethodsForEmail,
	isSignInWithEmailLink,
	linkWithCredential,
	PhoneAuthProvider,
	reauthenticateWithCredential,
	RecaptchaVerifier,
	signInWithCredential,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signInWithEmailLink,
	signInWithPhoneNumber,
	updateEmail,
	updatePassword,
	User,
} from 'firebase/auth';
import 'firebase/firestore';
import {
	addDoc,
	collection,
	deleteField,
	doc,
	DocumentChange,
	DocumentData,
	getDoc,
	onSnapshot,
	runTransaction,
	serverTimestamp,
	setDoc,
	Timestamp,
	Unsubscribe,
} from 'firebase/firestore';
import { getBuildVersion, isAdminOrTest, isAndroid, isFakeWebViewEnv, isWebViewEnv, timeout } from 'helpers';
import { Context } from 'helpers/context';
import { ThunkDispatch } from 'redux-thunk';
import { appInterface, modulesInit, store } from 'store';
import { updateConfigs } from 'store/data/configs/actions';
import { clearOrders } from 'store/data/orders/actions';
import { updateAllPlayedMedia } from 'store/data/played/actions';
import { updateAllTimerLogs } from 'store/data/timer/actions';
import { updateUser, updateUserData } from 'store/data/user/actions';
import { enqueueSnackbar, EventType, onEvent, toggleOnDemandPage } from 'store/temp/actions';
import { updateUserSettings } from 'store/ux/actions';
import { ApplicationState, PinnedRecord, TimerLogs, UserData } from 'types';
import { v1 as uuidv1 } from 'uuid';
import { triggerFunction } from './functions';
import { PlaylistsFireStoreModule } from './playlists';
import { SandeshFireStoreModule } from './sandesh';

const setUserRecord = async (userId, userData, merge) => {
	let db = fireDB;

	await setDoc(doc(collection(db, 'users'), userId), userData, { merge: merge });
};

export const getUserRoles = async () => {
	let idTokenResult = await fireAuth.currentUser?.getIdTokenResult();

	let roles: string[] = [];
	if (idTokenResult) {
		if (!!idTokenResult.claims.admin) {
			roles.push('admin');
			setUserProperty('admin', true);
		} else {
			setUserProperty('admin', false);
		}
		if (!!idTokenResult.claims.editor) {
			roles.push('editor');
			setUserProperty('editor', true);
		} else {
			setUserProperty('editor', false);
		}
		if (!!idTokenResult.claims.test) {
			roles.push('test');
			setUserProperty('test', true);
		} else {
			setUserProperty('test', false);
		}
		if (!!idTokenResult.claims.rhymeadmin) {
			roles.push('rhymeadmin');
			setUserProperty('rhymeadmin', true);
		} else {
			setUserProperty('rhymeadmin', false);
		}
	}

	return roles;
};

const verifyUserEmail = async (user) => {
	try {
		if (user.email && window.location.pathname.indexOf('finishEmailLink') < 0) {
			store.dispatch(
				enqueueSnackbar({
					message: `Verify your email by clicking on the link sent to your email address ${user.email}`,
					options: {
						persist: true,
					},
				})
			);
			// await user.sendEmailVerification();
			// alert('Please confirm your email');
		}
	} catch (error) {
		throw error;
	}
};

const updateUserState = async (user, updateData: boolean, createData?: boolean) => {
	if (!user) {
		store.dispatch(updateUser(undefined));
		store.dispatch(updateUserData(undefined));
		store.dispatch(clearOrders());
	} else {
		store.dispatch(updateUser(user));
		if (updateData) {
			let db = fireDB;
			let userDataRecord = await getDoc(doc(collection(db, 'users'), user.uid));
			let userData = userDataRecord?.data() as UserData;
			if (!userData && createData) {
				userData = {
					verified: user.emailVerified,
					fullName: user.displayName,
					photoUrl: user.photoURL,
					settings: DefaultUserSettings,
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
				} as unknown as UserData;

				if (user.email) {
					userData['email'] = user.email;
				}
				if (user.phoneNumber) {
					userData['phone'] = user.phoneNumber;
				}

				await setUserRecord(user.uid, userData, false);
			} else if (userData) {
				if (
					(user.email && (!userData.email || user.email !== userData.email)) ||
					(user.displayName && !userData.fullName) ||
					(user.photoURL && !userData.photoUrl) ||
					(user.phoneNumber && (!userData.phone || user.phoneNumber !== userData.phone))
				) {
					userData = {
						fullName: userData.fullName || user.displayName,
						photoUrl: userData.photoUrl || user.photoURL,
						updatedAt: serverTimestamp(),
					} as UserData;

					if (user.email || userData.email) {
						userData['email'] = user.email || userData.email;
					}
					if (user.phoneNumber || userData.phone) {
						userData['phone'] = user.phoneNumber || userData.phone;
					}

					await setUserRecord(user.uid, userData, true);
				}
			}

			if (userData) {
				userData.roles = await getUserRoles();
				store.dispatch(updateUserData(userData));
				store.dispatch(updateUserSettings(userData.settings, true));
			}

			if (isWebViewEnv()) {
				try {
					let appDetails = await App.getAppDetailesJson();
					if (typeof appDetails === 'string') {
						appDetails = JSON.parse(appDetails);
					}

					appDetails.webAppBuildVersion = getBuildVersion();
					let deviceId = appDetails.deviceRegisteredId;

					let key = 'synced_apptoken_' + user.uid + '_' + deviceId;
					let syncedToken = localStorage.getItem(key);
					let token = await App.getFromAppStorage('firebase_cloud_messaging_token');

					if (token && syncedToken === '1') {
						syncedToken = token;
						localStorage.setItem(key, token);
					}
					if (token && syncedToken !== token) {
						if (token) {
							if (typeof token === 'object') {
								token = token['value'];
							}

							let dispatch = store.dispatch as ThunkDispatch<ApplicationState, any, any>;
							await dispatch(
								updateAppToken({
									deviceId,
									token,
									appDetails,
									app: isAndroid() ? 'Android' : 'iOS',
								})
							);

							localStorage.setItem(key, token);
						}
					}
				} catch (error) {
					console.error(error);
				}
			}
		}
	}
};

let userModules: { [key: string]: { instance: any; module: any; shallSubscribe?: (user: User) => boolean } } = {
	// playlist: { instance: null, module: PlaylistsFireStoreModule },
	sandesh: {
		instance: null,
		module: SandeshFireStoreModule,
		shallSubscribe: (user: User) => {
			return false;

			if (!user.phoneNumber) {
				return false;
			}

			let state = store.getState() as ApplicationState;
			// let userData = state.userState.userStore.userData;
			// let refs = userData?.refUserIds;
			// if (!refs || !refs.length) {
			// 	return false;
			// }
			let SandeshDates = state.dataState.configs.byId[DBConfig.Sandesh]?.value;
			if (!SandeshDates) {
				return false;
			}
			let now = new Date().getTime() / 1000;
			let start: Timestamp = SandeshDates.enableAt;
			let end: Timestamp = SandeshDates.disableAt;
			if ((start && now < start.seconds) || (end && now > end.seconds)) {
				return false;
			}
			return true;
		},
	},
	// address: { instance: null, module: AddressesFireStoreModule },
	// order: { instance: null, module: OrdersFireStoreModule },
};

async function subscribeToUserModules(user) {
	if (IsMinimalRhymeSite) {
		return;
	}

	await unsubscribeFromUserModules();
	await timeout(1000);

	let instances: any[] = [];
	for (let moduleKey in userModules) {
		let moduleObj = userModules[moduleKey];
		if (!moduleObj.shallSubscribe || moduleObj.shallSubscribe(user)) {
			let moduleCls = moduleObj.module;

			moduleObj.instance = new moduleCls(user);
			instances.push(moduleObj.instance);
		}
	}

	await timeout(5000);
	modulesInit.initModules(instances);
}

async function unsubscribeFromUserModules() {
	if (IsMinimalRhymeSite) {
		return;
	}

	for (let moduleKey in userModules) {
		let moduleObj = userModules[moduleKey];

		if (moduleObj.instance) {
			await modulesInit.unsubscribeModule(moduleObj.instance);
			moduleObj.instance = null;
		}
	}
}

let unsubscribeFromUserData: Unsubscribe | null;

export const updateFBConfigs = (changes: DocumentChange<DocumentData>[]) => {
	return async (dispatch, getState) => {
		try {
			// let state = getState() as ApplicationState;
			// let userStore = state.userState.userStore;

			// let user = userStore.user;
			// if (user) {
			// 	subscribeToUserModules(user);
			// }

			dispatch(updateConfigs(changes));
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
};

fireAuth.onAuthStateChanged(async (user) => {
	if (user) {
		if (user.email && !user.emailVerified) {
			verifyUserEmail(user);
			return;
		}
		updateUserState(user, true);

		subscribeToUserModules(user);

		if (!unsubscribeFromUserData) {
			unsubscribeFromUserData = onSnapshot(doc(collection(fireDB, 'users'), user.uid), async (snapshot) => {
				let userData = snapshot.data() as UserData;
				if (userData && snapshot.metadata.hasPendingWrites === false) {
					userData.roles = await getUserRoles();
					store.dispatch(updateUserData(userData as UserData));
					store.dispatch(updateUserSettings(userData.settings, true));

					await modulesInit.processModuleChange(new PlaylistsFireStoreModule(user), userData.playlistUpdatedAt ?? Timestamp.fromDate(new Date()));
					let newRefs = (userData?.refUserIds?.length ?? 0) > 0;

					let state = store.getState() as ApplicationState;
					let userStore = state.userState.userStore;
					let prevRefs = (userStore.userData?.refUserIds?.length ?? 0) > 0;

					let hasRefsChanged = (newRefs && !prevRefs) || (prevRefs && !newRefs);

					if (hasRefsChanged) {
						await subscribeToUserModules(user);
						// try {
						// 	console.log(userData.sandeshUpdatedAt);
						// 	await modulesInit.processModuleChange(
						// 		new SandeshFireStoreModule(user),
						// 		userData.sandeshUpdatedAt ?? Timestamp.fromDate(new Date(1))
						// 	);
						// } catch (error) {
						// 	console.log(error);
						// }
					}
				}
			});
			await timeout(200);
		}
	} else {
		updateUserState(undefined, false);

		unsubscribeFromUserModules();

		if (unsubscribeFromUserData) {
			unsubscribeFromUserData();
			unsubscribeFromUserData = null;
		}
	}
});

export const saveExtraUserData = () => {
	return async (dispatch, getState) => {
		try {
			let db = fireDB;
			let user = fireAuth.currentUser;

			if (!user || !(isFakeWebViewEnv() || isAdminOrTest(getState()))) {
				// console.log('Not Signed In for Extra Data!');
				return;
			}

			console.log('Syncing User Data');

			var userdataRef = doc(collection(db, 'userdata'), user.uid);

			let result = await runTransaction(db, async (transaction) => {
				let playedMedia = (getState() as ApplicationState).offlineState.playedMedia;
				let timerLogs: TimerLogs = (getState() as ApplicationState).offlineState.timerLogs;
				let userdataDoc = await transaction.get(userdataRef);
				let userData;
				let userPlayedMedia = {};
				let userTimerLogs: TimerLogs = {};
				let requiresFullUpload = !userdataDoc.exists();
				if (userdataDoc.exists()) {
					userData = userdataDoc.data();
					for (let id in userData) {
						if (id === 'playedMedia') {
							userPlayedMedia = { ...userPlayedMedia, ...userData[id] };
						} else if (id.startsWith('playedMedia.')) {
							userPlayedMedia[id.replace('playedMedia.', '')] = userData[id];
							requiresFullUpload = true;
						} else if (id === 'timerLogs') {
							userTimerLogs = userData[id];
						}
					}
				}

				// console.log('playedMedia', playedMedia, 'userPlayesMediaFromDB', userPlayedMedia);
				// console.log('timerLogs', timerLogs, 'userTimerLogsFromDB', userTimerLogs);

				let requiresUpdate = false;
				let setData = {
					playedMedia: {},
					timerLogs: {},
					updatedAt: serverTimestamp(),
					createdAt: userData?.createdAt ?? serverTimestamp(),
				};

				for (let articleId in playedMedia) {
					let dbRecord = userPlayedMedia[articleId];
					let localRecord = playedMedia[articleId];

					if (!dbRecord) {
						userPlayedMedia[articleId] = localRecord;
						setData.playedMedia[articleId] = userPlayedMedia[articleId];
						requiresUpdate = true;
					} else if ((localRecord.timestamp ?? 0) > (dbRecord.timestamp ?? 0)) {
						userPlayedMedia[articleId] = {
							time: localRecord.time, //Math.max(localRecord.time, dbRecord.time),
							timestamp: localRecord.timestamp || new Date().getTime(), //Math.max(localRecord.timestamp ?? 0, dbRecord.timestamp ?? 0),
						};
						setData.playedMedia[articleId] = userPlayedMedia[articleId];
						requiresUpdate = true;
					}
				}

				for (let startTime in timerLogs) {
					let dbRecord = userTimerLogs[startTime];
					let localRecord = timerLogs[startTime];

					if (localRecord.duration === 0) {
						if (dbRecord) {
							delete userTimerLogs[startTime];
							setData.timerLogs[startTime] = deleteField();
							requiresUpdate = true;
						}
					} else if (!dbRecord || (localRecord.updatedAt ?? 0) > (dbRecord.updatedAt ?? 0)) {
						userTimerLogs[startTime] = localRecord;
						setData.timerLogs[startTime] = userTimerLogs[startTime];
						requiresUpdate = true;
					}
				}

				// if (Object.keys(userPlayedMedia).length || Object.keys(userTimerLogs).length) {
				if (requiresUpdate) {
					// console.log('Uploading playedMedia/timerLogs to DB', userPlayedMedia, userTimerLogs);

					if (requiresFullUpload) {
						transaction.set(userdataRef, {
							playedMedia: userPlayedMedia,
							timerLogs: userTimerLogs,
							updatedAt: serverTimestamp(),
							createdAt: userData?.createdAt ?? serverTimestamp(),
						});
					} else {
						transaction.set(userdataRef, setData, { merge: true });
					}
				}
				return { userPlayedMedia, userTimerLogs };
				// }
			});

			if (result) {
				// console.log('Updating local played-media/timer-logs state', result);
				dispatch(updateAllPlayedMedia(result.userPlayedMedia));
				dispatch(updateAllTimerLogs(result.userTimerLogs));
			}
		} catch (e) {}
	};
};

export interface LoginData {
	email: string;
	password: string;
}

export const login = (data: LoginData) => {
	return async (dispatch, getState) => {
		try {
			let result = await signInWithEmailAndPassword(fireAuth, data.email, data.password);
			var user = result.user;

			if (user) {
				if (!user.emailVerified) {
					// alert('Your email is not verified, please verify');
					return;
				}

				logEvent('login', 'Password');
				updateUserState(user, true);
				await dispatch(saveExtraUserData());
			}
		} catch (error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			console.error('auth: login: ' + errorCode + ': ' + errorMessage, error.stack);
			throw error;
		}
	};
};

const postUserSignUp = async (user, dispatch) => {
	await updateUserState(user, true, true);
	await dispatch(saveExtraUserData());
};

const deleteUser = async (user, dispatch, getState) => {
	try {
		// await triggerFunction('deleteCurrentUser', {});

		// let state: ApplicationState = getState();
		// let playlists = state.userState.userStore.subColl?.playlists ?? {}; //state.dataState.fbUserPlaylists;
		// let db = fireDB;
		// Object.keys(playlists).forEach(async (playlistId) => {
		// 	await deleteDoc(doc(collection(collection(db, 'users'), 'playlists'), playlistId));
		// 	await timeout(100);

		// 	logEvent('user_playlist_delete', { playlistId });
		// });
		// await deleteDoc(doc(collection(db, 'users'), user.uid));

		await user.delete();
		await dispatch(signout({}, new Context()));
		updateUserState(undefined, false);
		logEvent('user_delete', { userId: user.uid });
	} catch (error) {
		throw error;
	}
};

export const deleteByPasswordLogin = (data: { currentPass: string }) => {
	return async (dispatch, getState) => {
		try {
			let currentUser = fireAuth.currentUser;
			if (currentUser && currentUser.email) {
				// let credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, data.currentPass);
				// await currentUser.reauthenticateWithCredential(credential);

				await deleteUser(currentUser, dispatch, getState);

				dispatch(
					onEvent(new Context(), EventType.Information, 'deleteAccount', {
						success: true,
						message: 'Account deleted!',
					})
				);
			} else {
				throw new Error('User not signedin with Email');
			}
		} catch (error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;

			if (errorCode === 'auth/wrong-password') {
				dispatch(
					onEvent(new Context(), EventType.Information, 'deleteAccount', {
						success: false,
						message: 'Wrong Password!',
					})
				);
			} else if (errorCode === 'auth/too-many-requests') {
				dispatch(
					onEvent(new Context(), EventType.Information, 'deleteAccount', {
						success: false,
						message: 'Too many requests! Please try after some time!',
					})
				);
			} else {
				dispatch(
					onEvent(new Context(), EventType.Information, 'deleteAccount', {
						success: false,
						message: errorMessage,
					})
				);
			}
		}
	};
};

export const deleteByPhoneSign = () => {
	return async (dispatch, getState) => {
		try {
			let currentUser = fireAuth.currentUser;
			if (currentUser) {
				await deleteUser(currentUser, dispatch, getState);

				dispatch(
					onEvent(new Context(), EventType.Information, 'deleteAccount', {
						success: true,
						message: 'Account deleted!',
					})
				);
			}
		} catch (error) {
			dispatch(
				onEvent(new Context(), EventType.Information, 'deleteAccount', {
					success: false,
					message: error.message,
				})
			);
		}
	};
};

export const deleteByGoogleOrAppleSignIn = () => {
	return async (dispatch, getState) => {
		let context = new Context();
		try {
			let currentUser = fireAuth.currentUser;

			if (currentUser) {
				await deleteUser(currentUser, dispatch, getState);

				dispatch(
					onEvent(context, EventType.Information, 'deleteAccount', {
						success: true,
						message: 'Account deleted!',
					})
				);
			}
		} catch (error) {
			dispatch(
				onEvent(context, EventType.Information, 'deleteAccount', {
					success: false,
					message: error.message,
				})
			);
		}
	};
};

export const signInWithGoogleOrApple = (isApple?: boolean) => {
	return async (dispatch, getState) => {
		let user: User | null = null;
		try {
			let result;
			if (isApple) {
				result = await appInterface.initiateAppleSignIn();
			} else {
				result = await appInterface.initiateGoogleSignIn();
			}
			user = result.user;
			logEvent('login', isApple ? 'Apple' : 'Google');
		} catch (error) {
			var errorCode = error.code;

			var email = error.email;
			var credential = error.credential;

			if (errorCode === 'auth/account-exists-with-different-credential') {
				let methods = await fetchSignInMethodsForEmail(fireAuth, email);

				if (methods[0] === 'password') {
					dispatch(
						toggleOnDemandPage(
							<DialogForResult
								label="Enter Password"
								inputType="password"
								action={async (password) => {
									let signinResult = await signInWithEmailAndPassword(fireAuth, email, password);
									let linkResult = await linkWithCredential(signinResult?.user, credential);
									await postUserSignUp(linkResult?.user, dispatch);
								}}
							/>
						)
					);
				}
			} else {
				throw error;
			}
		}

		await postUserSignUp(user, dispatch);
	};
};

export interface CustomPhoneLoginData {
	number: string;
	retry?: boolean;
	voice?: boolean;
}

export const triggerCustomPhoneSignIn = (data: CustomPhoneLoginData) => {
	return async (dispatch, getState) => {
		try {
			let sendOTPCount: any = localStorage.getItem('SendOTPCount');
			sendOTPCount = parseInt(sendOTPCount ?? '0');
			let sendOTPTimestamp = parseInt(localStorage.getItem('SendOTPTimestamp') ?? '0');
			let now = new Date().getTime();

			if (now - sendOTPTimestamp < 30 * 60 * 1000) {
				localStorage.setItem('SendOTPCount', sendOTPCount + 1);

				if (sendOTPCount > 5) {
					// eslint-disable-next-line
					throw { code: 'auth/too-many-requests', message: 'Try after sometime' };
				}
			} else {
				localStorage.setItem('SendOTPCount', '1');
			}

			localStorage.setItem('SendOTPTimestamp', now.toString());

			await triggerFunction('sendOTP', data);
		} catch (error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			console.error('auth: login: phone: ' + errorCode + ': ' + errorMessage, error.stack);
			throw error;
		}
	};
};

export interface CustomOTPData {
	number: string;
	OTP: string;
	link?: boolean;
	update?: boolean;
}

export const verifyCustomOTP = (data: CustomOTPData) => {
	return async (dispatch, getState) => {
		try {
			let result = await triggerFunction('sendOTP', { ...data, verify: true });

			if (result.token) {
				let authResult = await signInWithCustomToken(fireAuth, result.token);

				const user = authResult.user;

				if (user) {
					logEvent('login', 'Phone');
					await updateUserState(user, true, true);
					await dispatch(saveExtraUserData());
				}

				return (getState() as ApplicationState).userState.userStore.userData;
			}
		} catch (error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			console.error('auth: login: phone: ' + errorCode + ': ' + errorMessage, error.stack);
			throw error;
		}
	};
};

export interface PhoneLoginData {
	phone: string;
	recaptchaVerifier: RecaptchaVerifier;
}

export const triggerPhoneSignIn = (data: PhoneLoginData) => {
	return async (dispatch, getState) => {
		try {
			// var sendOTP = firebase.app().functions('asia-east2').httpsCallable('sendOTP');
			// let result = await sendOTP({
			// 	number: data.phone,
			// });
			return await signInWithPhoneNumber(fireAuth, data.phone, data.recaptchaVerifier);
		} catch (error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			console.error('auth: login: phone: ' + errorCode + ': ' + errorMessage, error.stack);
			throw error;
		}
	};
};

// const mergeUserAccounts = async (
// 	credential: AuthCredential,
// 	targetUser: User,
// 	userToBeDeleted: User,
// 	dispatch,
// 	getState
// ) => {
// 	let db = fireDB;

// 	let targetPlaylistsSnap = await getDocs(collection(doc(collection(db, 'users'), targetUser.uid), 'playlists'));
// 	let targetPlaylists = targetPlaylistsSnap.docs;

// 	let tbdPlaylistsSnap = await getDocs(collection(doc(collection(db, 'users'), userToBeDeleted.uid), 'playlists'));
// 	let tbdPlaylists = tbdPlaylistsSnap.docs;

// 	await deleteUser(userToBeDeleted, dispatch, getState);

// 	let linkResult = await linkWithCredential(targetUser, credential);

// 	if (!tbdPlaylistsSnap.empty) {
// 		for (let i = 0; i < tbdPlaylists.length; i++) {
// 			let tbdPlaylist = tbdPlaylists[i];

// 			let targetPlaylist = targetPlaylists.find((doc) => doc.id === tbdPlaylist.id);
// 			if (targetPlaylist?.exists) {
// 				if (tbdPlaylist.data().tracks && Object.keys(tbdPlaylist.data().tracks).length) {
// 					await setDoc(
// 						doc(collection(doc(collection(db, 'users'), targetUser!.uid), 'playlists'), tbdPlaylist.id),
// 						{
// 							tracks: {
// 								...tbdPlaylist.data().tracks,
// 								...targetPlaylist.data().tracks,
// 							},
// 							updatedAt: serverTimestamp(),
// 						},
// 						{ merge: true }
// 					);
// 				}
// 			} else {
// 				await updateDoc(
// 					doc(collection(doc(collection(db, 'users'), targetUser!.uid), 'playlists'), tbdPlaylist.id),
// 					tbdPlaylist.data()
// 				);
// 			}
// 		}
// 	}

// 	if (linkResult && linkResult.user) {
// 		// credential = linkResult.user;
// 	}

// 	logEvent('login', 'User Accounts Link Completed');

// 	return credential;
// };

const linkAccountWithPhone = async (prevUser, credential, link, dispatch, getState) => {
	// if (prevUser.phoneNumber === null || !prevUser.phoneNumber.length) {
	try {
		let linkResult = await prevUser.linkWithCredential(credential);

		if (linkResult && linkResult.credential) {
			credential = linkResult.credential;
		}
	} catch (error) {
		if (error.code === 'auth/provider-already-linked') {
			await prevUser.unlink(PhoneAuthProvider.PROVIDER_ID);
			logEvent('login', 'Phone Unlinked');

			return await linkAccountWithPhone(prevUser, credential, link, dispatch, getState);
		} else if (link && error.code === 'auth/credential-already-in-use') {
			logEvent('login', 'User Accounts Link Started');
			try {
				let result = await signInWithCredential(fireAuth, credential);
				if (result) {
					let linkResult = await prevUser.linkWithCredential(result);
					if (linkResult && linkResult.credential) {
						credential = linkResult.credential;
					}
					// } else if (result.user) {
					// 	credential = await mergeUserAccounts(credential, prevUser, result.user, dispatch, getState);
				}
			} catch (error) {
				throw error;
			}
		} else {
			throw error;
		}
	}
	// } else {
	// 	await prevUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID);
	// 	logEvent('login', 'Phone Unlinked');
	// 	// eslint-disable-next-line
	// 	throw {
	// 		code: 'already-linked-with-a-phone',
	// 		message: 'User account is already linked with a phone. It has been un-linked now!',
	// 	};
	// }

	return credential;
};

export const phoneSignIn = (code: string, confirmationResult: ConfirmationResult, link?: boolean, reauth?: boolean) => {
	return async (dispatch, getState) => {
		try {
			let prevUser = fireAuth.currentUser;
			let credential = PhoneAuthProvider.credential(confirmationResult.verificationId, code);

			if (prevUser) {
				if (reauth === true) {
					await reauthenticateWithCredential(prevUser, credential);
					return;
				} else {
					credential = await linkAccountWithPhone(prevUser, credential, link, dispatch, getState);
				}
			}

			let result = await signInWithCredential(fireAuth, credential);
			const user = result.user;

			if (user) {
				logEvent('login', 'Phone');
				await updateUserState(user, true, true);
				await dispatch(saveExtraUserData());
			}

			return (getState() as ApplicationState).userState.userStore.userData;
		} catch (error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			console.error('auth: login: phone: ' + errorCode + ': ' + errorMessage, error.stack);
			throw error;
		}
	};
};

export interface PasswordResetData {
	email: string;
}

export const sendResetPasswordEmail = (data: PasswordResetData) => {
	return async (dispatch, getState) => {
		try {
			await addDoc(collection(fireDB, 'messages'), {
				action: 'resetpass',
				email: data.email,
				actionCodeUrl: window.location.origin + '/account',
				createdAt: serverTimestamp(),
			});

			logEvent('user_resetpass', { email: data.email });

			// let actionCodeSettings = {
			// 	// URL you want to redirect back to. The domain (www.example.com) for this
			// 	// URL must be whitelisted in the Firebase Console.
			// 	url: window.location.origin + '/account',
			// 	// This must be true.
			// 	handleCodeInApp: true,
			// 	// iOS: {
			// 	// 	bundleId: 'com.example.ios',
			// 	// },
			// 	// android: {
			// 	// 	packageName: 'com.example.android',
			// 	// 	installApp: true,
			// 	// 	minimumVersion: '12',
			// 	// },
			// 	// dynamicLinkDomain: 'example.page.link',
			// };

			// await firebase.auth().sendPasswordResetEmail(data.email, actionCodeSettings);
		} catch (error) {
			throw error;
		}
	};
};

export interface ChangePasswordData {
	current: string;
	password: string;
}

export const changePassword = (data: ChangePasswordData) => {
	return async (dispatch, getState) => {
		try {
			let currentUser = fireAuth.currentUser;
			if (currentUser && currentUser.email) {
				let credential = EmailAuthProvider.credential(currentUser.email, data.current);
				await reauthenticateWithCredential(currentUser, credential);
				await updatePassword(currentUser, data.password);
			} else {
				throw new Error('User not signedin with Email');
			}
		} catch (error) {
			throw error;
		}
	};
};

export const verifyEmailLinkUpdate = (email: string, password: string) => {
	return async (dispatch, getState) => {
		try {
			let auth = fireAuth;
			// let currentUser = firebase.auth().currentUser;
			// if (!currentUser) {
			// 	throw 'User not Signed In';
			// }

			// if (currentUser && currentUser.email && password) {
			// 	let credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
			// 	await currentUser.reauthenticateWithCredential(credential);
			// }

			// let result = currentUser.updateEmail(email);

			if (isSignInWithEmailLink(auth, window.location.href)) {
				await signInWithEmailLink(auth, email, window.location.href);
				let user = auth.currentUser;

				if (!user) {
					throw 'SignIn Failed'; // eslint-disable-line no-throw-literal
				}
				await updatePassword(user, password);

				logEvent('user_emailupdatecomplete', { email: email });

				await postUserSignUp(user, dispatch);

				dispatch(
					onEvent(new Context(), EventType.Information, 'email', {
						success: true,
						message: 'Email Update Successful!',
					})
				);
			}
		} catch (error) {
			dispatch(
				onEvent(new Context(), EventType.Information, 'email', {
					success: false,
					message: error.message,
				})
			);
		}
	};
};

export interface SignupData {
	email: string;
	password: string;
}

export const verifyEmailLinkSignup = (data: SignupData) => {
	return async (dispatch, getState) => {
		try {
			let auth = fireAuth;

			if (isSignInWithEmailLink(auth, window.location.href)) {
				await signInWithEmailLink(auth, data.email, window.location.href);
				let user = auth.currentUser;
				if (!user) {
					throw 'SignUp Failed'; // eslint-disable-line no-throw-literal
				}
				await updatePassword(user, data.password);

				logEvent('user_emailsignupcomplete', { email: data.email });
				logEvent('sign_up', 'Password');

				await postUserSignUp(user, dispatch);

				dispatch(
					onEvent(new Context(), EventType.Information, 'signup', {
						success: true,
						message: 'SignUp Successful!',
					})
				);
			}
		} catch (error) {
			dispatch(
				onEvent(new Context(), EventType.Information, 'signup', {
					success: false,
					message: error.message,
				})
			);
		}
	};
};

export interface SignupWithEmailLinkData {
	email: string;
}

export const signupWithEmailLink = (data: SignupWithEmailLinkData) => {
	return async (dispatch, getState) => {
		try {
			await addDoc(collection(fireDB, 'messages'), {
				action: 'signup',
				email: data.email,
				actionCodeUrl: window.location.origin + '/finishEmailLinkSignUp',
				createdAt: serverTimestamp(),
			});
			logEvent('user_emailsignup', { email: data.email });
		} catch (error) {
			throw error;
		}
	};
};

export interface SignOutData {}

export const signout = (data: SignOutData, context: Context) => {
	return async (dispatch) => {
		try {
			await dispatch(saveExtraUserData());

			let currentUser = fireAuth.currentUser;
			if (currentUser && currentUser.providerData.map((provider) => provider?.providerId).indexOf('google.com') >= 0) {
				App.gSignout();
			} else if (currentUser && currentUser.providerData.map((provider) => provider?.providerId).indexOf('apple.com') >= 0) {
				App.appleSignout();
			}

			await fireAuth.signOut();
			updateUserState(undefined, false);

			await dispatch(updateAllPlayedMedia({}));
			await dispatch(updateAllTimerLogs({}));
		} catch (error) {
			dispatch(onEvent(context, EventType.Exception, 'signout', error));
		}
	};
};

export const toggleRecordPin = (id: string, type: string) => {
	return async (dispatch, getState) => {
		let userStore = (getState() as ApplicationState).userState.userStore;
		let pinnedRecords = userStore.userData?.pinnedRecords ?? {};

		if (pinnedRecords[id]) {
			pinnedRecords[id] = deleteField() as any;
		} else {
			pinnedRecords[id] = { type: type, pintime: new Date().getTime() };
		}

		await dispatch(updateProfile({ pinnedRecords }, new Context()));
	};
};

export interface ProfileData {
	fullName?: string;
	gender?: string;
	phone?: string;
	dob?: string;
	rahbar?: string;
	settings?: { [key: string]: any };
	photoUrl?: string;
	pinnedRecords?: { [id: string]: PinnedRecord };
}

export const updateProfile = (data: ProfileData, context: Context) => {
	return async (dispatch, getState) => {
		try {
			dispatch(onEvent(context, EventType.OperationStart, 'updateProfile', data));

			let userStore = getState().userState.userStore;
			let user = userStore.user;
			if (!user) {
				dispatch(onEvent(context, EventType.AssertionFailure, 'updateProfile', 'User Not Signed In'));
				dispatch(onEvent(context, EventType.OperationEnd, 'updateProfile', { success: false }));
				return;
			}

			let db = fireDB;
			let userRecord = {};
			Object.keys(data).forEach((key) => {
				if (data[key]) {
					userRecord[key] = data[key];
				}
			});

			userRecord['updatedAt'] = serverTimestamp();

			await setUserRecord(user.uid, userRecord, true);

			logEvent('user_profile_update', {
				...userRecord,
				settings: JSON.stringify(data.settings),
				updatedAt: new Date().toUTCString(),
			});

			if (data.settings) {
				let group: string[] = [];
				if (data.settings.push) {
					group.push('push');
				}
				if (data.settings.quote) {
					group.push('quote');
				}

				if (data.settings.email) {
					group.push('email');
					if (data.settings.email.pubs) {
						let pubIds = Object.keys(data.settings.email.pubs).sort();
						pubIds.forEach((id) => data.settings?.email.pubs[id] && group.push(id));
					}
				}
				logEvent('join_group', group.join(','));
			}

			let userData = (await getDoc(doc(collection(db, 'users'), user.uid))).data();

			dispatch(updateUserData({ ...userStore.userData, ...userData }));

			// dispatch(
			// 	onEvent(context, EventType.OperationEnd, 'updateProfile', {
			// 		success: true,
			// 		message: 'Profile updated',
			// 	})
			// );
		} catch (error) {
			dispatch(onEvent(context, EventType.Exception, 'submitFeedback', error));
		}
	};
};

export const updateEmailAddress = (email: string, password?: string) => {
	return async (dispatch, getState) => {
		try {
			let currentUser = fireAuth.currentUser;
			if (!currentUser) {
				throw 'User not Signed In'; // eslint-disable-line no-throw-literal
			}

			if (currentUser && currentUser.email && password) {
				let credential = EmailAuthProvider.credential(currentUser.email, password);
				await reauthenticateWithCredential(currentUser, credential);
			}

			updateEmail(currentUser, email);

			await addDoc(collection(fireDB, 'messages'), {
				action: 'signup',
				email: email,
				actionCodeUrl: window.location.origin + '/finishEmailLinkUpdate',
				createdAt: serverTimestamp(),
			});
		} catch (e) {
			throw e;
		}
	};
};

export interface FeedbackData {
	name: string;
	category: string;
	comments: string;
	data?: string;
	email?: string;
}

export const submitFeedback = (data: FeedbackData, context: Context) => {
	return async (dispatch, getState) => {
		try {
			dispatch(onEvent(context, EventType.OperationStart, 'submitFeedback', data));

			let state = getState() as ApplicationState;
			let userStore = state.userState.userStore;

			let user = userStore.user;
			if (!user) {
				dispatch(onEvent(context, EventType.AssertionFailure, 'submitFeedback', 'User Not Signed In'));
				dispatch(onEvent(context, EventType.OperationEnd, 'submitFeedback', { success: false }));
				return;
			}

			if (!userStore.userData?.fullName && data.name) {
				await updateProfile({ fullName: data.name }, context);
			}

			let record = {
				...data,
				...state.uxState.appParams,
				email: user.email ?? data.email,
				phone: user.phoneNumber,
				createdAt: serverTimestamp(),
			};

			if (!IsOwnerSAPD) {
				record['owner'] = SiteOwner;
			}

			await setDoc(
				doc(collection(fireDB, 'feedback'), user.uid),
				{
					['f_' + uuidv1()]: record,
					updatedAt: serverTimestamp(),
				},
				{ merge: true }
			);

			dispatch(
				onEvent(context, EventType.OperationEnd, 'submitFeedback', {
					success: true,
					message: 'Feedback submitted',
				})
			);
		} catch (error) {
			dispatch(onEvent(context, EventType.Exception, 'submitFeedback', error));
		}
	};
};

export interface AppNotificationData {
	token: string;
	app: string;
	appDetails: any;
	deviceId: string;
}

export const updateAppToken = (data: AppNotificationData) => {
	return async (dispatch, getState) => {
		let state = getState() as ApplicationState;
		let userStore = state.userState.userStore;

		let user = userStore.user;
		if (!user) {
			return;
		}

		await setDoc(
			doc(collection(fireDB, 'apptokens'), data.deviceId),
			{
				userId: user.uid,
				phone: user.phoneNumber,
				token: data.token,
				app: data.app,
				appDetails: data.appDetails,
				updatedAt: serverTimestamp(),
			},
			{ merge: true }
		);
	};
};
