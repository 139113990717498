import { getSessionId } from 'helpers';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { concatMap, delay, filter, mergeMap, takeUntil } from 'rxjs/operators';
import {
	MULTI_ACTION,
	PerformOperationAtInterval,
	PerformOperationDaily,
	PerformOperationOnce,
	PerformOperationOnceInSession,
	PERFORM_OPS_AT_INT,
	PERFORM_OPS_DAILY,
	PERFORM_OPS_ONCE,
	PERFORM_OPS_ONCE_SESSION,
	StopOperation,
	STOP_OPS,
	updateOperation,
} from 'store/data/ops/actions';
import { ApplicationState } from 'types';

let randomMaxDelay = 5 * 1000;

export let OPS_QUEUE = '@@ops/OPS_QUEUE';

export const queueOps = (key: string, action: any) => {
	return {
		type: OPS_QUEUE,
		key,
		action,
	};
};

let isDialogOpen = () => {
	if (
		[
			'/notices',
			'/signin',
			'/feature',
			'/phonesignin',
			'/finishEmailLinkSignUp',
			'/contact',
			'/feedback',
			'/terms',
			'/privacy',
			'/dailyquote',
			'/account',
		].indexOf(window.location.pathname) >= 0 ||
		window.location.pathname.startsWith('/s/') ||
		window.location.pathname.startsWith('/account/') ||
		window.location.pathname.startsWith('/publication/')
	) {
		return true;
	}
	return false;
};

let opsQueue = (action$, state$) =>
	action$.pipe(
		ofType(OPS_QUEUE),
		concatMap((action: any) => {
			if (action.action.action) {
				if (isDialogOpen()) {
					return of({ ...action.action, performNow: false }).pipe(delay(10000));
				} else {
					return of({ ...action.action, performNow: true }).pipe(delay(randomMaxDelay));
				}
			} else {
				return action.action.pipe(delay(randomMaxDelay));
			}
		})
	);

let multiActions = (action$, state$) =>
	action$.pipe(
		ofType(MULTI_ACTION),
		concatMap((action: any) => {
			if (action.actions && action.actions.length) {
				return concat(...action.actions.map((action) => of(action)));
			} else {
				return EMPTY;
			}
		})
	);

let sessionId = getSessionId();

let session = (action$, state$) =>
	action$.pipe(
		ofType(PERFORM_OPS_ONCE_SESSION),
		mergeMap((action: PerformOperationOnceInSession) => {
			let key = action.key;
			let opsAction = action.action;
			let state: ApplicationState = state$.value;
			let now = new Date();

			let until = takeUntil(
				action$.pipe(
					ofType(STOP_OPS),
					filter((act: StopOperation) => act.key === key)
				)
			);

			if ((!state.opsState.byId[key] || state.opsState.byId[key].value !== sessionId) && (!action.condition || action.condition())) {
				if (action.performNow === true && !isDialogOpen()) {
					return concat(of(opsAction), of(updateOperation({ key: key, value: sessionId, createdAt: now, updatedAt: now })));
				}

				return of(queueOps(key, action)).pipe(until);
			} else {
				return EMPTY;
			}
		})
	);

let once = (action$, state$) =>
	action$.pipe(
		ofType(PERFORM_OPS_ONCE),
		mergeMap((action: PerformOperationOnce) => {
			let key = action.key;
			let opsAction = action.action;
			let state: ApplicationState = state$.value;
			let now = new Date();

			let until = takeUntil(
				action$.pipe(
					ofType(STOP_OPS),
					filter((act: StopOperation) => act.key === key)
				)
			);

			if (!state.opsState.byId[key]) {
				if (action.performNow === true && !isDialogOpen()) {
					return concat(of(opsAction), of(updateOperation({ key: key, value: sessionId, createdAt: now, updatedAt: now })));
				}

				return of(queueOps(key, action)).pipe(until);
			} else {
				return EMPTY;
			}
		})
	);

let daily = (action$, state$) =>
	action$.pipe(
		ofType(PERFORM_OPS_DAILY),
		mergeMap((action: PerformOperationDaily) => {
			let key = action.key;
			let opsAction = action.action;
			let state: ApplicationState = state$.value;
			let midnight = new Date(new Date().toDateString());
			let nextMidnight = new Date(new Date().toDateString());
			nextMidnight.setTime(nextMidnight.getTime() + 24 * 60 * 60 * 1000);

			let now = new Date();
			let updatedAt = state.opsState.byId[key] ? new Date(state.opsState.byId[key].updatedAt) : now;

			let until = takeUntil(
				action$.pipe(
					ofType(STOP_OPS),
					filter((act: StopOperation) => act.key === key)
				)
			);

			if ((!state.opsState.byId[key] || updatedAt < midnight) && (!action.condition || action.condition())) {
				if (action.exactTime === true || (action.performNow === true && !isDialogOpen())) {
					return concat(
						of(opsAction),
						of(
							updateOperation({
								key: key,
								value: sessionId,
								createdAt: state.opsState.byId[key] ? new Date(state.opsState.byId[key].createdAt) : now,
								updatedAt: now,
							})
						),
						of({ ...action, performNow: false }).pipe(delay(nextMidnight), until)
					);
				}

				return of(queueOps(key, action)).pipe(until);
			} else {
				return of(action).pipe(delay(nextMidnight), until);
			}
		})
	);

let interval = (action$, state$) =>
	action$.pipe(
		ofType(PERFORM_OPS_AT_INT),
		mergeMap((action: PerformOperationAtInterval) => {
			let key = action.key;
			let opsAction = action.action;
			let state: ApplicationState = state$.value;
			let interval = action.interval;
			let now = new Date();

			let performAt = state.opsState.byId[key] ? new Date(new Date(state.opsState.byId[key].updatedAt).getTime() + interval * 1000) : now;

			let until = takeUntil(
				action$.pipe(
					ofType(STOP_OPS),
					filter((act: StopOperation) => act.key === key)
				)
			);

			if (!state.opsState.byId[key] || performAt < now) {
				if (action.performNow === true && !isDialogOpen()) {
					return concat(
						of(opsAction),
						of(
							updateOperation({
								key: key,
								value: sessionId,
								createdAt: state.opsState.byId[key]?.createdAt ?? now,
								updatedAt: now,
							})
						),
						of({ ...action, performNow: false }).pipe(delay(interval * 1000), until)
					);
				}

				return of(queueOps(key, action)).pipe(until);
			} else {
				return of(action).pipe(delay(performAt), until);
			}
		})
	);

export const opsEpic = combineEpics(multiActions, opsQueue, once, session, daily, interval);
