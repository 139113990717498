import { DEFAULT_VERSION } from 'redux-persist/lib/constants';
import type { MigrationManifest, PersistedState } from 'redux-persist/lib/types';

function logDebug(debug?: boolean, msg?: string) {
	if (process.env.NODE_ENV !== 'production' && debug) {
		console.log(msg);
	}
}

export function customCreateMigrate(migrations: MigrationManifest, config?: { debug: boolean; asyncMigrations?: boolean }) {
	let { debug, asyncMigrations } = config || {};
	return function (state: PersistedState, currentVersion: number): Promise<PersistedState> {
		if (!state && currentVersion !== 0) {
			// logDebug(debug, 'redux-persist: no inbound state, skipping migration');
			return Promise.resolve(undefined);
		}

		let inboundVersion: number = state?._persist && state._persist.version !== undefined ? state._persist.version : DEFAULT_VERSION;
		if (inboundVersion === currentVersion) {
			// logDebug(debug, 'redux-persist: versions match, noop migration');
			return Promise.resolve(state);
		}
		if (inboundVersion > currentVersion) {
			if (process.env.NODE_ENV !== 'production') {
				console.error('redux-persist: downgrading version is not supported');
			}
			return Promise.resolve(state);
		}

		let migrationKeys = Object.keys(migrations)
			.map((ver) => parseInt(ver))
			.filter((key) => currentVersion >= key && key > inboundVersion)
			.sort((a, b) => a - b);

		logDebug(debug, 'redux-persist: migrationKeys ' + migrationKeys);

		if (asyncMigrations) {
			// Return Promise chain to run async migrations in sequence. Migration
			// functions can return a Promise (or be async).
			return migrationKeys.reduce((promiseChain, versionKey) => {
				return promiseChain.then((state) => {
					logDebug(debug, `redux-persist: running migration ${versionKey}`);
					return (migrations[versionKey](state) as any as Promise<any>)
						.then((state) => {
							logDebug(debug, `redux-persist: successfully ran migration ${versionKey}`);
							return state;
						})
						.catch((error) => {
							logDebug(debug, `redux-persist: error running migration ${versionKey}: ${error.message}`);
							throw error;
						});
				});
			}, Promise.resolve(state));
		} else {
			try {
				if (state) {
					let migratedState = migrationKeys.reduce((state, versionKey) => {
						logDebug(debug, 'redux-persist: running migration for versionKey ' + versionKey);
						return migrations[versionKey](state);
					}, state as PersistedState);
					return Promise.resolve(migratedState);
				}
			} catch (err) {
				return Promise.reject(err);
			}
		}

		return Promise.resolve(undefined);
	};
}
