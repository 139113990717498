import { AnnouncementConfig, DBConfig, isTestEnv } from 'config';
import { getBuildVersion, isAdminOrTest, isFakeWebViewEnv } from 'helpers';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'store/temp/actions';
import { ApplicationState } from 'types';

interface AnnouncementControlProps {
	title: { [locale: string]: string };
	message: { [locale: string]: string };
	version: number;
	tryUrl: string;

	enqueueSnackbar: (notification: any) => void;
}

const Announcement = (props: AnnouncementControlProps) => {
	let { version, title, message, enqueueSnackbar, tryUrl } = props;

	useEffect(() => {
		let localVersion = parseInt(localStorage.getItem(AnnouncementConfig.key) ?? '0');
		let buildVersion = getBuildVersion();

		if (isFakeWebViewEnv() && !isTestEnv && title && message && localVersion < version && (buildVersion === 0 || buildVersion >= version)) {
			enqueueSnackbar({
				message: 'Announcement',
				options: {
					variant: 'announcement',
					persist: 'persist',
					tryUrl,
					title,
					version,
					msg: message,
				},
			});
			// if (version) {
			// 	localStorage.setItem(AnnouncementConfig.key, version.toString());
			// }
		}
	}, [version]);

	return <></>;
};

function mapStateToProps(state: ApplicationState) {
	let announcementConfig = state.dataState.configs.byId[DBConfig.Announcement];
	let announcementConfigValue = announcementConfig?.active ? announcementConfig?.value : undefined;
	let title = announcementConfigValue?.title;
	let message = announcementConfigValue?.message;
	let version = announcementConfigValue?.version;
	let tryUrl = announcementConfigValue?.tryUrl;

	return {
		title: title,
		message: message,
		version,
		tryUrl,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		enqueueSnackbar: (notification: any) => {
			dispatch(enqueueSnackbar(notification));
		},
	};
}

export const AnnouncementControl = connect(mapStateToProps, mapDispatchToProps)(Announcement);
