import { Box, Divider, Paper } from '@mui/material';
import AnandDate from 'helpers/AnandDate';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getTimerDurationByDate } from 'store/data/timer/selectors';
import { ApplicationState, TimerRunningState, TimerState, TimerType } from 'types';
import { TimerAnalytics } from './TimerAnalytics';
import { TimerPresetWidget } from './TimerPresetWidget';
import { TimerProgress } from './TimerProgress';
import { TimerRunningControl } from './TimerRunningControl';

const classes = {
	root: {
		margin: 1,
		display: 'flex',
		flexDirection: 'column',
		padding: 1,
		borderRadius: 2,
	},
	countUp: {
		width: 150,
		textAlign: 'center',
		marginBottom: -1,
	},
	countUpCircle: {
		margin: '0 auto',
		width: 150,
	},
	countDown: {
		display: 'flex',
		flexDirection: 'row',
		// padding: '8px 0 0 8px',
	},
	presets: { display: 'flex', flexDirection: 'row', overflow: 'auto', paddingTop: 0.5 },
};

interface DhyanWidgetProps {
	timerState?: TimerState;
	durationsByDate: { [date: string]: number };
}

const Component = (props: DhyanWidgetProps) => {
	let { timerState, durationsByDate } = props;

	let [tempCompleted, setTempCompleted] = useState(-1);
	let [tempDate, setTempDate] = useState('');
	let [isCustomMode, setIsCustomMode] = useState(false);

	let today = new AnandDate().format('YYYY-MM-DD');

	let isPastDate = tempDate.length && tempDate !== today;
	let duration = isPastDate ? tempCompleted : 0;

	return (
		<Paper sx={classes.root}>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<TimerProgress customDate={tempDate} customDuration={duration} />
				<Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
				<TimerAnalytics
					onDateClick={(date: string) => {
						setTempDate(date);
						let tempDuration = durationsByDate[date];
						setTempCompleted(tempDuration);

						setIsCustomMode(date.length > 0);
					}}
					isCustomMode={isCustomMode}
					sx={{ flexGrow: 1 }}
				/>
			</Box>
			<Divider flexItem sx={{ my: 1 }} />
			{timerState && timerState.currentState !== TimerRunningState.Stopped && timerState.type === TimerType.Dhyan ? (
				<TimerRunningControl />
			) : (
				<TimerPresetWidget />
			)}
		</Paper>
	);
};

function mapStateToProps(state: ApplicationState) {
	let timerState = state.tempState.timerState;
	let durationsByDate = getTimerDurationByDate(state);

	return {
		timerState,
		durationsByDate,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export const DhyanWidget = connect(mapStateToProps, mapDispatchToProps)(Component);
